<template>
  <div class="cursor-pointer" @click="copyToClipboard">
    {{ title }}
    <font-awesome-icon v-if="!copying" :icon="['fal', 'copy']" />
    <font-awesome-icon v-else :icon="['fal', 'check']" />
  </div>
</template>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:User');

import { defineComponent, ref, toRefs } from 'vue';

import useClipboard from 'vue-clipboard3';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { content } = toRefs(props);
    const copying = ref(false);
    const { toClipboard } = useClipboard();

    async function copyToClipboard() {
      try {
        if (content.value != null) {
          copying.value = true;
          await toClipboard(content.value);
          setTimeout(() => (copying.value = false), 2000);
        }
      } catch (e) {
        logger.error(e);
      }
    }

    return {
      copyToClipboard,
      copying,
    };
  },
});
</script>
