<template>
  <a-modal
    class="modal-no-padding"
    style="top: 10vh"
    :bodyStyle="{
      maxHeight: 'calc(75vh - 150px)',
      overflowY: 'scroll',
      marginLeft: '1px',
      marginRight: '1px',
    }"
    :width="800"
    :visible="show"
    :closable="false"
    :destroyOnClose="true"
    :onCancel="() => updateShow(false)"
  >
    <!-- Title -->
    <template #title>
      <div class="flex flex-col mb-2 justify-between text-2xl md:flex-row">
        <div class="flex flex-row items-center">
          <font-awesome-icon
            class="text-ow-p-dark-blue mr-4"
            :icon="['fas', 'plane']"
          />
          <div>{{ segment.departure?.name }}</div>
          <FlightLine class="flex-grow mx-2 min-w-20" />
          <div>{{ segment.arrival?.name }}</div>
        </div>
        <div class="my-3">
          <font-awesome-icon class="mr-3" :icon="['far', 'calendar']" />
          <span>{{ departureDateStr }}</span>
        </div>
      </div>
      <Filters
        class="mt-6 mb-2"
        ref="filtersRef"
        :segment="segment"
        @update:filters="handleFilterUpdates"
      />
    </template>

    <!--  Body   -->
    <FlightOverview
      class="sticky top-0 bg-white z-500 filter"
      :class="{
        grayscale: selectedIsFilteredOut,
      }"
      :segment="segment"
      :flight="segment.flightSegment?.selectedFlight"
      :previousFlight="previousFlight"
      :selected="true"
      @update:selectedFlight="handleSelectedFlightChange"
    />
    <FlightOverview
      v-for="flightOption in filteredFlightOptionsWithoutSelected"
      :key="flightOption.id"
      :segment="segment"
      :flight="flightOption"
      :previousFlight="previousFlight"
      class="odd:bg-white even:bg-neutral-200"
      @update:selectedFlight="handleSelectedFlightChange"
    />
    <div
      class="min-h-24 bg-neutral-200 rounded-xl flex flex-row justify-center items-center"
      v-if="filteredFlightOptions.length === 0"
    >
      There are no alternative flights that match your search criteria.
    </div>

    <!--  Footer   -->
    <template #footer>
      <div class="flex flex-row justify-end">
        <div class="btn secondary" @click="() => updateShow(false)">
          Cancel
        </div>
        <div class="btn ml-4" @click="submitFlightChange">Select & proceed</div>
      </div>
    </template>
  </a-modal>
</template>

<style lang="scss">
.modal-content-90vh {
  max-height: 90vh;
}
</style>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:FlightOptions');

import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue';
import {
  Carrier,
  dayFormattingShort,
  Flight,
  FlightTimes,
  Maybe,
  Segment,
} from '@/api/service';
import { format } from 'date-fns';
import FlightOverview from './FlightOverview.vue';
import { useStore } from '@/store';
import { max } from 'lodash';
import Filters from './Filters.vue';
import FlightLine from '../step/FlightLine.vue';
import { ActionTypes } from '@/store/actions';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

const MAX_DURATION = '10000';

export default defineComponent({
  components: {
    FlightOverview,
    FlightLine,
    Filters,
  },
  props: {
    show: Boolean,
    segment: Object as PropType<Segment>,
    previousFlight: Object as PropType<Flight>,
  },
  setup(props, { emit }) {
    const store = useStore();

    const filtersRef = ref<typeof Filters | null>(null);

    const flightOptions = computed<Flight[]>(
      () =>
        (props.segment?.flightSegment?.flightOptions ?? []).filter(
          flightOption => flightOption != null
        ) as Flight[]
    );

    const filteredFlightOptionsWithoutSelected = computed(() =>
      filteredFlightOptions.value.filter(
        fo =>
          fo.flightNumber !==
          props.segment?.flightSegment?.selectedFlight?.flightNumber
      )
    );

    const selectedIsFilteredOut = computed(
      () =>
        filteredFlightOptions.value.length ===
        filteredFlightOptionsWithoutSelected.value.length
    );

    const elapsedTimes = computed<string[]>(() =>
      flightOptions.value
        .map(fo => fo.elapsedTime)
        .filter((et): et is string => et != null)
    );

    // TODO: add back with good layover duration data
    // const elapsedLayoverTimes = computed<number[]>(() => {
    //   const legs = flightOptions.value
    //     .map(flightOption => flightOption?.legs?.slice(1))
    //     .flat();
    //   return legs
    //     .map(leg => unMaybe(leg)?.layoverMinutes)
    //     .filter((lm): lm is number => lm != null);
    // });

    const filters = reactive({
      checkedAirlines: [],
      checkedStops: [],
      checkedDepartureTimes: [],
      checkedArrivalTimes: [],
      checkedDepartureAirports: [],
      checkedArrivalAirports: [],
      maxDuration: MAX_DURATION, // Large placeholder
      checkedLayoverAirports: [],
      // Layover duration data not valid - add back in later
      // maxLayoverDuration: MAX_DURATION, // Large placeholder,
    });

    // Force update the maximums, when new data comes in
    watch(elapsedTimes, () => {
      const met = max(elapsedTimes.value.map(et => Number(et)));
      filters.maxDuration = met !== undefined ? met.toString() : MAX_DURATION;
    });
    // TODO: add back with good layover duration data
    // watch(elapsedLayoverTimes, () => {
    //   const met = max(elapsedLayoverTimes.value);
    //   filters.maxLayoverDuration =
    //     met !== undefined ? met.toString() : MAX_DURATION;
    // });

    function updateShow(status: boolean) {
      emit('update:show', status);
      if (!status && filtersRef.value != null) {
        filtersRef.value.resetFilters();
      }
    }

    const selectedFlight = computed(
      () => props.segment?.flightSegment?.selectedFlight
    );
    const selectedFlightId = ref<string | undefined>(
      selectedFlight.value?.id ?? undefined
    );
    const carriers = computed<Carrier[]>(() => store.getters.carriers);

    function selectFlight(id?: string) {
      selectedFlightId.value = id;
    }

    function getAirline(flightNumber: Maybe<string> | undefined): string {
      if (flightNumber === null || flightNumber === undefined) {
        return 'Unknown';
      }
      const iataCode = flightNumber.slice(0, 2);
      const carrier = carriers.value.find((c: Carrier) => c.code === iataCode);
      return carrier?.name || 'Unknown';
    }

    function submitFlightChange() {
      const visit = props.segment?.arrivalVisit;
      const flightId = selectedFlightId.value;
      const flight = flightOptions.value.find(f => f.id === flightId);
      if (flight === undefined) {
        logger.error('.submitFlightChange: could not find flight ' + flightId);
        return;
      }
      const flightNumbers = flight.flightNumber?.split('_');
      if (visit != null && flightId != null && flightNumbers !== undefined) {
        const action = sendActionBuilder(
          'Set flight to use ' + flightNumbers.join('/'),
          formulaBuilder('rtwAction/setSelectedFlight', [
            propertyValueBuilder('arrivalVisit', visit),
            propertyValueBuilder('flightId', literalStringBuilder(flightId)),
          ])
        );
        store.dispatch(ActionTypes.SendActions, [action]);
        // actually change the flight
        updateShow(false);
      } else {
        logger.error('.submitFlightChange: visit or flight poorly defined.');
      }
    }

    const departureDateStr = computed(() => {
      const localDate = props.segment
        ? store.getters.departureDateForSegment(props.segment)
        : undefined;
      return props.segment && localDate !== undefined
        ? format(localDate, dayFormattingShort)
        : '-';
    });

    const arrivalDateStr = computed(() => {
      const localDate = props.segment
        ? store.getters.arrivalDateForSegment(props.segment)
        : undefined;
      return props.segment && localDate !== undefined
        ? format(localDate, dayFormattingShort)
        : '-';
    });

    const showArrivalStr = computed(
      () => arrivalDateStr.value !== departureDateStr.value
    );

    function filterFlightOptions(
      flightOptions: Flight[],
      filters: Record<string, string[] | Maybe<string> | undefined>
    ): Flight[] {
      let filteredFlightOptions = flightOptions;

      if (filters.checkedAirlines && filters.checkedAirlines.length > 0) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          if (!flightOption.flightNumber) return false;

          return (
            filters.checkedAirlines &&
            filters.checkedAirlines.includes(
              getAirline(flightOption.flightNumber)
            )
          );
        });
      }

      if (filters.checkedStops && filters.checkedStops.length > 0) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            filters.checkedStops &&
            filters.checkedStops.includes(
              String((flightOption.legs ?? []).length - 1)
            )
          );
        });
      }

      if (
        filters.checkedDepartureTimes &&
        filters.checkedDepartureTimes.length > 0
      ) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            filters.checkedDepartureTimes &&
            filters.checkedDepartureTimes.includes(
              store.getters.getFlightTime(
                flightOption.departureTime?.seconds,
                flightOption.departureCity?.timeZone
              )
            )
          );
        });
      }

      if (
        filters.checkedArrivalTimes &&
        filters.checkedArrivalTimes.length > 0
      ) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            filters.checkedArrivalTimes &&
            filters.checkedArrivalTimes.includes(
              store.getters.getFlightTime(
                flightOption.arrivalTime?.seconds,
                flightOption.arrivalCity?.timeZone
              )
            )
          );
        });
      }

      if (filters.maxDuration) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            flightOption?.elapsedTime &&
            Number(flightOption?.elapsedTime) <=
              (Number(filters.maxDuration) ?? 100000)
          );
        });
      }

      if (
        filters.checkedArrivalAirports &&
        filters.checkedArrivalAirports.length > 0
      ) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            flightOption.arrivalAirport &&
            filters.checkedArrivalAirports &&
            filters.checkedArrivalAirports.includes(
              store.getters.getAirportString(flightOption.arrivalAirport)
            )
          );
        });
      }

      if (
        filters.checkedDepartureAirports &&
        filters.checkedDepartureAirports.length > 0
      ) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          return (
            flightOption.departureAirport &&
            filters.checkedDepartureAirports &&
            filters.checkedDepartureAirports.includes(
              store.getters.getAirportString(flightOption.departureAirport)
            )
          );
        });
      }

      // TODO: add back with good layover duration data
      // if (
      //   filters.maxLayoverDuration &&
      //   Number(filters.maxLayoverDuration) > 0
      // ) {
      //   filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
      //     const maxLayover = max(
      //       flightOption.legs
      //         ?.slice(1)
      //         .filter(leg => leg?.layoverMinutes != null)
      //         .map(leg => leg?.layoverMinutes)
      //     );
      //     return (
      //       maxLayover && maxLayover <= (filters.maxLayoverDuration ?? 100000)
      //     );
      //   });
      // }

      if (
        filters.checkedLayoverAirports &&
        filters.checkedLayoverAirports.length > 0
      ) {
        filteredFlightOptions = filteredFlightOptions.filter(flightOption => {
          const legsAirports = flightOption.legs
            ?.slice(1)
            .map(leg =>
              leg?.departureAirport
                ? store.getters.getAirportString(leg?.departureAirport)
                : ''
            );
          return (
            filters.checkedLayoverAirports &&
            legsAirports?.some(airport =>
              filters.checkedLayoverAirports?.includes(airport)
            )
          );
        });
      }

      return filteredFlightOptions;
    }

    const filteredFlightOptions = computed(() =>
      filterFlightOptions(flightOptions.value, filters)
    );

    function sortFlightTimes(a: FlightTimes, b: FlightTimes): number {
      const order = {
        [FlightTimes.EarlyMorning]: 1,
        [FlightTimes.Morning]: 2,
        [FlightTimes.Afternoon]: 3,
        [FlightTimes.Evening]: 4,
      };

      if (order[a] < order[b]) {
        return -1;
      }
      if (order[a] > order[b]) {
        return 1;
      }
      return 0;
    }

    // eslint-disable-next-line
    function handleFilterUpdates(newFilterValues: any) {
      filters.checkedAirlines = newFilterValues.checkedAirlines;
      filters.checkedStops = newFilterValues.checkedStops;
      filters.checkedDepartureTimes = newFilterValues.checkedDepartureTimes;
      filters.checkedArrivalTimes = newFilterValues.checkedArrivalTimes;
      filters.maxDuration = newFilterValues.maxDuration;
      filters.checkedDepartureAirports =
        newFilterValues.checkedDepartureAirports;
      filters.checkedArrivalAirports = newFilterValues.checkedArrivalAirports;
      filters.checkedLayoverAirports = newFilterValues.checkedLayoverAirports;
      // TODO: add back with good layover duration data
      // filters.maxLayoverDuration = newFilterValues.maxLayoverDuration;
    }

    function handleSelectedFlightChange(newFlight: Flight) {
      if (newFlight && newFlight.id) selectFlight(newFlight.id);
    }

    return {
      filtersRef,
      updateShow,
      selectedFlight,
      selectedFlightId,
      selectFlight,
      elapsedTimes,
      getAirline,
      flightOptions,
      submitFlightChange,
      departureDateStr,
      arrivalDateStr,
      showArrivalStr,
      filteredFlightOptions,
      filteredFlightOptionsWithoutSelected,
      selectedIsFilteredOut,
      sortFlightTimes,
      filters,
      handleFilterUpdates,
      handleSelectedFlightChange,
    };
  },
});
</script>
