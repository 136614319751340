<template>
  <div class="flex text-base">
    <div class="line flex-grow" />
  </div>
</template>

<style lang="scss" scoped>
.line {
  border-top: 1px dashed #9ba0a8;
  margin: 4px 0;
}

.airplane {
  color: #666d79;
  margin: 0 2px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
