<template>
  <!--  TODO need to figure out why having a transition sometimes causes the component to hide itself-->
  <!--  <transition name="fade-in">-->
  <!-- Outer itinerary with width control -->
  <div
    class="Itinerary max-h-full flex flex-row overflow-hidden"
    v-show="haveCities"
  >
    <!-- Itinerary box -->
    <div
      class="shadow-sm bg-gray-200 border border-gray-400 rounded-2xl p-4 overflow-hidden"
      :class="{
        'w-itinerary-collapsed': collapsed && !showSegments,
        'w-itinerary-with-segments-collapsed': collapsed && showSegments,
        'pr-0': collapsed && showSegments,
      }"
    >
      <div class="h-full flex flex-col overflow-hidden">
        <ItineraryHeader class="mb-3" />
        <Pricing class="mb-3" />
        <TripDuration class="mb-3" v-show="showTripDuration" />
        <div class="flex-grow overflow-auto relative">
          <Origin v-if="haveOrigin" :show-segments="showSegments" />
          <Interims v-if="haveInterims" :show-segments="showSegments" />
          <Final
            v-if="haveInterims && haveFinal"
            :show-segments="showSegments"
          />
          <!-- Could not get this to work <div class="bottom-fade" />-->
        </div>
      </div>
    </div>

    <!-- Collapse tab -->
    <div
      class="mt-3 w-8 h-12 flex flex-row justify-center items-center rounded-tr-lg rounded-br-lg bg-gray-100 border border-gray-300 border-l-none text-gray-600 cursor-pointer hover:text-gray-400"
      v-if="showSegments"
      @click="toggleCollapsed"
    >
      <font-awesome-icon
        class="text-2xl"
        :icon="['fal', 'angle-right']"
        v-if="collapsed"
      />
      <font-awesome-icon
        class="text-2xl"
        :icon="['fal', 'angle-left']"
        v-else
      />
    </div>
  </div>
  <!--  </transition>-->
</template>

<style lang="scss" scoped>
.bottom-fade {
  @apply bg-gradient-to-t from-gray-200;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1em;
  content: '';
}
</style>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from '@/store';
import { Itinerary, Segment as SegmentType } from '@/api/service';

import Pricing from '@/components/common/itinerary/Pricing.vue';
import ItineraryHeader from '@/components/common/itinerary/ItineraryHeader.vue';
import Origin from '@/components/common/itinerary/Origin.vue';
import Interims from '@/components/common/itinerary/Interims.vue';
import Final from '@/components/common/itinerary/Final.vue';
import TripDuration from '@/components/common/itinerary/TripDuration.vue';

export default defineComponent({
  components: {
    TripDuration,
    Pricing,
    ItineraryHeader,
    Origin,
    Interims,
    Final,
  },
  setup() {
    const store = useStore();

    const itinerary = computed<Itinerary | undefined>(
      () => store.state.itinerary
    );
    const segments = computed<SegmentType[]>(() => store.getters.segments);
    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const haveCities = computed(() => store.getters.haveCities);
    const haveOrigin = computed(() => store.getters.haveOrigin);
    const haveInterims = computed(() => store.getters.haveInterims);
    const haveFinal = computed(() => store.getters.haveFinal);
    const showSegments = computed(() => haveOrigin.value && haveInterims.value);
    const haveFlights = computed(() => store.getters.haveFlights);
    const tripDuration = computed(() => store.state.itinerary?.tripDuration);
    const showTripDuration = computed(
      () => tripDuration.value != null && tripDuration.value > 0
    );

    const inspirationOpen = computed(() => store.state.showInspiration);
    const heightVal = computed(() => {
      const preplannedHeight = inspirationOpen.value ? 375 : 0;
      return `${window.innerHeight - 156 - preplannedHeight}px`;
    });

    const collapsed = ref(true);
    function setCollapsed(newVal: boolean) {
      collapsed.value = newVal;
    }
    function toggleCollapsed() {
      collapsed.value = !collapsed.value;
    }

    watch(haveFlights, newHaveFlightsVal => {
      if (newHaveFlightsVal) {
        setCollapsed(false);
      }
    });

    return {
      itinerary,
      segments,
      requireDialogResponse,
      haveCities,
      haveOrigin,
      haveInterims,
      haveFinal,
      showSegments,
      haveFlights,
      showTripDuration,
      collapsed,
      setCollapsed,
      toggleCollapsed,
      heightVal,
    };
  },
});
</script>
