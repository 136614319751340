<template>
  <Step
    class="Interim"
    :index="index"
    :city="city"
    :visit="visit"
    :city-preferences="preferences"
    :city-status="cityStatus"
    :city-status-message="cityStatusMessage"
    :segment="segment"
    :previous-segment="previousSegment"
    :show-segments="showSegments"
    :draggable="draggable"
  >
    <template #calendar>
      <CityDateRange
        :index="index"
        :city="city"
        :visit="visit"
        :arrivalSegment="previousSegment"
        :arrivalDate="arrivalDate"
        :departureSegment="segment"
        :departureDate="departureDate"
        :duration="duration"
        :preferences="preferences"
      />
    </template>
  </Step>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { Segment, unMaybe, Interim, unMaybeArray } from '@/api/service';

import Step from './step/Step.vue';
import CityDateRange from '@/components/common/calendars/CityDateRange.vue';
import { useStore } from '@/store';

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
    interim: {
      type: Object as PropType<Interim>,
      required: true,
    },
    segment: {
      type: Object as PropType<Segment>,
      required: true,
    },
    previousSegment: {
      type: Object as PropType<Segment>,
      required: false,
    },
    draggable: {
      type: Boolean,
      required: true,
    },
    showSegments: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Step,
    CityDateRange,
  },
  setup(props) {
    const store = useStore();

    const { segment, interim } = toRefs(props);
    const city = computed(() => unMaybe(segment.value.departure));
    const cityStatus = computed(() => unMaybe(interim.value.status));
    const cityStatusMessage = computed(() =>
      unMaybe(interim.value.statusMessage)
    );
    const preferences = computed(() => unMaybeArray(interim.value.preferences));
    const visit = computed(() => unMaybe(segment.value.departureVisit));
    const arrivalDate = computed(() =>
      store.getters.arrivalDateForInterim(interim.value)
    );
    const departureDate = computed(() =>
      store.getters.departureDateForInterim(interim.value)
    );
    const duration = computed(() =>
      store.getters.durationForInterim(interim.value)
    );

    return {
      city,
      cityStatus,
      cityStatusMessage,
      visit,
      arrivalDate,
      departureDate,
      duration,
      preferences,
    };
  },
});
</script>
