<template>
  <div class="Welcome text-ow-p-dark-blue">
    <div class="onboarding-header">
      <div class="title">
        Welcome to <strong class="font-bold">one</strong>world's new booking
        tool!
      </div>
      <div class="subtitle">
        A new, revolutionary way to book your round-the-world trip.
      </div>
    </div>
    <div class="pt-4 pb-8 px-8">
      <div class="flex flex-col md:flex-row justify-between items-center gap-4">
        <div v-for="x in numContent" :key="x" class="flex flex-col md:flex-row">
          <div class="mt-16 relative w-64">
            <div class="absolute -top-8 w-full">
              <div
                class="background-circle shadow-md bg-ow-p-light-blue h-16 w-16 rounded-full mx-auto text-center"
              >
                <font-awesome-icon
                  :icon="icon(x - 1)"
                  class="z-4000 text-white text-2xl mt-5"
                />
              </div>
            </div>
            <div class="shadow-lg px-7 py-8 mx-2">
              <div class="my-3 text-lg font-bold text-center">
                {{ title(x - 1) }}
              </div>
              <div class="text-center">
                {{ content(x - 1) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

const iconNames = [
  ['fa', 'globe'],
  ['far', 'calendar'],
  ['fas', 'plane'],
];
const pageTitles = ['Choose Destinations', 'Set Dates', 'Finalize Flights'];
const pageContent = [
  'First, be inspired by our pre-built itineraries or create your own. We will help you order your cities.',
  'Next, set the desired dates for your trip. Any scheduling issues will be handled in real time',
  'Finally, we will recommend flights for you to review. Pick the flights that best suit you.',
];

export default defineComponent({
  setup() {
    const numContent = computed(() => pageTitles.length);

    function title(pageNum: number) {
      return pageTitles[pageNum];
    }
    function content(pageNum: number) {
      return pageContent[pageNum];
    }
    function icon(pageNum: number) {
      return iconNames[pageNum];
    }

    return {
      numContent,
      title,
      content,
      icon,
    };
  },
});
</script>
