<!--
  Currently only capable of horizontal scroll with right indicator.
  Could not find good component in the wild.

  Styling has the option of having small variant on the scroll arrows.
-->
<template>
  <div
    class="Scroller relative overflow-y-visible overflow-x-hidden"
    @mouseenter="updateOverflow"
    @mouseleave="updateOverflow"
  >
    <div
      ref="scroller"
      class="flex flex-row overflow-y-visible overflow-x-auto scroll-smooth"
    >
      <slot />
    </div>
    <div
      class="absolute top-0 h-full flex justify-center items-center"
      :class="{
        'left-2': !small,
        'left-0': small,
      }"
      v-if="isOverflowingLeft"
    >
      <div
        class="bg-white flex shadow-2xl justify-center items-center cursor-pointer"
        :class="{
          'rounded-full': !small,
          'h-14': !small,
          'w-14': !small,
          'h-8': small,
          'w-8': small,
        }"
        @click="scrollLeft"
      >
        <font-awesome-icon
          class="text-neutral-800 text-lg"
          :icon="['fas', 'chevron-left']"
        />
      </div>
    </div>
    <div
      class="absolute top-0 h-full flex justify-center items-center"
      :class="{
        'right-2': !small,
        'right-0': small,
      }"
      v-if="isOverflowingRight"
    >
      <div
        class="shadow-2xl bg-white flex justify-center items-center cursor-pointer"
        :class="{
          'rounded-full': !small,
          'h-14': !small,
          'w-14': !small,
          'h-8': small,
          'w-8': small,
        }"
        @click="scrollRight"
      >
        <font-awesome-icon
          class="text-neutral-800 text-lg"
          :icon="['fas', 'chevron-right']"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';

export default defineComponent({
  props: {
    small: {
      type: Boolean,
      require: false,
      default: false,
    },
    step: {
      type: Number,
      require: false,
      default: 400,
    },
  },
  setup(props) {
    const scroller = ref<null | Element>(null);
    const isOverflowingLeft = ref(false);
    const isOverflowingRight = ref(false);

    // create the observer
    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => updateOverflow());
    });
    const observerConfig = { attributes: true, childList: true, subtree: true };

    function updateOverflow() {
      if (scroller.value != null) {
        // Note offset by 1 due to what appears to be rounding issues
        isOverflowingRight.value =
          scroller.value.scrollLeft + 1 <
          scroller.value.scrollWidth - scroller.value.clientWidth;
        isOverflowingLeft.value = scroller.value.scrollLeft > 0;
      }
    }

    function scrollLeft() {
      if (scroller.value != null) {
        scroller.value.scrollTo({
          left: scroller.value.scrollLeft - props.step,
          behavior: 'smooth',
        });
      }
    }

    function scrollRight() {
      if (scroller.value != null) {
        scroller.value.scrollTo({
          left: scroller.value.scrollLeft + props.step,
          behavior: 'smooth',
        });
      }
    }

    onMounted(() => {
      updateOverflow();
      if (scroller.value != null) {
        scroller.value.addEventListener('scroll', updateOverflow);
        observer.observe(scroller.value, observerConfig);
      }
    });

    onBeforeUnmount(() => {
      if (scroller.value != null) {
        scroller.value.removeEventListener('scroll', updateOverflow);
      }
      observer.disconnect();
    });

    return {
      scroller,
      updateOverflow,
      isOverflowingLeft,
      isOverflowingRight,
      scrollLeft,
      scrollRight,
    };
  },
});
</script>
