<template>
  <a-tooltip
    placement="right"
    class="display-block flex flex-row items-center dragBlur"
    :class="{
      'text-neutral-500': missingDates,
      'hover:underline': !missingDates && showPopupToEditDates,
      'cursor-text': !showPopupToEditDates,
      'cursor-pointer': !requireDialogResponse && !missingDates,
      'cursor-not-allowed': requireDialogResponse || missingDates,
    }"
    :title="tooltipText"
    :visible="hovered && hasDialogResponse"
    @visibleChange="handleHoverChange"
  >
    <a-popover
      class="z-2000"
      placement="leftBottom"
      :visible="clicked && showPopupToEditDates && hasDialogResponse"
      @visibleChange="handleClickChange"
      :destroyTooltipOnHide="true"
      trigger="click"
    >
      <!-- popover trigger -->
      <div
        class="w-full flex items-center mt-1 ml-2"
        :class="{
          'text-neutral-500': missingDates,
          'cursor-pointer': !requireDialogResponse && !missingDates,
          'cursor-not-allowed': requireDialogResponse || missingDates,
        }"
      >
        <CityDateInfo
          :index="index"
          :visit="visit"
          :arrivalDate="arrivalDate"
          :isGroundArrival="isGroundArrival"
          :departureDate="departureDate"
          :isGroundDeparture="isGroundDeparture"
          :duration="duration"
          :preferences="preferences"
        />
      </div>

      <!-- popover content -->
      <template #content>
        <div class="p-4">
          <div class="text-xl">For {{ city.name }}:</div>
          <VisitDuration
            :preferences="preferences"
            :city="city"
            :arrival-date="arrivalDate"
            :departure-date="departureDate"
          />
          <DateEdit
            class="mt-8 overridden: arrivalDateRequestOutOfSync"
            :city="city"
            :visit="visit"
            :segment="arrivalSegment"
            :date="arrivalDate"
            :min-date="arrivalMinDate"
            :max-date="arrivalMaxDate"
            :preferences="preferences"
            :dateSentence="arrivalDateSentence"
            :arrival-date="arrivalDate"
            :departure-date="departureDate"
          />
          <DateEdit
            v-if="departureSegment !== undefined"
            class="mt-8 overridden: arrivalDateRequestOutOfSync"
            :city="city"
            :visit="visit"
            :segment="departureSegment"
            :date="departureDate"
            :min-date="departureMinDate"
            :max-date="departureMaxDate"
            :preferences="preferences"
            :dateSentence="departureDateSentence"
            :arrival-date="arrivalDate"
            :departure-date="departureDate"
          />
          <div
            class="mt-8 flex flex-row items-center justify-between"
            v-if="isPhaseDatesBatching"
          >
            <div class="italic">Note: Dates have yet to be validated.</div>
            <!--  <div class="button-call-to-action" @click="validateDates">-->
            <!--    Validate-->
            <!--  </div>-->
          </div>
        </div>
      </template>
    </a-popover>
  </a-tooltip>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store';
import { City, Concept, Formula, Segment } from '@/api/service';

import CityDateInfo from './CityDateInfo.vue';
import { event } from 'vue-gtag';
import { oneYear } from '@/components/common/calendars/Calendar';
import {
  formulaBuilder,
  getUserRequestsArrivalDate,
  getUserRequestsDepartureDate,
  sendActionBuilder,
} from '@/api/kr';
import DateEdit from '@/components/common/calendars/DateEdit.vue';
import { ActionTypes } from '@/store/actions';
import { dateStr, dateFormat } from '@/format';
import VisitDuration from '@/components/common/calendars/VisitDuration.vue';

export default defineComponent({
  components: {
    VisitDuration,
    DateEdit,
    CityDateInfo,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    city: {
      type: Object as PropType<City>,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    arrivalSegment: {
      type: Object as PropType<Segment>,
    },
    arrivalDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    departureSegment: {
      type: Object as PropType<Segment>,
    },
    departureDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    duration: {
      type: Number,
      required: false,
    },
    preferences: {
      type: Object as PropType<Formula[]>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const isFinalVisit = computed(() =>
      store.getters.isFinalVisit(props.visit)
    );
    const hadDates = computed(() => store.state.hadDates);

    const isGroundArrival = computed(
      () => props.arrivalSegment?.groundSegment != null
    );

    const isGroundDeparture = computed(
      () => props.departureSegment?.groundSegment != null
    );

    const missingDates = computed(() => !store.getters.haveDates);
    const isPhaseDatesBatching = computed(
      () => store.getters.isPhaseDatesBatching
    );

    const beforeError = ref(false);
    const afterError = ref(false);

    //
    // Ranges
    //

    // TODO: bind by worst case constraint before this
    const arrivalMinDate = computed(() => {
      return new Date();
    });

    const arrivalMaxDate = computed(() => {
      const departureDateRequest = getUserRequestsDepartureDate(
        props.preferences
      );
      const depDate = departureDateRequest
        ? departureDateRequest.date
        : props.departureDate
        ? props.departureDate
        : oneYear;
      if (depDate.getTime() <= oneYear.getTime()) {
        return depDate;
      } else {
        return oneYear;
      }
    });

    const departureMinDate = computed(() => {
      const today = new Date();
      const arrivalDateRequest = getUserRequestsArrivalDate(props.preferences);
      const arrDate = arrivalDateRequest
        ? arrivalDateRequest.date
        : props.arrivalDate
        ? props.arrivalDate
        : today;
      if (arrDate.getTime() >= today.getTime()) {
        return arrDate;
      } else {
        return today;
      }
    });

    // TODO: bind by worst case constraint after this
    const departureMaxDate = computed(() => oneYear);
    //
    // Interaction
    //
    const clicked = ref<boolean>(false);
    const hovered = ref<boolean>(false);

    function hidePopover() {
      const clickedBefore = clicked.value;
      clicked.value = false;
      hovered.value = false;
      eventShowHide(clickedBefore);
    }

    function handleHoverChange(visible: boolean) {
      const clickedBefore = clicked.value;
      clicked.value = false;
      hovered.value = visible;
      eventShowHide(clickedBefore);
    }

    function handleClickChange(visible: boolean) {
      if (hasDialogResponse.value) {
        const clickedBefore = clicked.value;
        if (!requireDialogResponse.value && !missingDates.value) {
          clicked.value = visible;
          hovered.value = false;
        } else {
          clicked.value = false;
          hovered.value = false;
        }
        eventShowHide(clickedBefore);
      } else {
        sendProcessingWarning();
      }
    }

    function eventShowHide(clickedBefore: boolean) {
      if (!clickedBefore && clicked.value) {
        event('city-date-range-calendar-show', { method: 'Google' });
      } else if (clickedBefore && !clicked.value) {
        event('city-date-range-calendar-hide', { method: 'Google' });
      }
    }

    function validateDates() {
      const action = sendActionBuilder(
        'Validate dates',
        formulaBuilder('rtwAction/batchingComplete', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    function sendProcessingWarning() {
      store.dispatch(ActionTypes.SendProcessingWarning);
    }

    const editArrivalDateUI = computed(() =>
      store.state.ldClient?.variation('edit-arrival-date-ui', true)
    );

    const showPopupToEditDates = computed(() => {
      return (
        editArrivalDateUI.value ||
        (!editArrivalDateUI.value &&
          props.departureSegment !== undefined &&
          !isGroundDeparture.value)
      );
    });

    const arrivalDateSentence = computed(() => {
      return (
        'You have an arrival date of ' +
        (!editArrivalDateUI.value && props.arrivalDate !== undefined
          ? formatDate(props.arrivalDate)
          : '')
      );
    });

    const departureDateSentence = computed(() => {
      return 'You have a departure date of ';
    });

    const tooltipText = computed(() => {
      if (!hadDates.value && missingDates.value) {
        return 'You will be able to configure dates shortly';
      } else if (missingDates.value) {
        return 'Dates are being re-calculated';
      } else if (
        !showPopupToEditDates.value &&
        props.arrivalDate !== undefined
      ) {
        return arrivalDateSentence.value;
      } else {
        return 'Click to edit dates';
      }
    });

    function formatDate(date: Date) {
      return dateStr(date, dateFormat.day);
    }

    const hasDialogResponse = computed(
      () =>
        store.getters.requireDialogResponse ||
        store.getters.requireMultiChoiceResponse ||
        store.getters.haveActiveRequestSystemMessage
    );

    return {
      clicked,
      hovered,
      handleHoverChange,
      handleClickChange,
      isFinalVisit,
      hadDates,
      missingDates,
      isGroundArrival,
      isGroundDeparture,
      isPhaseDatesBatching,
      requireDialogResponse,
      tooltipText,
      hidePopover,
      arrivalMinDate,
      arrivalMaxDate,
      departureMinDate,
      departureMaxDate,
      beforeError,
      afterError,
      validateDates,
      showPopupToEditDates,
      arrivalDateSentence,
      departureDateSentence,
      hasDialogResponse,
    };
  },
});
</script>
