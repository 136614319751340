<template>
  <a-popover
    class="Debug"
    placement="bottomRight"
    overlayClassName="popover-no-padding"
  >
    <div class="dialog-header-button">
      <font-awesome-icon class="text-xl" :icon="['fat', 'bug']" />
    </div>
    <template #content>
      <div class="menu-item" @click="restart">Restart Dialog</div>
      <div class="menu-item" @click="performanceReport">
        See Performance Report
      </div>
      <div class="menu-item" v-if="userMessagesToCopy.length > 0">
        <CopyToClipboard
          title="Copy User Messages"
          :content="userMessagesToCopy"
        />
      </div>
    </template>
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import {
  isSystemMessage,
  unMaybeArray,
  UserAction,
  UserMessage,
  UserTextChoice,
} from '@/api/service';
import { useStore } from '@/store';

import CopyToClipboard from '@/components/common/misc/CopyToClipboard.vue';
import { ActionTypes } from '@/store/actions';
import * as config from 'config';

export default defineComponent({
  components: {
    CopyToClipboard,
  },
  setup() {
    const store = useStore();

    const sessionId = computed(() => store.state.session?.id);

    const citiesDone = computed(() =>
      store.state.itinerary && store.state.itinerary?.citiesDone != null
        ? store.state.itinerary?.citiesDone
        : false
    );
    const datesDone = computed(() =>
      store.state.itinerary && store.state.itinerary?.datesDone
        ? store.state.itinerary?.datesDone
        : false
    );
    const flightsDone = computed(() =>
      store.state.itinerary && store.state.itinerary?.flightsDone
        ? store.state.itinerary?.flightsDone
        : false
    );
    const showItineraryLink = computed(() =>
      store.state.ldClient?.variation('save-itinerary', false)
    );
    const userMessages = computed(() =>
      store.state.dialog.messages.filter(
        (m): m is UserMessage => !isSystemMessage(m)
      )
    );
    const userMessagesToCopy = computed(() =>
      userMessages.value.map(m => userText(m)).join(',\n')
    );

    function restart() {
      store.dispatch(ActionTypes.Restart);
    }

    function performanceReport() {
      const env = config.ENV == 'local' ? 'dev' : config.ENV;
      const sessionId = store.state.sessionId;
      const hcUrlTemplate = `https://ui.honeycomb.io/ec/datasets/${env}?tab=traces&query={ "time_range": 7200, "granularity": 0, "breakdowns": [], "calculations": [ { "op": "MAX", "column": "duration_ms" } ], "filters": [ { "column": "sessionId", "op": "=", "value": "${sessionId}" }, { "column": "name", "op": "=", "value": "dialogBotSend" } ], "filter_combination": "AND", "orders": [], "havings": [], "limit": 1000 }\n`;
      window.open(hcUrlTemplate, '_blank');
    }

    function userText(message: UserMessage | undefined | null): string {
      if (message === null || message === undefined) {
        return 'INVALID USER MESSAGE';
      } else if (message.textInput != null) {
        return message.textInput.text != null
          ? '"' + decodeURIComponent(message.textInput.text) + '"'
          : 'text unset';
      } else if (message.textChoices != null) {
        const choices =
          message.textChoices.textChoices != null
            ? unMaybeArray(message.textChoices.textChoices)
            : ([] as UserTextChoice[]);
        return choices
          ? choices.map(c => JSON.stringify(c)).join(', ')
          : 'choices unset';
      } else if (message.actions != null) {
        const actions =
          message.actions.actions != null
            ? unMaybeArray(message.actions.actions)
            : ([] as UserAction[]);
        return actions
          ? actions.map(a => JSON.stringify(a)).join(', ')
          : 'actions unset';
      } else {
        return 'INVALID USER MESSAGE';
      }
    }

    return {
      sessionId,
      citiesDone,
      datesDone,
      flightsDone,
      showItineraryLink,
      restart,
      performanceReport,
      userMessagesToCopy,
    };
  },
});
</script>
