<template>
    <div class="h-full w-10 items-center justify-center" v-if="showStopOverPopover"
         v-bind:style="{ position:'fixed', left: positionLeft, top: positionTop }"
         style="z-index: 100000"
    >
        <div
                id="popupStopOver"
                class="ant-tooltip ant-tooltip-placement-right"
        >
            <div class="ant-tooltip-content">
                <div class="ant-tooltip-arrow">
                    <span class="ant-tooltip-arrow-content"> </span>
                </div>
                <div class="ant-tooltip-inner" role="tooltip" style="width: 365px">
                    <div class="pr-2 flex flex-col" style="width: 350px">
                        <div>There are no direct flights for this segment.</div>
                    </div>
                    <div class="pr-2 flex flex-col"
                         v-if="hasSelectedFlightWithTwoStopOverAndThereIsPossibleOneStopOver">
                        <div>{{textWhenHasSelectedFlightWithTwoStopOver}}
                        </div>
                    </div>
                    <div class="pr-2 flex flex-col">
                        <div v-html="possibleStopOverText"/>
                    </div>
                    <div class="pr-2 flex flex-col" v-if="hasNoSelectedFlights">
                        <div>You will be able to select it while confirming flights.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {
    defineComponent,
    PropType,
    ref,
    computed,
    onMounted,
    onUpdated,
} from 'vue';
import {
    Segment,
    Maybe,
    City,
    Cities, unMaybeArray
} from '@/api/service';
import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
import {useStore} from "@/store";
import {getPropertyEntity, isFlightSelectedByUser, userRequestsFlight} from "@/api/kr";

const md = new MarkdownIt().use(MarkDownItEmoji);

export default defineComponent({
    props: {
        segment: {
            type: Object as PropType<Segment>,
            required: true,
        },
        anchor: {
            type: String,
            required: false,
        },
        visible: {
            type: Boolean,
            required: false,
        },
    },

    emits: ['toggleShowDetails'],
    setup(props) {
        const stopOverCities = ref("");
        const segment = computed(() => props.segment);
        let possibleOneStopOverCitiesCount = 0;
        let possibleTwoStopOverCitiesCount = 0;
        const hasNoSelectedFlights = ref(false);
        const hasSelectedFlightWithTwoStopOverAndThereIsPossibleOneStopOver = ref(false);
        const possibleStopOverText = ref("");
        const selectedFlightStopOverCount = ref(0);
        const positionTop = ref("");
        const positionLeft = ref("");        
        const showStopOverPopover = ref(false);
        const textWhenHasSelectedFlightWithTwoStopOver = ref("");

        function update() {

            if (props.anchor) {

                let flightSelectByUser = false;
                if(segment.value.flightSegment?.selectedFlight?.flightNumber != undefined) {

                    if(segment.value.preferences !== undefined) {
                        flightSelectByUser = isFlightSelectedByUser(unMaybeArray(segment.value.preferences), segment.value.flightSegment?.selectedFlight?.flightNumber);
                    }
                }

                if (flightSelectByUser) {
                    textWhenHasSelectedFlightWithTwoStopOver.value = "You have chosen a 2 stop-over flight.";
                } else {
                    textWhenHasSelectedFlightWithTwoStopOver.value = "We have chosen a 2 stop-over flight as it minimizes travel time."
                }

                var clientHeight = document.getElementById('popupStopOver');
                let height = 0;

                if (clientHeight != null) {
                    height = clientHeight.clientHeight;
                }

                const element = document.getElementById(props.anchor);

                if (element != null && props.visible == true) {
                    var coordinates = element.getBoundingClientRect();
                    positionTop.value = (8 + coordinates.top - (height / 2)) + "px";
                    positionLeft.value = (5 + coordinates.left + element.clientWidth) + "px";
                }

                stopOverCities.value = "";
                possibleOneStopOverCitiesCount = segment.value.possibleOneStopOverCities?.length != undefined ? segment.value.possibleOneStopOverCities?.length : 0;
                if (possibleOneStopOverCitiesCount > 0) {

                    segment.value.possibleOneStopOverCities?.forEach((stopOver: Maybe<City>) => {
                        stopOverCities.value += ", " + "<strong>" + stopOver?.name + "</strong>";
                    });

                }
                possibleTwoStopOverCitiesCount = segment.value.possibleTwoStopOverCities?.length != undefined ? segment.value.possibleTwoStopOverCities?.length : 0;
                if (possibleTwoStopOverCitiesCount > 0) {

                    segment.value.possibleTwoStopOverCities?.forEach((cities: Maybe<Cities>) => {

                        let citiesName = "";

                        cities?.cities?.forEach((stopOver: Maybe<City>) => {
                            citiesName += "<strong>" + stopOver?.name + "</strong>" + "+";
                        })
                        let index = citiesName.lastIndexOf("+");
                        if (index > -1) {
                            citiesName = citiesName.slice(0, index) + citiesName.slice(index + 1);
                        }

                        stopOverCities.value += ", " + citiesName;
                    });

                }

                stopOverCities.value = stopOverCities.value.replace(/^,/, '');
                stopOverCities.value = stopOverCities.value.replace(/,(?=[^,]+$)/, ' or');

                if (selectedFlightStopOverCount.value == undefined) {
                    selectedFlightStopOverCount.value = 0;
                }

                showStopOverPopover.value = props.visible == true && (possibleOneStopOverCitiesCount > 0 || possibleTwoStopOverCitiesCount > 0);

                hasNoSelectedFlights.value = segment.value.flightSegment?.selectedFlight == undefined;
                selectedFlightStopOverCount.value = segment.value.flightSegment?.selectedFlight?.legs?.length ? segment.value.flightSegment?.selectedFlight?.legs?.length - 1 : 0;

                hasSelectedFlightWithTwoStopOverAndThereIsPossibleOneStopOver.value = selectedFlightStopOverCount.value > 1 && possibleOneStopOverCitiesCount > 1
                const isPlural = possibleOneStopOverCitiesCount > 1 || possibleTwoStopOverCitiesCount > 1;
                possibleStopOverText.value = "There " + (isPlural ? "are" : "is") + (possibleTwoStopOverCitiesCount > 0 ? " 2-" : " ") + "stop-over " + (isPlural ? "options" : "option") + " through " + stopOverCities.value + ".";

            }
        }

        onMounted(() => update());
        onUpdated(() => update());

        return {
            selectedFlightStopOverCount,
            hasSelectedFlightWithTwoStopOverAndThereIsPossibleOneStopOver,
            hasNoSelectedFlights,
            possibleStopOverText,
            showStopOverPopover,
            positionTop,
            positionLeft,
            stopOverCities,
            textWhenHasSelectedFlightWithTwoStopOver
        };
    },
});
</script>