<template>
  <div class="DateRequest">
    <DatePicker
      class="city"
      color="blue"
      v-model="datePickerVal"
      mode="date"
      :columns="1"
      :min-date="minDate"
      :max-date="maxDate"
      :attributes="attributes"
      @dayclick="update"
      @update:from-page="updateAvailability"
    />
    <div
      v-if="!loadingAvailability"
      class="flex flex-row items-center justify-center mt-4"
    >
      <div class="rounded-full w-3 h-3 bg-green-500 mr-2"></div>
      <div>Flights scheduled (availability TBD)</div>
    </div>
    <div v-else class="mt-4">
      loading availability ...
    </div>
  </div>
</template>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:DateRequest');

import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import {
  dateToLocalDate,
  localDateToDate,
  Segment,
  unMaybe,
  unMaybeArray,
} from '@/api/service';

import { uniq } from 'lodash-es';
import { Page } from '@/components/common/calendars/Calendar';
import { dateStr, dateFormat } from '@/format';
import { AiEcApiRtwV1Scheduled } from '@ec/rtw-data-api';

export default defineComponent({
  props: {
    date: {
      type: Object as PropType<Date>,
      required: true,
    },
    segment: {
      type: Object as PropType<Segment>,
      required: true,
    },
    isDeparture: {
      type: Boolean,
      required: true,
    },
    minDate: {
      type: Date,
      required: true,
    },
    maxDate: {
      type: Date,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const { date, minDate, maxDate } = toRefs(props);

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const isPhaseDatesBatching = computed(
      () => store.getters.isPhaseDatesBatching
    );
    const haveValidDate = computed(() => !isPhaseDatesBatching.value);

    //
    // Date
    //

    const datePickerVal = ref(props.date);
    watch(date, () => (datePickerVal.value = date.value));

    const availableDates = ref<Array<Date>>([]);
    const loadingAvailability = ref(true);

    async function updateAvailability(page: Page) {
      if (
        props.segment.departure === undefined ||
        props.segment.arrival === undefined
      ) {
        return;
      }
      loadingAvailability.value = true;
      const request = {
        departureCityCode: props.segment.departure?.code,
        arrivalCityCode: props.segment.arrival?.code,
        // WARNING: page month comes in + 1, even when accounting for 1-indexing :/
        startDate: dateToLocalDate(new Date(page.year, page.month - 1, 1)),
        endDate: dateToLocalDate(new Date(page.year, page.month, 0)),
      };
      const scheduleResult = await store.dispatch(
        ActionTypes.GetSchedule,
        request
      );
      const schedule = unMaybeArray(scheduleResult.scheduled);

      // Add all dates that have availability
      schedule
        .filter(s => s.direct > 0 || s.indirect > 0)
        .map(s => mapDates(s))
        .filter((d): d is Date => d !== undefined)
        // ensure we don't have options today or earlier
        .filter(d => d > new Date())
        .forEach(d => availableDates.value.push(d));

      // Uniquify
      availableDates.value = uniq(availableDates.value);

      // Indicate loaded
      loadingAvailability.value = false;
    }

    function mapDates(record: AiEcApiRtwV1Scheduled) {
      let date = localDateToDate(unMaybe(record.date));
      if (!date) {
        return;
      }
      const routes = unMaybe(record.routes) || [];
      if (!props.isDeparture && routes && routes.length > 0) {
        let offSet = Math.min(
          ...routes.map(value => unMaybe(value?.arrivalDayOffset) || 0)
        );
        date.setDate(date.getDate() + offSet);
        return date;
      }
      return date;
    }

    function update() {
      if (
        datePickerVal.value.getTime() >= minDate.value.getTime() &&
        datePickerVal.value.getTime() <= maxDate.value.getTime()
      ) {
        emit('update', datePickerVal.value);
      } else {
        logger.warn('.update: selected date outside of available range. ');
      }
    }

    const minDateStr = computed(() => dateStr(minDate.value, dateFormat.full));
    const maxDateStr = computed(() => dateStr(maxDate.value, dateFormat.full));

    const attributes = computed(() => {
      const attr = [];
      attr.push({
        highlight: {
          color: 'blue',
          fillMode: 'outline',
        },
        dates: date.value,
      });
      attr.push({
        highlight: {
          color: 'gray',
          contentStyle: {
            color: 'gray',
          },
        },
        dates: { end: minDate.value },
      });
      // attr.push({
      //   highlight: {
      //     color: 'gray',
      //     contentStyle: {
      //       color: 'gray',
      //     },
      //   },
      //   dates: { start: props.maxDate },
      // });
      attr.push({
        dot: 'green',
        dates: availableDates.value,
      });
      return attr;
    });

    function formatDate(date: Date) {
      return dateStr(date, dateFormat.day);
    }

    return {
      requireDialogResponse,
      isPhaseDatesBatching,
      haveValidDate,
      datePickerVal,
      minDateStr,
      maxDateStr,
      attributes,
      loadingAvailability,
      updateAvailability,
      availableDates,
      update,
      formatDate,
    };
  },
});
</script>
