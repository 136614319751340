<template>
  <div
    class="flex-grow InputBoxText flex content-center items-center min-h-8 max-w-96"
  >
    <div
      class="InputBoxText flex-grow content-center items-center resize-none focus:outline-none min-h-8 placeholder-neutral-900 focus:placeholder-neutral-400"
    >
      <textarea
        class="flex-grow min-h-10 min-w-full leading-relaxed resize-none focus:outline-none placeholder-neutral-900 focus:placeholder-neutral-400"
        rows="1"
        v-show="show && connected"
        v-model="localText"
        :placeholder="placeholder"
        maxlength="1000"
        @input="debouncedUpdate"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="submit"
        @focus="debouncedUpdate"
        id="userMessageInputBox"
        ref="inputBox"
      />
    </div>
    <div class="flex-grow min-h-0 min-w-0 max-w-96" v-if="!connected">
      <Session />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  nextTick,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';
import { debounce } from 'lodash-es';
import Session from '@/components/desktop/Session.vue';

export default defineComponent({
  components: { Session },
  emits: ['submit'],
  setup(_, { emit }) {
    const store = useStore();
    const connected = computed(() => store.state.isConnected);
    const inputBox = ref<HTMLElement | null>(null);
    const disableTextEntry = computed(() => store.getters.disabledTextEntry);
    const placeholder = computed(() =>
      disableTextEntry.value ? '' : 'Type a message...'
    );
    const show = computed(() => !disableTextEntry.value);
    // This is fairly aggressive re-focusing - might want to consider some condition based on dialog state/focus
    watch(show, () => {
      if (show.value) {
        nextTick(() => setFocus());
      }
    });

    const localText = ref('');
    const text = computed(() => store.state.dialog.inputBox.text);
    watch(text, () => {
      if (localText.value !== text.value) {
        localText.value = text.value;
      }
    });

    const debouncedUpdate = debounce(update, 100);

    function setFocus() {
      if (inputBox.value != null) {
        inputBox.value.focus();
      }
      if (text.value.length === 0) {
        resetTextarea();
      }
    }

    function update() {
      store.commit(MutationTypes.SetDialogInputBoxText, localText.value);
      updateInteraction();
    }

    function updateInteraction() {
      store.commit(MutationTypes.SetDialogInputBoxLastInteraction, new Date());
    }

    function resizeTextarea() {
      if (inputBox.value != null) {
        inputBox.value.style.height = 'auto';
        inputBox.value.style.height = inputBox.value.scrollHeight + 'px';
      }
    }
    function resetTextarea() {
      if (inputBox.value != null) {
        inputBox.value.style.height = 'auto';
        inputBox.value.style.height = '0px';
      }
    }

    function submit() {
      // Ensure we cancel all the additional debounced calls
      debouncedUpdate.cancel();
      // force the update to ensure text is updated, and not blocked by debounce
      update();
      // Force localText back to nothing
      // TODO need to revisit this
      // localText.value = '';
      // Submit
      emit('submit');
    }

    onMounted(() => {
      nextTick(() => {
        if (inputBox.value != null) {
          inputBox.value.setAttribute(
            'style',
            'height:' +
              inputBox.value.scrollHeight +
              'px;min-height:1.5em;overflow-y:hidden;'
          );
        }
      });

      if (inputBox.value != null) {
        inputBox.value.addEventListener('input', resizeTextarea);
      }
    });

    onBeforeUnmount(() => {
      if (inputBox.value != null) {
        inputBox.value.removeEventListener('input', resizeTextarea);
      }
    });

    return {
      inputBox,
      show,
      disableTextEntry,
      placeholder,
      localText,
      text,
      setFocus,
      debouncedUpdate,
      submit,
      Session,
      connected,
    };
  },
});
</script>
