<template>
  <div
    v-if="show"
    class="disconnection-message flex justify-center items-center"
  >
    {{ message }}
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from 'vue';
import { useStore } from '@/store';
import { formatDistanceStrict } from 'date-fns';

export default defineComponent({
  setup() {
    const store = useStore();

    const data = reactive({
      now: Date.now(),
    });

    const show = computed(() => !store.state.isConnected);
    const errorMessage = computed(() => store.state.errorMessage);
    const message = computed(() => {
      let m = '';
      if (store.state.nextRetry) {
        m = `${errorMessage.value ||
          ''} Trying to connect to your session. Retrying in ${formatDistanceStrict(
          data.now,
          store.state.nextRetry,
          {
            roundingMethod: 'floor',
          }
        )} ... `;
      } else if (!store.state.handleDisconnect && !store.state.isConnected) {
        m =
          errorMessage.value ||
          'Session has been disconnected. Please refresh to start a new one.';
      }
      return m;
    });

    // Update now every second;
    setInterval(() => (data.now = Date.now()), 1000);

    return {
      show,
      errorMessage,
      message,
    };
  },
});
</script>
