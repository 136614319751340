<template>
  <div class="flex flex-col">
    <OwBar class="flex-none" />
    <div
      class="flex-grow w-full flex flex-row justify-around items-center px-2"
    >
      <img
        class="h-10 ml-2 mr-1"
        src="@/assets/oneworld/oneworld.webp"
        alt="oneworld"
      />
      <img
        class="max-w-36"
        style="width: 6%"
        v-for="carrier in carriers"
        :key="carrier.code"
        :src="carrier.logoUri"
        :alt="carrier.name"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import OwBar from '@/components/common/misc/OwBar.vue';
import { useStore } from '@/store';
import {
  PartnerCarrier,
  partnerCarrierCodes,
} from '@/assets/carriers/airlines';

export default defineComponent({
  components: {
    OwBar,
  },
  setup() {
    const store = useStore();

    // This is not abstracted because of the require
    const carriers = computed<PartnerCarrier[]>(() => {
      const c = partnerCarrierCodes.map(code => {
        const uri = store.getters.logoUriForCarrierCode(code);
        return {
          code: code,
          name: store.getters.nameForCarrierCode(code),
          logoUri: uri != undefined ? require(`@/${uri}.webp`) : undefined,
        };
      });
      return c;
    });

    return {
      carriers,
    };
  },
});
</script>
