<template>
  <div class="Final">
    <div class="flex flex-row overflow-hidden">
      <City
        class="w-city flex-none"
        :city="city"
        :status="status"
        :statusMessage="statusMessage"
        :visit="visit"
        :index="index"
        :sequence="sequence"
        :draggable="false"
        :preferences="preferences"
      >
        <template #calendar>
          <CityDateRange
            :index="index"
            :city="city"
            :visit="visit"
            :arrivalSegment="lastSegment"
            :arrivalDate="arrivalDate"
            :preferences="preferences"
          />
        </template>
      </City>
      <div
        class="ticket placeholder ticket-notch-left ticket-notch-right min-w-48 ml-4 flex-grow flex justify-center items-center p-2"
        v-if="showSegments"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';

import City from './step/City.vue';
import CityDateRange from '@/components/common/calendars/CityDateRange.vue';
import { Status } from '@/api/service';

export default defineComponent({
  props: {
    showSegments: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    City,
    CityDateRange,
  },
  setup() {
    const store = useStore();

    const city = computed(() => store.getters.finalCity);
    const visit = computed(() => store.getters.finalVisit);
    const lastSegment = computed(() => store.getters.lastSegment);

    const status = ref(Status.StatusOk);
    const statusMessage = ref('');
    const index = computed(() => store.getters.segments.length);
    const sequence = computed(() =>
      lastSegment.value && lastSegment.value.sequence != null
        ? lastSegment.value.sequence + 1
        : -1
    );
    // TODO: This needs to be corrected for arrival offset
    const arrivalDate = computed(() =>
      lastSegment.value !== undefined
        ? store.getters.arrivalDateForSegment(lastSegment.value)
        : undefined
    );
    const preferences = computed(() => store.getters.arrivalPreferences);

    return {
      city,
      visit,
      lastSegment,
      status,
      statusMessage,
      index,
      sequence,
      arrivalDate,
      preferences,
    };
  },
});
</script>
