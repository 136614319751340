<template>
  <Step
    class="Origin"
    :index="0"
    :city="city"
    :visit="visit"
    :city-preferences="preferences"
    :city-status="cityStatus"
    :city-status-message="cityStatusMessage"
    :segment="segment"
    :previous-segment="previousSegment"
    :show-segments="showSegments"
    :draggable="false"
  >
    <template #calendar>
      <StartDateOrRangeCalendar
        :index="0"
        :city="city"
        :visit="visit"
        :departureDate="departureDate"
        :departureSegment="segment"
        :dateRange="startDateRange"
        :preferences="preferences"
      />
    </template>
  </Step>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

import Step from './step/Step.vue';
import { localDateToDate, Status, unMaybe } from '@/api/service';

import StartDateOrRangeCalendar from '@/components/common/calendars/StartDateOrRangeCalendar.vue';

export default defineComponent({
  props: {
    showSegments: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Step,
    StartDateOrRangeCalendar,
  },
  setup() {
    const store = useStore();

    const city = computed(() => store.getters.originCity);
    const visit = computed(() => store.getters.originVisit);
    const departureDate = computed(() => {
      if (store.getters.firstSegment?.departureTime) {
        return store.getters.departureDateForSegment(
          store.getters.firstSegment
        );
      } else {
        return localDateToDate(
          unMaybe(store.getters.firstSegment?.departureLocalDate)
        );
      }
    });
    const segment = computed(() => store.getters.firstSegment);
    const previousSegment = computed(() => undefined);
    const startDateRange = computed(() => ({
      start: localDateToDate(
        unMaybe(store.state.itinerary?.startDateRange?.start)
      ),
      end: localDateToDate(unMaybe(store.state.itinerary?.startDateRange?.end)),
    }));
    const preferences = computed(() => store.getters.departurePreferences);

    // Currently, no status on origin
    const cityStatus = ref(Status.StatusOk);
    const cityStatusMessage = ref('');

    return {
      city,
      visit,
      departureDate,
      cityStatus,
      cityStatusMessage,
      segment,
      previousSegment,
      startDateRange,
      preferences,
    };
  },
});
</script>
