<template>
  <Preference
    triggerId="preferred-carrier"
    :title="title"
    :show-edit="false"
    v-model:showEdit="showEdit"
  >
    <template v-slot:body>
      <div class="max-h-112 overflow-y-scroll">
        <div
          @click="selectAirline('any')"
          class="grid grid-cols-2 capitalize py-3.5 hover:bg-ec-notification-blue-100 cursor-pointer text-gray-600"
          :class="{
            'font-semibold': preferredCarrierName === anyAirline,
            'bg-ec-notification-blue-100': preferredCarrierName === anyAirline,
          }"
          data-tid="popup-preferred-carrier__option-any"
        >
          <div class="flex justify-center">
            <img
              src="@/assets/oneworld/oneworld.png"
              alt="logo"
              class="w-8 h-8 mr-1 justify-center"
            />
          </div>
          <div class="pt-1">{{ anyAirline }}</div>
        </div>
        <div
          v-for="(option, o) in carriers"
          :key="o"
          class="grid grid-cols-2 capitalize py-3.5 px-7 pr-12 hover:bg-ec-notification-blue-100 cursor-pointer text-gray-600"
          :class="{
            'font-semibold': preferredCarrier?.code === option.code,
            'bg-ec-notification-blue-100':
              preferredCarrier?.code === option.code,
          }"
          :value="option.code"
          @click="selectAirline(option)"
          :data-tid="`popup-preferred-carrier__option-${option.code}`"
        >
          <div class="flex justify-center mr-9">
            <img :src="logoUrl(option.code)" class="logo h-10 justify-center" />
          </div>
          <div class="pt-2">{{ beautify(option.name) }}</div>
        </div>
      </div>
    </template>
  </Preference>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

import Preference from '@/components/desktop/preferences/Preference.vue';
import {
  PartnerCarrier,
  partnerCarrierCodes,
} from '@/assets/carriers/airlines';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

const anyAirline = 'Any Airline';

export default defineComponent({
  components: {
    Preference,
  },
  setup() {
    const store = useStore();

    const showEdit = ref(false);

    const travelInfo = computed(() => store.state.travelInfo);
    const preferredCarrier = computed(() => travelInfo.value?.preferredCarrier);
    const preferredCarrierName = computed<string>(
      () =>
        carriers.value?.find(c => c.code === preferredCarrier.value?.code)
          ?.name || anyAirline
    );
    // This is not abstracted because of the require
    const carriers = computed<PartnerCarrier[]>(() => {
      return partnerCarrierCodes.map(code => {
        const uri = store.getters.logoUriForCarrierCode(code);
        return {
          code: code,
          name: store.getters.nameForCarrierCode(code),
          logoUri: uri != undefined ? require(`@/${uri}.png`) : undefined,
        };
      });
    });
    const title = computed(() => {
      return preferredCarrierName.value != null
        ? preferredCarrierName.value
        : anyAirline;
    });

    function selectAirline(carrier: PartnerCarrier): void {
      const code = carrier.code || 'any';
      const name = carrier.name || 'any airline';
      const action = sendActionBuilder(
        `set preferred carrier to ${name}`,
        formulaBuilder('rtwAction/setPreferredCarrier', [
          propertyValueBuilder('carrierCode', literalStringBuilder(code)),
        ])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
      showEdit.value = false;
    }

    function beautify(name: string): string {
      return name
        .toLowerCase()
        .split(' ')
        .map((word: string) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    }

    function logoUrl(code: string) {
      if (code) {
        const url = store.getters.logoUriForCarrierCode(code);
        if (url) return require(`@/${url}.png`);
        return undefined;
      }
      return undefined;
    }

    return {
      showEdit,
      title,
      anyAirline,
      carriers,
      preferredCarrier,
      preferredCarrierName,
      selectAirline,
      beautify,
      logoUrl,
    };
  },
});
</script>
