<template>
  <div class="Pricing">
    <div class="select-none" v-if="priceStr === undefined">
      Price to be calculated...
    </div>
    <div v-else class="flex flex-row items-baseline">
      <div class="mr-2 select-none">{{ titleStr }}:</div>
      <div class="text-lg font-normal text-ow-p-dark-blue">
        {{ priceStr }}
        <span class="text-sm text-black">{{ currencyCode }}</span>
      </div>
    </div>
    <!--    <div class="flex text-sm whitespace-pre">-->
    <!--      <span v-if="taxStr !== undefined">Tax {{ taxStr }}. </span>-->
    <!--      <span>For {{ numAdults }} {{ adultString }}, </span>-->
    <!--      <span>{{ numChildren }} {{ childString }}. </span>-->
    <!--      <span v-if="seatClass.length > 0">-->
    <!--        <span class="capitalize">{{ seatClass }}</span> class-->
    <!--      </span>-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

import { PriceSummary, Money, Price, Maybe, TravelInfo } from '@/api/service';

export default defineComponent({
  setup() {
    const store = useStore();

    const priceSummary = computed<PriceSummary | undefined>(
      () => store.state.priceSummary
    );
    const price = computed<Maybe<Price> | undefined>(
      () => priceSummary.value?.price
    );

    const travelInfo = computed<TravelInfo | undefined>(
      () => store.state.travelInfo
    );

    const numAdults = computed<number>(() =>
      travelInfo.value?.numAdults != null ? travelInfo.value?.numAdults : 0
    );

    const adultString = computed<string>(() =>
      numAdults.value === 1 ? 'Adult' : 'Adults'
    );

    const numChildren = computed<number>(() =>
      travelInfo.value?.numChildren != null ? travelInfo.value?.numChildren : 0
    );

    const childString = computed<string>(() =>
      numChildren.value === 1 ? 'Child' : 'Children'
    );

    const seatClass = computed<string>(() =>
      travelInfo.value?.seatClass != null
        ? store.getters
            .getReadibleSeatClass(travelInfo.value?.seatClass)
            .toLowerCase()
        : ''
    );

    function priceFormatter(cost: Money | undefined): string {
      // eslint-disable-next-line
      if (!cost || !cost.currencyCode || !cost.units) {
        return '-';
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: cost.currencyCode,
        minimumFractionDigits: 0,
      });
      return formatter.format(Number(cost.units));
    }

    const titleStr = computed(() => {
      if (price.value === null || price.value === undefined) {
        return undefined;
      } else if (
        price.value.total != null &&
        price.value.total.units != BigInt(0)
      ) {
        return 'Total price';
      } else if (price.value.base != null) {
        return 'Base price';
      } else {
        return undefined;
      }
    });

    const priceStr = computed(() => {
      if (price.value === null || price.value === undefined) {
        return undefined;
      } else if (
        price.value.total != null &&
        price.value.total.units != BigInt(0)
      ) {
        return priceFormatter(price.value.total);
      } else if (price.value.base != null) {
        return priceFormatter(price.value.base);
      } else {
        return undefined;
      }
    });

    const taxStr = computed(() => {
      if (
        price.value != null &&
        price.value.tax != null &&
        price.value.tax?.units != BigInt(0)
      ) {
        return priceFormatter(price.value.tax);
      } else {
        return undefined;
      }
    });

    const currencyCode = computed(() => {
      if (price.value != null && price.value.base != null) {
        if (price.value.total?.units !== 0 && price.value.tax != null) {
          return (
            price.value.base.currencyCode +
            ' (incl. ' +
            priceFormatter(price.value.tax) +
            ' taxes & fees)'
          );
        } else {
          return price.value.base.currencyCode + ' (+ taxes and fees)';
        }
      } else {
        return undefined;
      }
    });

    return {
      priceSummary,
      titleStr,
      priceStr,
      taxStr,
      currencyCode,
      numAdults,
      adultString,
      numChildren,
      childString,
      seatClass,
    };
  },
});
</script>
