<template>
  <a-popover
    class="User z-500 text-ow-p-dark-blue"
    placement="bottomRight"
    overlayClassName="popover-no-padding"
  >
    <div class="flex flex-row items-center ml-4" v-show="ready">
      <font-awesome-icon
        class="text-xl"
        v-if="userEnabledAndLoggedIn"
        :icon="['fas', 'circle-user']"
      />
      <font-awesome-icon
        class="text-xl"
        v-else
        :icon="['fal', 'circle-user']"
      />
      <div class="ml-3 capitalize whitespace-nowrap">{{ menuTitle }}</div>
    </div>
    <template #content>
      <div class="select-none">
        <div class="menu-item" v-if="userEnabledAndLoggedOut" @click="login">
          Register / Login
        </div>
        <div
          class="menu-item"
          v-if="userEnabledAndLoggedIn"
          @click="showItineraryList"
        >
          My Itineraries
        </div>
        <a-tooltip
          placement="bottomRight"
          title="This action is disabled. Please respond in the chat first."
        >
          <div
            class="menu-item disabled"
            v-if="userLoginEnabled && requireDialogResponse"
          >
            Save itinerary
          </div>
        </a-tooltip>
        <div
          class="menu-item"
          v-if="userLoginEnabled && !requireDialogResponse"
          @click="saveItinerary"
        >
          Save Itinerary
        </div>
        <!--div v-if="userEnabledAndLoggedIn">Manage account</div-->
        <div class="menu-item" key="copy-ref-id">
          <CopyToClipboard title="Copy Reference ID" :content="sessionId" />
        </div>
        <div class="menu-item" @click="initiateRestart">
          Restart Dialog
        </div>
        <div class="menu-item" v-if="userEnabledAndLoggedIn" @click="logout">
          Logout
        </div>
      </div>
    </template>

    <!-- Iframe for zen3 login -->
    <a-modal
      v-model:visible="zen3UserManagementVisible"
      :footer="null"
      :afterClose="closed"
    >
      <iframe
        id="Frame"
        ref="frameRef"
        :src="zen3UserManagementUrl"
        width="400px"
        height="600px"
      ></iframe>
    </a-modal>

    <!-- Hidden Iframe: Turn on when you need to interact with user without showing modal -->
    <iframe
      id="HiddenFrame"
      :src="zen3UserManagementUrl"
      width="0"
      height="0"
    />
  </a-popover>
</template>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:User');

import { computed, defineComponent, onMounted, ref, watch } from 'vue';

import { event } from 'vue-gtag';
import { ActionTypes } from '@/store/actions';
import { useStore, Zen3LoginUser, Zen3SocialLogin } from '@/store';

import CopyToClipboard from '@/components/common/misc/CopyToClipboard.vue';

import config from 'config';
import { MutationTypes } from '@/store/mutations';
import { isString } from 'lodash-es';
import { unMaybe, UserAction } from '@/api/service';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

export default defineComponent({
  components: {
    CopyToClipboard,
  },
  setup() {
    const store = useStore();

    const frameRef = ref<HTMLIFrameElement | null>(null);
    const ready = computed(() => store.getters.ready);
    const zen3UserManagementUrl = ref(config.ZEN3_USER_MANAGEMENT_URL);
    const zen3UserManagementVisible = ref(false);

    // LD flags
    const userLoginEnabled = computed(() =>
      store.state.ldClient?.variation('enable-user-login', true)
    );

    const loggedIn = computed(() => store.getters.loggedIn);
    const userEnabledAndLoggedOut = computed(
      () => userLoginEnabled.value && !loggedIn.value
    );
    const userEnabledAndLoggedIn = computed(
      () => userLoginEnabled.value && loggedIn.value
    );
    const menuTitle = computed(() =>
      store.state.user.firstName != undefined
        ? store.state.user.firstName
        : 'Your session'
    );
    const sessionId = computed(() => store.state.session?.id);

    function showItineraryList() {
      store.commit(MutationTypes.SetShowItineraryList, true);
    }

    async function login() {
      logger.debug('.login: ...');
      // Event
      event('user-login', { method: 'Google' });
      // Complete any token refresh state
      refreshingToken.value = false;
      // Show the modal for login
      zen3UserManagementVisible.value = true;
    }

    watch(zen3UserManagementVisible, () => {
      if (zen3UserManagementVisible.value && frameRef.value != null) {
        // Do a juggle to force the frame to refresh
        const src = frameRef.value.src;
        frameRef.value.src = '';
        frameRef.value.src = src;
      }
    });

    async function updateLoginUser(
      loginUser: Zen3LoginUser,
      userKeyId: string
    ) {
      logger.debug('.updateUser: ...');

      logger.debug('.login: zen3 user login: ' + JSON.stringify(loginUser));

      // Update user using zen3 user
      await store.commit(MutationTypes.SetUserFromZen3LoginUser, {
        loginUser,
        userKeyId,
      });

      await completeLogin();
    }

    async function updateUserFromSocialLogin(
      socialLogin: Zen3SocialLogin,
      userKeyId: string
    ) {
      logger.debug('.updateUser: ...');

      logger.debug('.login: zen3 user login: ' + JSON.stringify(socialLogin));

      // Update user using zen3 user
      await store.commit(MutationTypes.SetUserFromZen3SocialLogin, {
        socialLogin,
        userKeyId,
      });

      await completeLogin();
    }

    async function completeLogin() {
      if (
        zen3UserManagementVisible.value &&
        actionOnLogin.value === undefined
      ) {
        await welcomeMessage(store.state.user.firstName);
      }
    }

    function saveItinerary() {
      if (store.getters.requireDialogResponse) {
        return;
      }
      const action = sendActionBuilder(
        'Save my itinerary',
        formulaBuilder('rtwAction/initiateSaveItinerary', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    function initiateRestart() {
      const action = sendActionBuilder(
        'Restart',
        formulaBuilder('rtwAction/initiateRestart', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    async function welcomeMessage(firstName?: string) {
      if (store.getters.requireDialogResponse) {
        return;
      }
      let a;
      if (firstName !== undefined) {
        a = formulaBuilder('rtwAction/welcomeMessage', [
          propertyValueBuilder('firstName', literalStringBuilder(firstName)),
        ]);
      } else {
        a = formulaBuilder('rtwAction/welcomeMessage', []);
      }

      const action = sendActionBuilder(':lock:', a);
      await store.dispatch(ActionTypes.SendActions, [action]);
    }

    async function logout() {
      logger.debug('.logout: ...');
      // Event
      event('user-logout', { method: 'Google' });
      // Push thought iframe
      const iFrame = document.getElementById(
        'HiddenFrame'
      ) as HTMLFrameElement | null;
      if (iFrame != null) {
        const message = JSON.stringify({ topic: 'logout' });
        const w = iFrame.contentWindow;
        if (w != null) {
          logger.debug('.logout: posting message to iframe ...');
          logger.debug(message);
          w.postMessage(message, '*');
        }
      }
    }

    // Process possible responses from iframe
    // Note that the hidden iFrame will immediately send a message if there is a token
    async function processMessage(message: MessageEvent) {
      // Get the sent data
      let data = message.data;
      if (isString(data)) {
        try {
          data = JSON.parse(data);
        } catch (e) {
          // often fails with various messages from other components
          return;
        }
      }

      // If anything other than iframe message
      if (data.topic === undefined) {
        return;
      }

      logger.debug('.processMessage: ...');
      logger.debug(message);

      // Login callback
      if (data.topic === 'login') {
        logger.debug('.processMessage: processing login...');
        if (data.isLoggedIn === 'true' || data.isLoggedIn === 'success') {
          logger.debug('.processMessage: login true.');

          if (data.loginDetails.LoginUser) {
            await updateLoginUser(
              data.loginDetails.LoginUser as Zen3LoginUser,
              data.userKeyId
            );
          } else if (data.loginDetails.SocialLogin) {
            await updateUserFromSocialLogin(
              data.loginDetails.SocialLogin as Zen3SocialLogin,
              data.userKeyId
            );
          } else {
            logger.error(
              '.processMessage: data from login message unexpected.'
            );
          }
          zen3UserManagementVisible.value = false;
          // Run action on login if specified
          if (actionOnLogin.value !== undefined) {
            store.dispatch(ActionTypes.SendActions, [actionOnLogin.value]);
            actionOnLogin.value;
          }
          actionOnLogin.value = undefined;
        } else {
          logger.error('.processMessage: login failed. No recourse.');
          // login failed?
        }
      }
      // Logout callback
      else if (data.topic === 'logout') {
        logger.debug('.processMessage: processing logout...');
        store.commit(MutationTypes.ClearUser);
        // If we are refreshing the token, then chain the login
        if (refreshingToken.value) {
          logger.debug(
            '.processMessage: in token refresh flow ... running login.'
          );
          login();
        }
      }
      // unknowns
      else {
        logger.warn(
          '.processMessage: got an unknown messages. Could be some other tool sending messages with the same message topic structure.'
        );
      }
    }

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    onMounted(() => {
      window.addEventListener('message', processMessage);
    });

    function closed() {
      logger.debug('.closed: ...');
      // On closed ... if action is set, then clear it and send a skip login action
      if (actionOnLogin.value != undefined) {
        logger.debug('.closed: user has skipped authentication.');
        actionOnLogin.value = undefined;
        const skipAction = sendActionBuilder(
          'Skip login',
          formulaBuilder('rtwAction/skipAuthentication', [])
        );
        store.dispatch(ActionTypes.SendActions, [skipAction]);
      }
    }

    //
    // Capture last system message and follow login request
    const systemMessages = computed(() => store.getters.systemMessages);
    const lastSystemMessage = computed(() => store.getters.lastSystemMessage);
    const coveredSystemMessages = ref(0);
    const actionOnLogin = ref<UserAction | undefined>(undefined);
    const refreshingToken = ref(false);

    watch(lastSystemMessage, () => {
      // Avoid double processing
      // TODO: make better
      if (coveredSystemMessages.value < systemMessages.value.length) {
        coveredSystemMessages.value = systemMessages.value.length;
        const action = unMaybe(
          lastSystemMessage.value?.metadata?.systemIntent?.authenticationAction
        );
        if (action !== undefined) {
          // System wants to ensure we are logged in, so will run a logout out, which will chain a login
          logger.debug(
            'Running authentication then action ' + JSON.stringify(action)
          );
          actionOnLogin.value = action;
          // If we think we are logged in then log out first to ensure token refresh
          if (loggedIn.value) {
            refreshingToken.value = true;
            logout();
          } else {
            login();
          }
        }
      }
    });

    return {
      frameRef,
      ready,
      closed,
      zen3UserManagementUrl,
      zen3UserManagementVisible,
      userLoginEnabled,
      loggedIn,
      userEnabledAndLoggedOut,
      userEnabledAndLoggedIn,
      menuTitle,
      sessionId,
      requireDialogResponse,
      showItineraryList,
      login,
      saveItinerary,
      initiateRestart,
      logout,
    };
  },
});
</script>
