<template>
  <Desktop />
  <!-- Blocking of mobile handled in desktop -->
  <!--  <Mobile v-if="isMobile()" />  -->
  <!--  <Desktop v-else />  -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// import isMobile from 'is-mobile';

import Desktop from '../components/desktop/Desktop.vue';
//import Mobile from '../components/mobile/Mobile.vue';

export default defineComponent({
  components: {
    Desktop,
    //Mobile,
  },
  setup() {
    return {
      // isMobile,
    };
  },
});
</script>
