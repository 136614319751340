<template>
  <div
    class="DialogHeader bg-white shadow h-16 px-4 py-2 flex flex-row items-center min-h-16 flex-shrink-0"
  >
    <Avatar :size="35" />
    <div class="flex-grow ml-2 text-ow-p-dark-blue">
      Round the World
      <a-tooltip placement="topLeft" :title="betaTitle">
        <span class="ml-2 px-1.5 py-1 bg-ow-p-light-blue rounded text-sm">
          Beta
        </span>
      </a-tooltip>
    </div>
    <div class="button-call-to-action" @click="initiateRestart">
      Restart
    </div>
    <a-tooltip placement="topLeft">
      <template #title>
        <div>Click to see some examples to help you through the dialog</div>
      </template>
      <!-- tooltip trigger -->
      <div
        class="dialog-header-button ml-2 HelpTrigger"
        @click="toggleShowHelp"
      >
        <font-awesome-icon :icon="['fal', 'question']" />
      </div>
    </a-tooltip>
    <Debug class="ml-2" v-if="debug" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';

import Debug from '@/components/common/dialog/Debug.vue';
import Avatar from '@/components/common/dialog/Avatar.vue';
import { formulaBuilder, sendActionBuilder } from '@/api/kr';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  components: {
    Avatar,
    Debug,
  },
  setup() {
    const store = useStore();
    const debug = computed(() => store.state.debug);
    const betaTitle = ref(
      'Help us make this experience better by selecting "Give Feedback" in the chat footer'
    );

    function initiateRestart() {
      const action = sendActionBuilder(
        'Restart',
        formulaBuilder('rtwAction/initiateRestart', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    function toggleShowHelp() {
      store.commit(
        MutationTypes.SetDialogShowHelp,
        !store.state.dialog.showHelp
      );
    }

    return {
      debug,
      betaTitle,
      initiateRestart,
      toggleShowHelp,
    };
  },
});
</script>
