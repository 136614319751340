<template>
  <div class="Requirements text-ow-p-dark-blue">
    <div class="onboarding-header">
      <div class="title">
        Trip Requirements
      </div>
      <div class="subtitle">
        No need to memorize the rules, we will help you navigate any issues.
      </div>
    </div>
    <div
      class="grid grid-cols-1 pb-4 px-4 md:grid-cols-2 gap-x-2 md:my-6 md:mx-0 md:pt-4"
    >
      <div class="my-2 md:mx-4 md:my-0">
        <div
          v-for="x in contentA.length"
          :key="x"
          class="flex py-4 px-4 max-w-96"
        >
          <font-awesome-icon
            class="mt-1 mr-4 text-ow-s-emerald text-xl"
            :icon="['fas', 'check-circle']"
          />
          <div class="text-left">{{ contentA[x - 1] }}</div>
        </div>
      </div>
      <div class="my-2 md:mx-4 md:my-0">
        <div
          v-for="x in contentB.length"
          :key="x"
          class="flex py-4 px-4 max-w-96"
        >
          <font-awesome-icon
            class="mt-1 mr-4 text-ow-s-emerald  text-xl"
            :icon="['fas', 'check-circle']"
          />
          <div class="text-left">{{ contentB[x - 1] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

const contentA = [
  'Trips must start and finish in the same city.',
  'Travel must be completed within 12 months of departure from your starting city.',
  'A trip can include up to 16 flights with visits to at least 3 continents.',
];

const contentB = [
  'You must cross both the Atlantic Ocean and the Pacific Ocean in your journey.',
  'Your trip must be in a continuous forward direction, West to East or East to West. Backtracking within a continent is generally permitted.',
];

export default defineComponent({
  setup() {
    const show = ref(true);

    return {
      show,
      contentA,
      contentB,
    };
  },
});
</script>
