<template>
  <transition name="fade-in">
    <div class="relative" :style="outerStyle">
      <div
        class="absolute top-0 left-0 rounded-full transform bg-ow-s-sapphire opacity-50"
        style="height: 70%; width: 70%; --tw-translate-x: 0rem; --tw-translate-y: 15%;"
      ></div>
      <div
        class="absolute top-0 left-0 rounded-full transform bg-ow-p-dark-blue opacity-50"
        style="height: 70%; width: 70%; --tw-translate-x: 30%; --tw-translate-y: 15%;"
      ></div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: Number,
      required: false,
      default: 25,
    },
  },
  setup(props) {
    const outerStyle = computed(() => {
      return {
        height: props.size + 'px',
        width: props.size + 'px',
      };
    });

    return {
      outerStyle,
    };
  },
});
</script>
