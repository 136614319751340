<template>
  <div
    class="Dialog bg-white flex flex-col overflow-hidden border-t border-gray-200"
  >
    <div class="flex-grow flex flex-col overflow-hidden relative">
      <DialogHelp
        v-show="showHelp"
        class="absolute top-0 left-0 h-full w-full"
      />
      <DialogHeader />
      <div
        class="flex flex-col flex-grow overflow-y-auto overflow-x-hidden px-6"
        id="messagesRef"
        ref="messagesRef"
        @mouseenter="isOver = true"
        @mouseleave="isOver = false"
      >
        <transition-group name="messages">
          <Message
            class="first:mt-auto"
            v-for="(message, m) in filteredMessages"
            :key="m"
            :message="message"
            :isLastRequestSystemMessage="isLastRequestSystemMessage(m)"
            :isLastUserMessage="lastUserMessage(m)"
            @attention="debouncedScrollToBottom"
          />
          <Status
            key="status"
            :class="{ 'mt-auto': filteredMessages.length === 0 }"
            @attention="debouncedScrollToBottom"
          />
          <!-- bottom div, used to scroll to -->
          <div class="min-h-3 w-full" ref="bottomRef" key="bottom"></div>
        </transition-group>
      </div>
    </div>
    <InputBox />
    <DialogFooter />
  </div>
</template>

<style lang="scss" scoped>
.no-scrollbar {
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
}
.no-scrollbar::-webkit-scrollbar {
  display: none; // Safari and Chrome
}
</style>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:Dialog');

import {
  defineComponent,
  ref,
  computed,
  watch,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useStore } from '@/store';
import { UserMessage, isSystemMessage, SystemMessage } from '@/api/service';
import { ActionTypes } from '@/store/actions';

import DialogHelp from './DialogHelp.vue';
import DialogHeader from './DialogHeader.vue';
import Message from './Message.vue';
import Status from './Status.vue';
import InputBox from './input-box/InputBox.vue';
import DialogFooter from './DialogFooter.vue';
import { debounce } from 'lodash-es';

export default defineComponent({
  components: {
    DialogHelp,
    DialogHeader,
    Message,
    Status,
    InputBox,
    DialogFooter,
  },
  setup() {
    const store = useStore();

    const messagesRef = ref<HTMLElement | null>(null);
    const isOver = ref(false);
    const showHelp = computed(() => store.state.dialog.showHelp);
    const messages = computed(() => store.state.dialog.messages);

    // NOTE: currently no filtering
    const filteredMessages = computed(() => messages.value);

    async function send(input: string) {
      await store.dispatch(ActionTypes.SendText, input);
    }

    function isInformative(s: SystemMessage) {
      return s.metadata?.systemIntent?.type === 'informative';
    }

    // TODO: move to a getter
    // index must not be informative, and the remainder of messages must be system messages and informative
    function isLastRequestSystemMessage(index: number): boolean {
      const toCheck = filteredMessages.value.slice(index);

      // Check that all messages are system messages
      if (toCheck.every(s => isSystemMessage(s))) {
        // Check that message is a request
        if (isInformative(toCheck[0] as SystemMessage)) {
          return false;
        } else {
          // If is a request, make sure all following messages are information for it to be the last request
          if (toCheck.length === 1) {
            return true;
          } else {
            return toCheck
              .slice(1)
              .every(
                m => isSystemMessage(m) && isInformative(m as SystemMessage)
              );
          }
        }
      } else {
        return false;
      }
    }

    function lastUserMessage(index: number) {
      const types: boolean[] = filteredMessages.value.map(
        (message: UserMessage | SystemMessage) => {
          return isSystemMessage(message);
        }
      );
      const lastIndex = types.lastIndexOf(false);
      return index === lastIndex;
    }

    //
    // Auto Scroll
    //
    const bottomRef = ref<HTMLElement | null>(null);

    function scrollToBottom() {
      const bottomDiv = (bottomRef.value as unknown) as HTMLElement;
      if (!bottomDiv) {
        logger.error('.scrollToBottom: Could not get bottom ref for scrolling');
        return;
      }
      bottomDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }

    // Avoid a lot of scrolling triggers
    const debouncedScrollToBottom = debounce(scrollToBottom, 200);

    const resizeObserver = new ResizeObserver(debouncedScrollToBottom);

    function setupAutoScroll() {
      scrollToBottom();
      if (messagesRef.value != null) {
        resizeObserver.observe(messagesRef.value);
      }
    }
    function disableAutoScroll() {
      if (messagesRef.value != null) {
        resizeObserver.unobserve(messagesRef.value);
      }
    }

    //
    // Lifecycle
    onMounted(() => setupAutoScroll());
    onBeforeUnmount(() => disableAutoScroll());

    watch(messages, () => {
      debouncedScrollToBottom();
      nextTick(() => store.dispatch(ActionTypes.UpdateMatchingToolTips));
    });

    return {
      messagesRef,
      isOver,
      bottomRef,
      showHelp,
      filteredMessages,
      send,
      isLastRequestSystemMessage,
      lastUserMessage,
      debouncedScrollToBottom,
    };
  },
});
</script>
