<template>
  <div class="Preferences bg-ow-p-light-blue flex flex-row items-center">
    <TaskInfo v-if="isAmtHit" />
    <div class="flex-grow flex flex-row items-center gap-4 overflow-auto">
      <PreferredCarrier v-if="showPreferredCarrier" />
      <SeatClass />
      <Passengers />
    </div>
    <ShowInspired class="ml-4" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref } from 'vue';

import PreferredCarrier from './PreferredCarrier.vue';
import Passengers from './Passengers.vue';
import SeatClass from './SeatClass.vue';
import ShowInspired from './ShowInspired.vue';
import TaskInfo from '@/components/desktop/TaskInfo.vue';
import { useStore } from '@/store';

export default defineComponent({
  components: {
    TaskInfo,
    ShowInspired,
    PreferredCarrier,
    Passengers,
    SeatClass,
  },
  setup() {
    const store = useStore();
    const isAmtHit = computed(() => store.getters.isAmtHit);

    const showPreferredCarrier = ref(true);
    const ldClient = computed(() => store.state.ldClient);
    watch(ldClient, newVal => {
      if (newVal) {
        const flagValue = newVal.variation('preferred-carrier', false);
        showPreferredCarrier.value = flagValue;
      }
    });

    return {
      isAmtHit,
      showPreferredCarrier,
    };
  },
});
</script>
