<template>
  <div v-if="show">
    <div
      v-if="background"
      class="w-screen h-screen absolute bg-gray-700 bg-opacity-70 inset-0 z-4000 overflow-hidden"
    />

    <div
      class="Modal bg-white rounded-lg shadow-lg fixed z-5000 mx-auto my-auto"
      :style="modalStyle"
      ref="modal"
      v-click-outside="hide"
    >
      <div
        class="modal-arrow top"
        v-if="arrow !== 'none' && arrowVerticalType === 'top'"
      >
        <span class="arrow" :class="arrowHorizontalType" />
      </div>
      <div class="contentWrapper rounded h-full flex flex-col space-between">
        <div
          class="absolute top-3 right-4 text-gray-400 hover:text-gray-600 cursor-pointer"
          v-if="showClose"
          @click="hide"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </div>
        <div
          class="p-3"
          :class="headerClass"
          v-if="!!$slots.header"
          :style="headerStyle"
        >
          <slot name="header" />
        </div>
        <div
          class="flex-grow overflow-y-scroll overflow-x-hidden"
          :class="bodyClass"
          :style="bodyStyle"
        >
          <slot name="body" />
        </div>
        <div
          class="p-3"
          :class="footerClass"
          v-if="!!$slots.footer"
          :style="footerStyle"
        >
          <slot name="footer" />
        </div>
      </div>
      <div
        class="modal-arrow bottom"
        v-if="arrow !== 'none' && arrowVerticalType === 'bottom'"
      >
        <span class="arrow-bottom" :class="arrow" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.Modal {
  height: fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  width: -webkit-fit-content;
  overflow: hidden;
}
.contentWrapper {
  height: auto !important;
}
.modal-arrow {
  .arrow {
    content: '';
    display: block;
    transform: rotate(90deg);
    width: 0;
    height: 0;

    position: absolute;
    top: -12px;

    background: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid grey;

    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
    }
  }
  .arrow-bottom {
    content: '';
    display: block;
    transform: rotate(270deg);
    width: 0;
    height: 0;

    position: absolute;
    bottom: -12px;

    background: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid grey;

    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive, computed, watch, nextTick } from 'vue';

const OFFSET_X = 16;
const OFFSET_Y = 8;
const OFFSET_MIN = 16;

export default defineComponent({
  props: {
    show: Boolean,
    background: {
      type: Boolean,
      required: false,
      default: false,
    },
    triggerId: {
      type: String,
      required: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    preventClickHide: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrow: {
      type: String,
      required: false,
      default: 'none',
    },
    top: {
      type: Number,
      required: false,
      default: 0,
    },
    left: {
      type: Number,
      required: false,
      default: 0,
    },
    bottom: {
      type: Number,
      required: false,
      default: 0,
    },
    right: {
      type: Number,
      required: false,
      default: 0,
    },
    headerStyle: {
      type: Object,
      required: false,
      default: null,
    },
    bodyStyle: {
      type: Object,
      required: false,
      default: null,
    },
    footerStyle: {
      type: Object,
      required: false,
      default: null,
    },
    headerClass: {
      type: String,
      required: false,
      default: null,
    },
    bodyClass: {
      type: String,
      required: false,
      default: null,
    },
    footerClass: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = ref(null);
    const triggerElement = ref<HTMLElement>();
    const triggerBounds = ref<DOMRect>();

    const arrowHorizontalType = ref('left');
    const arrowVerticalType = ref('left');
    const offset = reactive({
      top: ref(0),
      left: ref(0),
      bottom: ref(0),
      right: ref(0),
    });

    function hide() {
      if (!props.preventClickHide) {
        emit('update:show', false);
      }
    }

    const modalStyle = computed<object>(() => {
      if (props.background) {
        return {
          top: '5%',
          left: '5%',
          right: '5%',
          bottom: '5%',
        };
      }
      return {
        top: offset.top !== 0 && `${offset.top}px`,
        left: offset.left !== 0 && `${offset.left}px`,
        bottom: offset.bottom !== 0 && `${offset.bottom}px`,
        right: offset.right !== 0 && `${offset.right}px`,
      };
    });

    function update() {
      nextTick(() => {
        const m = (modal.value as unknown) as HTMLElement;
        if (props.show && props.triggerId) {
          const trigger = document.getElementById(props.triggerId);
          if (trigger) {
            triggerElement.value = trigger;
            triggerBounds.value = trigger.getBoundingClientRect();

            // arrowHorizontalType
            if (triggerBounds.value.x < (window.innerWidth * 2) / 3) {
              arrowHorizontalType.value = 'left';
              const offsetLeft = Math.max(
                triggerBounds.value.x - OFFSET_X,
                OFFSET_MIN
              );
              offset.left = offsetLeft;
            } else {
              arrowHorizontalType.value = 'right';
              offset.left =
                triggerBounds.value.x +
                triggerBounds.value.width / 2 +
                OFFSET_X;
            }

            offset.left = offset.left + props.left;

            // arrowVerticalType
            if (triggerBounds.value.y < (window.innerHeight * 2) / 3) {
              arrowVerticalType.value = 'top';
              offset.top =
                triggerBounds.value.y + triggerBounds.value.height + OFFSET_Y;
              if (offset.top + m.scrollHeight > window.innerHeight) {
                offset.top = Math.max(
                  OFFSET_Y,
                  window.innerHeight - (OFFSET_Y + m.scrollHeight)
                );
              }
            } else {
              arrowVerticalType.value = 'bottom';
              offset.bottom =
                window.innerHeight - triggerBounds.value.y + OFFSET_Y;
            }

            // Force inside window bounds
            // TODO
          }
        } else if (props.bottom && props.left) {
          offset.left = props.left - OFFSET_X;
          offset.bottom = window.innerHeight - props.bottom + OFFSET_Y;
        } else if (props.show) {
          offset.top = OFFSET_MIN * 4;
          offset.left = OFFSET_MIN * 4;
          offset.bottom = OFFSET_MIN * 4;
          offset.right = OFFSET_MIN * 4;
        }
      });
    }

    watch(
      () => props.show,
      () => update()
    );

    return {
      modal,
      offset,
      arrowHorizontalType,
      arrowVerticalType,
      hide,
      modalStyle,
    };
  },
});
</script>
