<template>
  <div class="bg-gray-200 flex flex-row items-center min-h-6 px-3">
    <div
      class="flex flex-row items-center flex-grow justify-between text-gray-500"
    >
      <a
        href="https://ec.ai"
        target="_blank"
        class="flex-grow-0 all-small-caps whitespace-nowrap cursor-pointer hover:text-ow-p-dark-blue"
      >
        Powered by Elemental Cognition
      </a>
      <div
        v-if="showFeedbackBtn"
        class="flex-grow-0 justify-self-end all-small-caps whitespace-nowrap cursor-pointer hover:text-ow-p-dark-blue"
        :class="{
          'cursor-pointer': requireDialogResponse,
        }"
        @click="triggerFeedback"
      >
        Give feedback
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { formulaBuilder, sendActionBuilder } from '@/api/kr';

export default defineComponent({
  props: {
    text: String,
  },
  setup() {
    const store = useStore();
    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    const showFeedbackBtn = computed(() =>
      store.state.ldClient?.variation('feedback', false)
    );

    function triggerFeedback() {
      if (requireDialogResponse.value) {
        return;
      }
      const action = sendActionBuilder(
        'Provide Feedback',
        formulaBuilder('rtwAction/provideFeedback', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    return {
      requireDialogResponse,
      showFeedbackBtn,
      triggerFeedback,
    };
  },
});
</script>
