<template>
  <div>
    <div
      class="Map absolute overflow-hidden top-0 bottom-0 left-0 right-0"
      id="mapWrapper"
      ref="mapWrapper"
    ></div>
    <PossibleStopOverPopover ref="stopOverToolTip" :segment="segmentHovering" :anchor="anchorStopOverPopover" :visible="hoveringPossibleStopOverIcon"/>
  </div>
</template>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:Map');

import mapboxgl, { Map, Marker } from 'mapbox-gl';
import { debounce, isEqual } from 'lodash-es';

import {
  defineComponent,
  reactive,
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';

/* Custom Map Markers */
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons';
library.add(faMapMarker);

import arc from 'arc';
import config from 'config';

import {
  Itinerary,
  Segment,
  City,
  Country,
  Continent,
  UserMessage,
  SystemMessage,
  Maybe,
  unMaybe,
  unMaybeArray,
  isSystemMessage,
  Cities,
} from '@/api/service';

import { useStore } from '@/store';
import { event } from 'vue-gtag';
import PossibleStopOverPopover from "@/components/common/itinerary/step/PossibleStopOverPopover.vue";

const numberArcPoints = 100;
const iconPoint = Math.floor((numberArcPoints * 2) / 5);

const continentMap: Record<string, string> = {
  Europe: 'Europe-Middle East',
  'Middle East': 'Europe-Middle East',
  'North America': 'North America',
  'North or South': 'North America',
  'South America': 'South America',
  Africa: 'Africa',
  Asia: 'Asia',
  'Southwest Pacific': 'South West Pacific',
  'South West Pacific': 'South West Pacific',
};

const similarCountries: Record<string, string[]> = {
  Africa: ['South Africa'],
};

export default defineComponent({
  components: {PossibleStopOverPopover},
  setup() {
    const store = useStore();

    const mapVals = reactive({
      zoom: 0.9,
      minZoom: 0.9,
      maxZoom: 10,
      center: ref({ lng: -52.694931, lat: 42.244627 }),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {},
      bounds: ref(),
      maxBounds: {},
      showMap: true,
      geoJson: undefined,
      fillColor: '#fff',
      arcLayers: ref(),
      arcLayerGroup: ref(),
      endpoints: ref(),
    });

    const mapRef = ref<Map>();
    const showMap = ref(true);

    const continents = computed(() =>
      unMaybeArray(store.state.geographyData?.continents)
    );
    const countries = computed(() => store.state.geographyData?.countries);

    const itinerary = computed<Itinerary | undefined>(
      () => store.state.itinerary
    );
    const currentStopOverSegment = computed<Segment | undefined>(
      () => store.state.currentStopOverSegment
    );
    const showItinerary = computed(() => store.state.showItinerary);
    const origin = computed<City | undefined>(() => store.getters.originCity);
    const segments = computed(() =>
      itinerary.value && itinerary.value.segments
        ? itinerary.value.segments
        : []
    );
    const inspirationCities = computed(
      () => store.state.inspirationItineraryCities
    );
    const inspirationMarkers = ref<Marker[]>([]);
    const itineraryCitiesMarkers = ref<Marker[]>([]);
    const stopOverCitiesMarkers = ref<Marker[]>([]);
    const stopOverMarkers = ref<Marker[]>([]);
    const layers = ref<string[]>([]);
    const sources = ref<string[]>([]);

    const itineraryOrdered = computed(() => store.getters.itineraryOrdered);
    const messages = computed<Array<UserMessage | SystemMessage>>(
      () => store.state.dialog.messages
    );

    function zoomFitMarkers() {
      const pinLocals = itineraryCitiesMarkers.value.map(marker =>
        marker.getLngLat()
      );

      if (pinLocals.length > 0) {
        const centerLat =
          pinLocals.reduce((prev, current) => prev + current.lng, 0) /
          pinLocals.length;
        const centerLng =
          pinLocals.reduce((prev, current) => prev + current.lat, 0) /
          pinLocals.length;

        mapRef.value?.setCenter([centerLat, centerLng]);
      }
    }

    function mapFilters(codesFiltered: string[]) {
      const countryFilter = [
        'match',
        ['get', 'iso_a2'],
        codesFiltered,
        true,
        false,
      ];
      return ['all', countryFilter];
    }

    function clearContinentHighlights() {
      if (mapRef.value?.getLayer('custom_country_boundaries')) {
        mapRef.value?.setFilter('custom_country_boundaries', mapFilters(['']));
      }
    }

    function highlightAndZoomToContinents(matchingContinents: Continent[]) {
      const continentCodes: string[] = matchingContinents
        ?.map((c: Continent) => unMaybe(c.code))
        .filter((code: string | undefined) => code !== undefined) as string[];
      const countryCodes =
        countries.value
          ?.filter(
            (c: Maybe<Country>) =>
              c?.continentCode && continentCodes.includes(c.continentCode)
          )
          ?.map((c: Maybe<Country>) => unMaybe(unMaybe(c)?.code)) || [];
      const codesFiltered: string[] = countryCodes?.filter(
        code => code !== undefined
      ) as string[];

      // if Morocco, also highlight western sahara
      if (codesFiltered.includes('MA')) codesFiltered.push('EH');

      mapRef.value?.setFilter(
        'custom_country_boundaries',
        mapFilters(codesFiltered)
      );
    }

    function drawMarkerLabel(city: City, orderNumber : number, markerList: Marker[]) {
      if (city?.lon && city?.lat && mapRef.value) {
        const el = document.createElement('div');
        el.className = 'name-marker';
        el.innerHTML = `${city.name}`;
        el.style.marginTop = '10px';
        el.style.padding = '4px';
        el.style.borderRadius = '12px';
        el.style.fontWeight = '500';
        el.style.zIndex = `${200 - 2 * Math.floor(city.lat)}`;
        if (orderNumber === -1) {
          el.style.fontSize = "12px";
        }
        const labelMarker = new mapboxgl.Marker(el);
        labelMarker.setLngLat([city.lon, city.lat]).addTo(mapRef.value);
        markerList.push(labelMarker);
      }
    }

    function createMarker(
      color: string,
      orderNumber: number,
      markerClass: string,
      withIndicator: boolean
    ) {
      const wrapperEl = document.createElement('div');
      wrapperEl.className = `name-marker ${markerClass}`;

      const orderNumberTopOffset = orderNumber > 0 ? 0 : -10;
      const orderNumberLeftOffset = orderNumber > 0 ? 0 : 5;

      const pinEl = document.createElement('span');
      pinEl.innerHTML = icon({
        prefix: 'fas',
        iconName: 'map-marker',
      }).html.join('');
      pinEl.style.color = color;
      pinEl.style.fontSize = '28px';
      pinEl.style.top = `${orderNumberTopOffset}px`;
      pinEl.style.left = `${orderNumberLeftOffset}px`;
      pinEl.style.position = 'relative';
      pinEl.style.border = '1px sold white';


      if(orderNumber === -1) {
          const el = document.createElement('span');
          el.style.width = '15px';
          el.style.height = '15px';
          el.style.borderRadius = '50%';
          el.style.backgroundColor = color;
          el.style.border = '2px solid white';
          el.style.display= 'inline-block';
          wrapperEl.appendChild(el);
      } else {
          wrapperEl.appendChild(pinEl);
      }

      if (orderNumber > 0) {
        const numberEl = document.createElement('div');
        if (withIndicator) {
          numberEl.innerHTML = `${orderNumber}`;
        }
        numberEl.style.top = `-30px`;
        numberEl.style.left = orderNumber < 10 ? '7px' : '3px';
        numberEl.style.fontWeight = 'bold';
        numberEl.style.color = 'white';
        numberEl.style.position = 'relative';
        wrapperEl.appendChild(numberEl);
      } else if (orderNumber === 0) {
        const middleCircle = document.createElement('span');
        if (withIndicator) {
          middleCircle.innerHTML = icon({
            prefix: 'fas',
            iconName: 'pennant',
          }).html.join('');
        }
        middleCircle.style.color = 'white';
        middleCircle.style.position = 'relative';
        middleCircle.style.top = '-20px';
        middleCircle.style.left = '-11px';
        middleCircle.style.fontSize = '10px';
        wrapperEl.appendChild(middleCircle);
      }

      return wrapperEl;
    }

      function createAirplaneIcon(
          ) {
          const wrapperEl = document.createElement('div');
          let planeEl = document.createElement('span');
          planeEl.innerHTML = icon({
              prefix: 'fas',
              iconName: 'plane',
          }).html.join('');
          planeEl.style.fontSize = '15px';
          planeEl.style.color = 'grey';
          planeEl.style.position = 'relative';
          wrapperEl.appendChild(planeEl);

          return wrapperEl;
      }

    function drawCityMarker(
      orderNumber: number,
      city: City,
      color: string,
      markerList: Marker[]
    ) {
      if (city?.lon && city?.lat && mapRef.value) {

        const wrapperEl = createMarker(
          color,
          orderNumber,
          'city-marker',
          true
        );
        wrapperEl.style.zIndex = `${200 - 2 * Math.floor(city.lat)}`;
        const marker: Marker = new mapboxgl.Marker(wrapperEl);
        drawMarkerLabel(city, orderNumber, markerList);
        marker.setLngLat([city.lon, city.lat]).addTo(mapRef.value);
        markerList.push(marker);
      }
    }

    function drawHomeMarker(
      orderNumber: number,
      city: City,
      markerList: Marker[]
    ) {
      const wrapperEl = createMarker(
        '#515C3A',
        orderNumber,
        'home-marker',
        true
      );

      const marker: Marker = new mapboxgl.Marker(wrapperEl);
      if (city?.lon && city?.lat && mapRef.value) {
        drawMarkerLabel(city, orderNumber, markerList);
        marker.setLngLat([city.lon, city.lat]).addTo(mapRef.value);
        markerList.push(marker);
      }
      return marker;
    }

    function drawInspirationMarker(
      orderNumber: number,
      city: City,
      markerList: Marker[]
    ) {
      const wrapperEl = createMarker('#F6CE5C', 0, 'inspiration-marker', false);

      const marker: Marker = new mapboxgl.Marker(wrapperEl);
      if (city?.lon && city?.lat && mapRef.value) {
        drawMarkerLabel(city, orderNumber, markerList);
        marker.setLngLat([city.lon, city.lat]).addTo(mapRef.value);
        markerList.push(marker);
      }
      return marker;
    }

    function drawSegmentMarkers() {
      // clear out markers
      // eslint-disable-next-line
      itineraryCitiesMarkers.value?.forEach((marker: any) => marker.remove());

      // Create new markers
      itineraryCitiesMarkers.value = [];
      segments.value.forEach((segment: Maybe<Segment>, idx: number) => {
        const s: Segment | undefined = unMaybe(segment);
        if (s?.departureVisit && s.departure) {
          if (store.getters.isOriginVisit(s?.departureVisit)) {
            // if origin city
            drawHomeMarker(idx, s.departure, itineraryCitiesMarkers.value);
          } else {
            // otherwise draw interim marker
            const index = segment?.sequence != null && segment?.sequence > 0 ? segment?.sequence : -2;
              const cityColor = itineraryOrdered.value
                  ? index === -1
                      ? '#808080'
                      : index === 0
                          ? '#515C3A'
                          : '#120C80'
                  : '#9fa6b2';
            drawCityMarker(index, s.departure, cityColor, itineraryCitiesMarkers.value);
          }
        }
      });
    }

    function removePossibleStopOverMarkers() {
      stopOverMarkers.value?.forEach((marker: any) => marker.remove());
    }

    function removePossibleStopOverLabels() {
      stopOverCitiesMarkers.value?.forEach((marker: any) => marker.remove());
    }

    function drawPossibleStopOverMarkers(segment: Segment) {
        removePossibleStopOverMarkers();
        removePossibleStopOverLabels();
        let cityColors = ["#00cc66", "#6495ed", "#DA70D6", "#ff9999", "#cccc00"];
        let possibleOneStopOverCitiesCount = segment?.possibleOneStopOverCities?.length;
        let colorIndex = 0;
        if (possibleOneStopOverCitiesCount !== undefined && possibleOneStopOverCitiesCount > 0) {
            segment?.possibleOneStopOverCities?.forEach((stopOver: Maybe<City>) => {
                const stopOverCity: City | undefined = unMaybe(stopOver);
                if (stopOverCity) {
                    drawCityMarker(-1, stopOverCity, cityColors[colorIndex], stopOverCitiesMarkers.value);
                }
                colorIndex++;
                colorIndex = colorIndex%5;
            });

        }
        colorIndex = 0;
        let possibleTwoStopOverCitiesCount = segment?.possibleTwoStopOverCities?.length;
        if (possibleTwoStopOverCitiesCount !== undefined && possibleTwoStopOverCitiesCount > 0) {
            segment?.possibleTwoStopOverCities?.forEach((cities: Maybe<Cities>) => {
                cities?.cities?.forEach((stopOver: Maybe<City>) => {
                        const stopOverCity: City | undefined = unMaybe(stopOver);
                        if (stopOverCity) {
                            drawCityMarker(-1, stopOverCity, cityColors[colorIndex], stopOverCitiesMarkers.value);
                        }
                    }
                );
                colorIndex++;
                colorIndex = colorIndex%5;
            });
        }

    }

    function drawInspirationMarkers() {
      // clear out markers
      // eslint-disable-next-line
      inspirationMarkers.value?.forEach((marker: any) => marker.remove());

      // Create new markers
      inspirationMarkers.value = [];
      const segmentDepartureCodes: string[] = [];
      segments?.value.forEach((segment: Maybe<Segment>) => {
        const s: Segment | undefined = unMaybe(segment);
        if (s?.departure && s?.departure?.code) {
          segmentDepartureCodes.push(s?.departure?.code);
        }
      });
      const filteredInspirationCities = inspirationCities.value?.filter(
        (c: City) => c?.code && !segmentDepartureCodes.includes(c?.code)
      );
      filteredInspirationCities?.forEach((city: City, idx: number) =>
        drawInspirationMarker(idx, city, inspirationMarkers.value)
      );
    }

    function drawFlightArcs() {
      drawSegmentMarkers();
      zoomFitMarkers();

      // remove current arcs
      if (layers.value && layers.value.length > 0) {
        layers.value.forEach((layerName: string) =>
          mapRef.value?.removeLayer(layerName)
        );
        layers.value = [];
      }
      if (sources.value && sources.value.length > 0) {
        sources.value.forEach((sourceName: string) =>
          mapRef.value?.removeSource(sourceName)
        );
        sources.value = [];
      }

      if (!itineraryOrdered.value) {
        return;
      }

      segments?.value?.forEach((segment: Maybe<Segment>, index: number) => {
        const start = {
          x: segment?.departure?.lon,
          y: segment?.departure?.lat,
        };
        const end = { x: segment?.arrival?.lon, y: segment?.arrival?.lat };

        // lines are split at the dateline. If we don't want that, we need to do some work
        const generator = new arc.GreatCircle(start, end);
        const arcPoints = generator.Arc(numberArcPoints, { offset: 10 })
          .geometries;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const coords = arcPoints?.map((ap: any) =>
          ap.coords?.map((pts: [number, number]) => [pts[0], pts[1]])
        );

        const iconCoords: number[] =
          coords.length > 0 && coords[0].length > iconPoint
            ? coords[0][iconPoint]
            : coords[1][iconPoint - coords[0].length];

        const base = Math.max(0, iconPoint - coords[0].length - 1);
        const prevIconCoords: number[] =
          coords.length > 0 && coords[0].length > iconPoint - 1
            ? coords[0][iconPoint - 1]
            : coords[1][base];

        const iconIdStr = `point-${index}`;
        const rotation: number =
          (Math.atan2(
            iconCoords[0] - prevIconCoords[0],
            iconCoords[1] - prevIconCoords[1]
          ) *
            180) /
          Math.PI;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const point: any = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: iconCoords,
              },
            },
          ],
        };

        let possibleOneStopOverCitiesCount = segment?.possibleOneStopOverCities?.length;
        let possibleTwoStopOverCitiesCount = segment?.possibleTwoStopOverCities?.length;
        if ((possibleOneStopOverCitiesCount !== undefined && possibleOneStopOverCitiesCount > 0) || (possibleTwoStopOverCitiesCount != undefined && possibleTwoStopOverCitiesCount > 0)) {

            const el = document.createElement('span');
            el.id = iconIdStr;
            el.style.cursor = 'pointer';
            el.style.color = "white";
            el.style.width = '23px';
            el.style.height = '23px';
            el.style.marginLeft = '-10px';
            const rad = (rotation) * Math.PI / 180;
            const x = 20 * Math.cos(rad);
            const y = 20 * Math.sin(rad);
            el.style.zIndex = `${200 - 2 * Math.floor(iconCoords[1])}`;
            el.style.marginTop = y+'px';
            el.style.marginLeft = x+'px';
            el.style.borderRadius = '50%';
            el.style.backgroundColor = 'white';
            el.style.border = '2px solid #505050';

            let stopOverNumber = "+1";
            if(possibleTwoStopOverCitiesCount !== undefined && possibleTwoStopOverCitiesCount > 0) {
                stopOverNumber = "+2";
            }

            const numberEl = document.createElement('div');
            numberEl.innerHTML = `${stopOverNumber}`;
            numberEl.style.top = `-2px`;
            numberEl.style.color = "#505050";
            numberEl.style.left = '1px';
            numberEl.style.fontWeight = 'bold';
            numberEl.style.position = 'relative';
            numberEl.style.fontSize = '13px';
            el.appendChild(numberEl);

          if (mapRef.value instanceof Map) {
              const labelMarker = new mapboxgl.Marker(el)
              .setLngLat([iconCoords[0], iconCoords[1]])
              .addTo(mapRef.value);
              itineraryCitiesMarkers.value?.push(labelMarker);
          }
            el.addEventListener('mouseover', function (e) {
                if (segment != undefined) {
                    showPossibleStopOver(segment, iconIdStr);
                }
            });
          el.addEventListener('mouseout', function() {
            hidePossibleStopOver();
          });
        }
        mapRef.value?.addSource(iconIdStr, {
          type: 'geojson',
          data: point,
        });
        sources.value?.push(iconIdStr);

        if (segment?.groundSegment == null) {
            const airplaneIcon = createAirplaneIcon();
            airplaneIcon.id = iconIdStr+(segment?.departure?.code)+(segment?.arrival?.code);
            if (mapRef.value instanceof Map) {
                const labelMarker = new mapboxgl.Marker(airplaneIcon)
                    .setLngLat([iconCoords[0], iconCoords[1]])
                    .setRotation(rotation-90)
                    .addTo(mapRef.value);
                itineraryCitiesMarkers.value?.push(labelMarker);
            }
            mapRef.value?.addSource(airplaneIcon.id , {
                type: 'geojson',
                data: point,
            });
            sources.value?.push(airplaneIcon.id);
        }

        // eslint-disable-next-line
        coords.forEach((coord: number[], coordIndex: number) => {
          const idStr = `route-${index}-${coordIndex}`;

          // eslint-disable-next-line
          const route: any = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: coord,
                },
              },
            ],
          };

          mapRef.value?.addSource(idStr, {
            type: 'geojson',
            data: route,
          });
          sources.value?.push(idStr);

          mapRef.value?.addLayer({
            id: idStr,
            source: idStr,
            type: 'line',
            paint: {
              'line-width': 1.5,
              'line-dasharray': [2, 1],
              'line-color': '#6B7280',
            },
          });
          layers.value?.push(idStr);
        });
      });
    }

    watch(currentStopOverSegment, (newCurrentStopOverSegment, oldCurrentStopOverSegment) => {
        const oldName = oldCurrentStopOverSegment?.departure?.code + (oldCurrentStopOverSegment?.groundSegment != null ? '-ground' : '');
        const newName = newCurrentStopOverSegment?.departure?.code + (newCurrentStopOverSegment?.groundSegment != null ? '-ground' : '');

        if (newCurrentStopOverSegment != undefined) {
            if (!isEqual(newName, oldName)) {
                drawPossibleStopOverMarkers(newCurrentStopOverSegment);
            }
        } else {
            hidePossibleStopOver();
        }
    });

    // If just the origin, this still includes a half
    // filled segment
    watch(segments, (newSegments, oldSegments) => {
      const oldNames = oldSegments?.map(
        (s: Maybe<Segment>) =>
          s?.departure?.code + (s?.groundSegment != null ? '-ground' : '')
      );
      const newNames = newSegments?.map(
        (s: Maybe<Segment>) =>
          s?.departure?.code + (s?.groundSegment != null ? '-ground' : '')
      );
      if (!isEqual(newNames, oldNames)) {
        drawFlightArcs();
      }



      const oldStopOver = oldSegments?.map(
          (s: Maybe<Segment>) => {
              let stopOvers = s?.departure?.code + "_" + s?.arrival?.code;
              s?.possibleOneStopOverCities?.forEach(p => stopOvers += "_"+p?.code);
              return stopOvers;
          }
      );
      const newStopOver = newSegments?.map(
          (s: Maybe<Segment>) => {
              let stopOvers = s?.departure?.code + "_" + s?.arrival?.code;
              s?.possibleOneStopOverCities?.forEach(p => stopOvers += "_"+p?.code);
              return stopOvers;
          }
      );
      if (!isEqual(oldStopOver, newStopOver)) {
          drawFlightArcs();
      }
    });

    watch(itineraryOrdered, () => drawFlightArcs());

    watch(messages, newMessages => {
      // @ts-ignore
      const latestMessage:
        | SystemMessage
        | UserMessage
        | undefined = newMessages.slice(-1).pop();
      mapVals.geoJson = undefined;
      if (latestMessage && isSystemMessage(latestMessage)) {
        const matchingContinentNames = Object.keys(continentMap)
          .filter((continentName: string) => {
            return (
              latestMessage.text && latestMessage.text.includes(continentName)
            );
          })
          .filter(continent => {
            if (similarCountries[continent]) {
              return !similarCountries[continent].some(country =>
                latestMessage.text
                  ?.toLowerCase()
                  .includes(country.toLowerCase())
              );
            }
            return true;
          });
        if (matchingContinentNames && matchingContinentNames.length > 0) {
          const matchingContinents:
            | Continent[]
            | undefined = matchingContinentNames
            ?.map(name => {
              const continentName: string = continentMap[name];
              return continents.value.find(
                (c: Continent) => c.name === continentName
              );
            })
            .filter(
              (c: Continent | undefined) => c !== undefined
            ) as Continent[];

          const uniqueContinents = [...new Set(matchingContinents)];
          highlightAndZoomToContinents(uniqueContinents);
        } else {
          clearContinentHighlights();
        }
      }
    });

    // create the observer for resize
    const mapWrapper = ref<Element | null>(null);
    const observer = new ResizeObserver(entries => {
      entries.forEach(() => debouncedResize());
    });
    const debouncedResize = debounce(resize, 100);
    function resize() {
      setTimeout(() => {
        if (mapRef.value != undefined) {
          logger.debug('resize map ...');
          mapRef.value.resize();
        }
      }, 500);
    }

    onMounted(() => {
      // Div resize catch
      if (mapWrapper.value != null) {
        observer.observe(mapWrapper.value);
      }

      // Mapbox setup
      mapboxgl.accessToken = config.MAPBOX_TOKEN;
      mapRef.value = new mapboxgl.Map({
        container: 'mapWrapper', // container ID
        style: 'mapbox://styles/sheplerd/ckzznowk3003214my3w4luxf6', // style URL
        center: mapVals.center, // starting position [lng, lat]
        zoom: mapVals.zoom, // starting zoom
        minZoom: mapVals.minZoom,
        maxZoom: mapVals.maxZoom,
      });
      mapRef.value.dragRotate.disable();

      mapRef.value.on('load', function() {
        mapRef.value?.addSource('customCountryBoundaries', {
          type: 'vector',
          url: 'mapbox://sheplerd.58urkpt2',
        });

        //     // Add a layer with boundary lines
        mapRef.value?.addLayer({
          id: 'custom_country_boundaries',
          type: 'fill',
          source: 'customCountryBoundaries',
          'source-layer': 'customCountryBoundaries-3blqe7',
          paint: {
            'fill-color': '#014737',
            'fill-opacity': 0.4,
          },
        });

        clearContinentHighlights();
        if (segments.value.length > 0) {
          drawFlightArcs();
        }
      });

      // Capture basic user click
      mapRef.value.on('click', () => {
        event('map-click', { method: 'Google' });
      });
    });

    onBeforeUnmount(() => observer.disconnect());

    watch(showItinerary, newShowItineraryValue => {
      if (!newShowItineraryValue) {
        setTimeout(() => mapRef.value?.resize(), 10);
      }
    });

    watch(inspirationCities, () => {
      drawInspirationMarkers();
    });

      const segmentHovering = ref();
      const anchorStopOverPopover = ref();
      const hoveringPossibleStopOverIcon = ref(false);

      function showPossibleStopOver(segment: Segment, targetId: string) {

          let possibleOneStopOverCitiesCount = segment?.possibleOneStopOverCities?.length;
          let possibleTwoStopOverCitiesCount = segment?.possibleTwoStopOverCities?.length;
          if ((possibleOneStopOverCitiesCount !== undefined && possibleOneStopOverCitiesCount > 0) || (possibleTwoStopOverCitiesCount !== undefined && possibleTwoStopOverCitiesCount > 0)) {
              hoveringPossibleStopOverIcon.value = true;
              anchorStopOverPopover.value = targetId;
              segmentHovering.value = segment;
              drawPossibleStopOverMarkers(segment);
          }
      }

      function hidePossibleStopOver() {
          hoveringPossibleStopOverIcon.value = false;
          removePossibleStopOverMarkers();
          removePossibleStopOverLabels();
      }

    return {
      mapWrapper,
      mapRef,
      mapVals,
      showMap,
      origin,
      segments,
      inspirationCities,
      segmentHovering,
      anchorStopOverPopover,
      hoveringPossibleStopOverIcon
    };
  },
});
</script>
