<template>
  <a-tooltip
    placement="right"
    class="display-block h-1/2 flex flex-row items-center dragBlur"
    :class="{
      'text-neutral-500': missingDates,
      'hover:underline': !missingDates,
      'cursor-pointer': !requireDialogResponse && !missingDates,
      'cursor-not-allowed': requireDialogResponse || missingDates,
    }"
    :title="tooltipText"
    :visible="hovered && hasDialogResponse"
    @visibleChange="handleHoverChange"
  >
    <a-popover
      class="z-2000"
      placement="leftBottom"
      :visible="clicked && hasDialogResponse"
      @visibleChange="handleClickChange"
      trigger="click"
    >
      <!-- trigger -->
      <div
        class="w-full flex items-center mt-1 ml-2"
        :class="{
          'text-neutral-500': missingDates,
          'cursor-pointer': !requireDialogResponse && !missingDates,
          'cursor-not-allowed': requireDialogResponse || missingDates,
        }"
      >
        <CityDateInfo
          :index="index"
          :visit="visit"
          :departureDate="departureDate"
          :isGroundDeparture="isGroundDeparture"
          :preferences="preferences"
        />
      </div>

      <!-- content -->
      <template #content>
        <div class="p-4">
          <div class="text-xl mb-6">
            I want to start my trip {{ showRange ? 'between' : 'on' }} ...
          </div>
          <div>
            <!-- arrival date -->
            <div>
              <div class="flex space-between">
                <div class="flex-grow" :class="{ 'mr-6': showRange }">
                  <div class="text-ow-p-dark-blue mb-2 ml-3.5 font-medium">
                    {{ showRange ? 'First date' : 'Trip start date' }}
                  </div>
                  <div
                    v-if="dateRangeVal?.start"
                    class="border border-ec-light-gray rounded-full w-full py-2 px-3 text-ow-p-dark-blue font-light text-sm"
                  >
                    {{
                      showRange
                        ? formatDateStr(dateRangeVal?.start)
                        : formatDateStr(midDateVal)
                    }}
                  </div>
                </div>
                <div v-if="showRange" class="flex-grow">
                  <div class="text-ow-p-dark-blue mb-2 ml-3.5 font-medium">
                    Second date
                  </div>
                  <div
                    v-if="dateRangeVal.end"
                    class="border border-ec-light-gray rounded-full w-full py-2 px-3 text-ow-p-dark-blue font-light text-sm"
                  >
                    {{ formatDateStr(dateRangeVal?.end) }}
                  </div>
                </div>
              </div>
              <DatePicker
                v-if="showRange"
                color="blue"
                class="city"
                v-model="dateRangeVal"
                mode="date"
                :columns="1"
                is-range
                :min-date="minDate"
                :max-date="maxDate"
                :attributes="calendarAttr"
                @update:from-page="updateAvailability"
              />
              <DatePicker
                v-else
                color="blue"
                class="city"
                v-model="midDateVal"
                mode="date"
                :columns="1"
                :min-date="minDate"
                :max-date="maxDate"
                :attributes="calendarAttr"
                @update:from-page="updateAvailability"
              />
            </div>
            <div class="w-full flex justify-between mb-3">
              <div>Pick exact date</div>
              <a-switch :checked="!showRange" @change="updateShowRange" />
            </div>
            <div class="flex flex-row items-center mb-6">
              <div class="rounded-full w-3 h-3 bg-green-500 mr-2"></div>
              <div>Flights scheduled (availability TBD)</div>
            </div>
          </div>
          <div class="flex w-full text-sm justify-end">
            <button class="btn secondary mr-3" @click="hidePopover">
              Cancel
            </button>
            <button class="btn" @click="updateDates">Confirm</button>
          </div>
        </div>
      </template>
    </a-popover>
  </a-tooltip>
</template>

<style lang="scss" />
.vc-container.city { border: none; } .ant-switch-checked { @apply
bg-ow-p-dark-blue; }

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:StartDateOrRangeCalendar');

import {
  defineComponent,
  PropType,
  computed,
  ref,
  toRefs,
  watch,
  onMounted,
} from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { format } from 'date-fns';
import {
  City,
  Concept,
  dateToLocalDate,
  Formula,
  localDateToDate,
  Segment,
  unMaybe,
  unMaybeArray,
} from '@/api/service';

import CityDateInfo from './CityDateInfo.vue';
import { event } from 'vue-gtag';
import { uniq } from 'lodash-es';
import { Page } from '@/components/common/calendars/Calendar';
import {
  createDate,
  setStartDate,
  setStartDateRange,
} from '@/api/user-actions';
import { dateFormat, dateStr } from '@/format';

type DateRange = {
  start: Date;
  end: Date;
};

export default defineComponent({
  components: {
    CityDateInfo,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    city: {
      type: Object as PropType<City>,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    departureSegment: {
      type: Object as PropType<Segment>,
      required: false,
    },
    departureDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    dateRange: {
      type: Object as PropType<DateRange>,
      required: false,
    },
    preferences: {
      type: Object as PropType<Formula[]>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { dateRange, departureSegment, departureDate } = toRefs(props);

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const hadDates = computed(() => store.state.hadDates);
    const isGroundDeparture = computed(
      () => departureSegment.value?.groundSegment != null
    );
    const missingDates = computed(() => !store.getters.haveDates);

    const tooltipText = computed(() => {
      if (!hadDates.value && missingDates.value) {
        return 'You will be able to configure dates shortly';
      } else if (missingDates.value) {
        return 'Dates are being re-calculated';
      } else {
        return 'Click to edit dates';
      }
    });

    function formatDateStr(localDate: Date | null | undefined) {
      if (localDate != null) {
        return format(localDate, dateFormat.full);
      } else {
        return '-';
      }
    }

    const clicked = ref<boolean>(false);
    const hovered = ref<boolean>(false);

    function hidePopover() {
      const clickedBefore = clicked.value;
      clicked.value = false;
      hovered.value = false;
      eventShowHide(clickedBefore);
    }

    function handleHoverChange(visible: boolean) {
      const clickedBefore = clicked.value;
      clicked.value = false;
      hovered.value = visible;
      eventShowHide(clickedBefore);
    }

    function handleClickChange(visible: boolean) {
      if (hasDialogResponse.value) {
        const clickedBefore = clicked.value;
        if (!requireDialogResponse.value && !missingDates.value) {
          clicked.value = visible;
          hovered.value = false;
        } else {
          clicked.value = false;
          hovered.value = false;
        }
        eventShowHide(clickedBefore);
      } else {
        sendProcessingWarning();
      }
    }

    function eventShowHide(clickedBefore: boolean) {
      if (!clickedBefore && clicked.value) {
        event('start-date-or-range-calendar-show', { method: 'Google' });
      } else if (clickedBefore && !clicked.value) {
        event('start-date-or-range-calendar-hide', { method: 'Google' });
      }
    }

    const showRange = ref(props.dateRange?.start !== props.dateRange?.end);
    function updateShowRange(newVal: boolean) {
      showRange.value = !newVal;
    }
    watch(dateRange, (newVal, oldVal) => {
      if (newVal !== undefined && newVal !== oldVal) {
        if (newVal.start) {
          dateRangeVal.value.start = newVal.start;
        }
        if (newVal.end) {
          dateRangeVal.value.end = newVal.end;
        }
        if (
          new Date(newVal.start).getTime() !== new Date(newVal.end).getTime()
        ) {
          showRange.value = true;
        } else {
          showRange.value = false;
        }
      }
    });

    const dateRangeVal = ref({
      start: dateRange.value?.start ? dateRange.value.start : undefined,
      end: dateRange.value?.end ? dateRange.value.end : undefined,
    });

    const midDateVal = ref();
    watch(departureDate, (newVal, oldVal) => {
      if (newVal !== undefined && newVal !== oldVal) {
        midDateVal.value = newVal;
      }
    });

    const isNewStartDate = computed(
      () => midDateVal.value !== departureDate.value
    );

    const isNewDateRange = computed(() => {
      return (
        dateRangeVal.value.start !== dateRange.value?.start ||
        dateRangeVal.value.end !== dateRange.value?.end
      );
    });

    const availableDates = ref<Array<Date>>([]);

    async function updateAvailability(page: Page) {
      if (
        departureSegment.value !== undefined &&
        departureSegment.value.departure != null &&
        departureSegment.value.arrival != null
      ) {
        const request = {
          departureCityCode: departureSegment.value.departure.code,
          arrivalCityCode: departureSegment.value.arrival.code,
          // WARNING: page month comes in + 1, even when accounting for 1-indexing :/
          startDate: dateToLocalDate(new Date(page.year, page.month - 1, 1)),
          endDate: dateToLocalDate(new Date(page.year, page.month, 0)),
        };
        logger.debug('Arrival availability: ' + JSON.stringify(request));
        const scheduleResult = await store.dispatch(
          ActionTypes.GetSchedule,
          request
        );
        const schedule = unMaybeArray(scheduleResult.scheduled);

        // Add all dates that have availability
        schedule
          .filter(s => s.direct > 0 || s.indirect > 0)
          .map(s => localDateToDate(unMaybe(s.date)))
          .filter((d): d is Date => d !== undefined)
          // ensure we don't have options today or earlier
          .filter(d => d > new Date())
          .forEach(d => availableDates.value.push(d));

        // Uniquify
        availableDates.value = uniq(availableDates.value);
      }
    }

    const calendarAttr = computed(() => [
      {
        dot: 'green',
        dates: availableDates.value,
      },
    ]);

    function sendStartRangeUpdate() {
      const action = setStartDateRange(
        createDate(dateRangeVal.value?.start),
        createDate(dateRangeVal.value?.end)
      );
      if (action !== undefined) {
        store.dispatch(ActionTypes.SendActions, [action]);
      }
    }

    function sendStartDateUpdate() {
      const action = setStartDate(midDateVal.value);
      if (action !== undefined) {
        store.dispatch(ActionTypes.SendActions, [action]);
      }
    }

    function updateDates() {
      if (showRange.value) {
        if (isNewDateRange.value) {
          sendStartRangeUpdate();
        } else {
          logger.debug(
            'Dates not updated: ',
            dateRangeVal.value.start,
            dateRangeVal.value.end
          );
        }
      } else {
        if (isNewStartDate.value) {
          sendStartDateUpdate();
        } else {
          logger.debug('StartDate not updated: ', dateRangeVal.value.start);
        }
      }
      hidePopover();
    }

    function sendProcessingWarning() {
      store.dispatch(ActionTypes.SendProcessingWarning);
    }

    const minDate = computed(() => {
      const today = new Date();
      return dateStr(today, dateFormat.full);
    });
    const maxDate = computed(() => {
      const oneYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      return dateStr(oneYear, dateFormat.full);
    });

    onMounted(() => {
      if (dateRange.value?.start !== undefined) {
        dateRangeVal.value.start = dateRange.value?.start;
      }
      if (dateRange.value?.end !== undefined) {
        dateRangeVal.value.end = dateRange.value?.end;
      }
      if (departureDate.value !== undefined) {
        midDateVal.value = departureDate.value;
      }
      if (
        dateRange.value?.start !== undefined &&
        dateRange.value?.end !== undefined &&
        new Date(dateRange.value?.start).getTime() ===
          new Date(dateRange.value.end).getTime()
      ) {
        showRange.value = false;
      }
    });

    const hasDialogResponse = computed(
      () =>
        store.getters.requireDialogResponse ||
        store.getters.requireMultiChoiceResponse ||
        store.getters.haveActiveRequestSystemMessage
    );

    return {
      clicked,
      hovered,
      hidePopover,
      handleHoverChange,
      handleClickChange,
      tooltipText,
      hadDates,
      missingDates,
      isGroundDeparture,
      formatDateStr,
      requireDialogResponse,
      updateDates,
      showRange,
      updateShowRange,
      calendarAttr,
      updateAvailability,
      availableDates,
      midDateVal,
      dateRangeVal,
      minDate,
      maxDate,
      hasDialogResponse,
    };
  },
});
</script>
