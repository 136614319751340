<template>
  <div
    class="flex flex-row flex-shrink-0 max-w-9/10 relative"
    v-show="showStatus"
  >
    <div class="absolute -left-3.5 bottom-0">
      <Avatar />
    </div>
    <div class="flex-grow-0 ml-4 flex flex-col">
      <div
        class="bg-cool-gray-100 rounded-3xl rounded-bl-md flex-grow-0 pl-4 pr-5 py-3 leading-6 mt-3"
      >
        <div class="flex flex-row">
          <div class="flex flex-row h-6 place-self-end">
            <span class="bg-gray-400"></span>
            <span class="bg-gray-300"></span>
            <span class="bg-gray-400"></span>
          </div>
          <div
            class="ml-3 text-neutral-800"
            v-show="message.length > 0"
            v-html="message"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
span {
  @apply m-0.5 w-1.5 h-1.5 inline-block rounded-full;

  &:nth-child(1) {
    animation: bounce 1s infinite;
  }
  &:nth-child(2) {
    animation: bounce 1s infinite 0.2s;
  }
  &:nth-child(3) {
    animation: bounce 1s infinite 0.4s;
  }
  // &:nth-child(4) {
  //   animation: bounce 1s infinite 0.8s;
  // }
}

@keyframes bounce {
  0% {
    transform: translateY(7px);
  }
  40% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(7px);
  }
  100% {
    transform: translateY(7px);
  }
}
</style>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useStore } from '@/store';
import { DialogStatusType } from '@/api/service';
import Avatar from '@/components/common/dialog/Avatar.vue';
import { debounce } from 'lodash-es';

export default defineComponent({
  components: { Avatar },
  emits: ['attention'],
  setup(_, { emit }) {
    const store = useStore();

    const showStatus = ref(false);
    const status = computed(() => store.state.dialog?.status);

    const haveStatus = computed(
      () => status.value?.type !== DialogStatusType.StatusTypeIdle
    );

    const hasMessage = computed(
      () => status.value?.message != null && status.value?.message?.length > 0
    );

    const message = computed(() =>
      status.value?.message != null && status.value?.message?.length > 0
        ? status.value?.message
        : ''
    );

    function update() {
      // If this update is making the status show, then emit
      if (!showStatus.value && haveStatus.value) {
        emit('attention');
      }
      showStatus.value = haveStatus.value;
    }

    const debouncedUpdate = debounce(update, 1500, { maxWait: 1500 });

    watch(haveStatus, () => {
      if (haveStatus.value) {
        debouncedUpdate();
      } else {
        update();
      }
    });

    onMounted(update);

    return {
      status,
      showStatus,
      hasMessage,
      message,
    };
  },
});
</script>
