<template>
  <div class="City ticket flex items-center p-2">
    <!-- Gripper -->
    <div class="flex flex-col items-center text-gray-500 w-4">
      <!-- Move -->
      <a-tooltip :title="dragNDropTooltip" :destroyTooltipOnHide="true">
        <font-awesome-icon
          class="fill-current"
          :class="{
            'cursor-move': !requireDialogResponse,
            'cursor-not-allowed': requireDialogResponse,
            'opacity-40': requireDialogResponse,
          }"
          :icon="['far', 'grip-lines']"
          v-if="draggable"
        />
      </a-tooltip>
    </div>

    <!-- Data -->
    <div class="h-full relative flex-grow items-center ml-2 mr-2">
      <!-- Center line  -->
      <div class="absolute top-1/2 w-full border-t border-gray-300" />

      <!-- City -->
      <div class="h-1/2 flex flex-row items-center">
        <div class="w-6 flex justify-center items-center">
          <!-- Origin pin -->
          <OriginFinalPin v-if="isOriginVisit" />
          <!-- Interim Pin -->
          <div v-else-if="!isFinalVisit">
            <StatusTooltip
              v-if="showStatus"
              :statusMessage="statusMessage"
              :status="status"
            >
              <template v-slot:anchor>
                <font-awesome-layers>
                  <font-awesome-icon
                    class="text-pin"
                    :icon="['fas', 'location-pin']"
                    :class="pinColor"
                  />
                  <div v-if="itineraryOrdered" class="pin-text">
                    {{ index }}
                  </div>
                </font-awesome-layers>
              </template>
            </StatusTooltip>
            <font-awesome-layers v-else>
              <font-awesome-icon
                class="text-pin"
                :icon="['fas', 'location-pin']"
                :class="pinColor"
              />
              <div v-if="itineraryOrdered" class="pin-text">
                {{ index }}
              </div>
            </font-awesome-layers>
          </div>

          <!-- Final pin -->
          <OriginFinalPin v-else />
        </div>

        <!-- City name -->
        <div class="ml-3 flex-grow overflow-hidden">
          <div class="flex justify-between">
            <div
              class="items-center text-gray-700 whitespace-nowrap overflow-ellipsis overflow-hidden"
            >
              {{ city.name }}, {{ city.countryCode }}
            </div>
            <!-- Delete -->
            <font-awesome-icon
              class="ml-1 text-lg text-gray-500 cursor-pointer hover:bg-gray-500 hover:bg-opacity-10"
              v-if="!isOriginVisit && !isFinalVisit && !requireDialogResponse"
              @click="remove()"
              :icon="['fal', 'trash-can']"
            />
          </div>
        </div>
      </div>

      <!-- calendar icon & popover, tooltip trigger -->
      <div class="h-1/2 w-full flex justify-center items-center CalendarIcon">
        <slot name="calendar" />
      </div>

      <!-- preferences -->
      <!-- TODO: future work to display preference for users -->
      <!--      <div class="w-full flex flex-row">-->
      <!--        <div v-for="(pill, p) in preferencePills" :key="p">-->
      <!--          {{ pill.text }}-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pin-text {
  @apply text-white text-pin-text;
  position: absolute;
  top: -3px;
  width: 18px;
}

.select-date-modal {
  height: 350px;
  width: 510px;
}

span.loader {
  @apply m-0.5 w-1.5 h-1.5 inline-block rounded-full;

  &:nth-child(1) {
    animation: bounce 1s infinite;
  }
  &:nth-child(2) {
    animation: bounce 1s infinite 0.2s;
  }
  &:nth-child(3) {
    animation: bounce 1s infinite 0.4s;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(4px);
  }
  40% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(4px);
  }
}
</style>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { City, Concept, Formula, Status } from '@/api/service';

import StatusTooltip from '@/components/common/misc/StatusTooltip.vue';

import OriginFinalPin from './OriginFinalPin.vue';
import {
  conceptSetBuilder,
  formulaBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

// interface preferencePill {
//   text: string;
//   preference: Formula;
// }

export default defineComponent({
  components: {
    StatusTooltip,
    OriginFinalPin,
  },
  props: {
    // index in the visual list
    index: {
      type: Number,
      required: true,
    },
    city: {
      type: Object as PropType<City>,
      required: true,
    },
    // index in the itinerary - can be -1 if not in a sequence
    sequence: {
      type: Number,
      required: false,
    },
    status: {
      type: String as PropType<Status>,
      required: false,
    },
    statusMessage: {
      type: String,
      required: false,
    },
    draggable: {
      type: Boolean,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    preferences: {
      type: Object as PropType<Formula[]>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const dragNDropEnabled = ref(false);

    const isOriginVisit = computed(() =>
      store.getters.isOriginVisit(props.visit)
    );
    const isFinalVisit = computed(() =>
      store.getters.isFinalVisit(props.visit)
    );
    const interim = computed(() =>
      store.getters.interimForSegmentIndex(props.index)
    );
    const showWarningStatus = computed(
      () => interim?.value?.status === Status.StatusWarning
    );
    const showErrorStatus = computed(
      () => interim?.value?.status === Status.StatusError
    );
    const showStatus = computed(() => props.status !== Status.StatusOk);
    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const disabledSend = computed(() => store.getters.disabledSend);
    const itineraryOrdered = computed(() => store.getters.itineraryOrdered);
    const isPhaseCitiesBatching = computed(
      () => store.getters.isPhaseCitiesBatching
    );

    const dragNDropTooltip = computed(() => {
      if (requireDialogResponse.value) {
        return 'Drag-to-order is currently disabled. Please respond in the chat first.';
      } else if (disabledSend.value) {
        return 'Drag-to-order is currently disabled. Waiting on system.';
      } else if (isPhaseCitiesBatching.value) {
        return 'Drag-to-order is currently disabled. Waiting on first set of ordered cities.';
      } else {
        return 'Drag to re-order. Ticketing rules will be checked once you drop.';
      }
    });

    const pinColor = computed(() => {
      // default to unordered color
      let colorVal = 'gray-400';
      if (showWarningStatus.value) {
        colorVal = 'ow-warning';
      } else if (showErrorStatus.value) {
        colorVal = 'ow-error';
      } else if (itineraryOrdered.value) {
        colorVal = 'ow-p-dark-blue';
      }
      return `text-${colorVal}`;
    });

    // const preferencePills = computed<preferencePill[]>(() => {
    //   return unMaybeArray(props.preferences).map(p => ({
    //     text: p.predicate,
    //     preference: p,
    //   }));
    // });

    function remove() {
      const action = sendActionBuilder(
        `Drop ${props.city.name}`,
        formulaBuilder('rtwAction/dropFromItinerary', [
          propertyValueBuilder('cities', conceptSetBuilder([props.visit])),
        ])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    return {
      isOriginVisit,
      isFinalVisit,
      itineraryOrdered,
      requireDialogResponse,
      remove,
      pinColor,
      showStatus,
      dragNDropEnabled,
      dragNDropTooltip,
    };
  },
});
</script>
