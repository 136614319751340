<template>
  <div class="GroundSegment flex flex-row items-center">
    <!-- Left ticket slot -->
    <div
      class="h-full pl-6 w-10 mr-4 flex-none flex border-r-2 border-dotted items-center justify-center"
      @mouseenter="showGroundSegmentOption = true"
      @mouseleave="showGroundSegmentOption = false"
    >
      <a-tooltip
        class="flex flex-col cursor-pointer"
        @click="removeGroundSegment"
      >
        <template #title>
          Click to set {{ departureCity.name }} to {{ arrivalCity.name }} as
          flight segment
        </template>
        <font-awesome-icon
          class="text-ow-s-sapphire"
          :icon="['fas', 'car-side']"
        />
        <font-awesome-icon
          v-if="showGroundSegmentOption"
          class="text-ow-s-sapphire"
          :icon="['fal', 'arrow-down']"
        />
        <font-awesome-icon
          v-if="showGroundSegmentOption"
          class="text-ow-s-sapphire"
          :icon="['fas', 'plane']"
        />
      </a-tooltip>
    </div>

    <!-- Middle ticket slot -->
    <div class="flex-grow h-full px-4 py-2 flex flex-row">
      <div class="flex-grow flex items-center">
        <div class="pr-2 flex flex-col">
          <div class="mb-0.5">
            {{ departureCity.name }}
          </div>
        </div>
        <div class="flex-grow flex flex-col">
          <div class="flex-grow -mb-1 text-center">
            <div>
              Alternative travel
            </div>
          </div>
          <FlightLine />
        </div>
        <div class="px-2 flex flex-col text-right">
          <div class="mb-0.5">
            {{ arrivalCity.name }}
          </div>
        </div>
      </div>
    </div>

    <!-- Right ticket slot -->
    <div
      class="h-full w-10 flex-none border-l-2 border-dotted flex justify-center items-center"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { Segment, unMaybe, Status, unMaybeArray } from '@/api/service';
import { useStore } from '@/store';
import FlightLine from './FlightLine.vue';
import { getUserRequestsAlternateTravel } from '@/api/kr';
import { removePreference } from '@/api/user-actions';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  props: {
    segment: {
      type: Object as PropType<Segment>,
      required: true,
    },
    previousSegment: {
      type: Object as PropType<Segment>,
      required: false,
    },
  },
  components: {
    FlightLine,
  },
  setup(props) {
    const store = useStore();

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    const status = computed(() => props.segment.status);
    const statusMessage = computed(() => props.segment.statusMessage);
    const showWarningStatus = computed(
      () => props.segment.status === Status.StatusWarning
    );
    const showErrorStatus = computed(
      () => props.segment.status === Status.StatusError
    );
    const showStatus = computed(
      () => showWarningStatus.value || showErrorStatus.value
    );
    const departureCity = computed(() => unMaybe(props.segment.departure));
    const arrivalCity = computed(() => unMaybe(props.segment.arrival));

    const showGroundSegmentOption = ref(false);
    const groundSegmentRequest = computed(() =>
      getUserRequestsAlternateTravel(unMaybeArray(props.segment.preferences))
    );
    async function removeGroundSegment() {
      const departure = props.segment.departure?.name;
      const departureVisit = props.segment.departureVisit;
      const arrival = props.segment.arrival?.name;
      const arrivalVisit = props.segment.arrivalVisit;
      if (
        groundSegmentRequest.value?._formula &&
        departure &&
        departureVisit &&
        arrival &&
        arrivalVisit
      ) {
        const action = removePreference(
          'Remove alternative travel between ' + departure + ' and ' + arrival,
          groundSegmentRequest.value?._formula
        );
        if (action != undefined) {
          await store.dispatch(ActionTypes.SendActions, [action]);
        }
      }
    }

    return {
      status,
      statusMessage,
      showStatus,
      showWarningStatus,
      showErrorStatus,
      origin,
      departureCity,
      arrivalCity,
      requireDialogResponse,
      showGroundSegmentOption,
      removeGroundSegment,
    };
  },
});
</script>
