<template>
  <div class="OwBar h-1 w-full flex flex-row">
    <div class="h-full flex-grow bg-ow-p-pink"></div>
    <div class="h-full flex-grow bg-ow-p-yellow"></div>
    <div class="h-full flex-grow bg-ow-p-green"></div>
    <div class="h-full flex-grow bg-ow-p-light-blue"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
