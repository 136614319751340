<template>
  <div class="Home w-full h-full flex flex-col overflow-hidden">
    <Header class="flex-none h-16 pl-32 pr-4" v-if="!isAmtHit" />
    <Preferences
      class="flex-none h-22 pr-4"
      :class="{
        'pl-32': !isAmtHit,
      }"
    />
    <Inspire v-show="showInspiration" />
    <!-- Using row-reverse here so that things are aligned when itinerary is not present  -->
    <div class="flex-grow relative overflow-hidden">
      <!-- Map -->
      <Map class="absolute h-full w-full top-0 left-0" />

      <div
        class="h-full w-full flex flex-row-reverse justify-between overflow-x-auto"
      >
        <!-- Dialog -->
        <div class="z-400 h-full flex-none overflow-hidden p-6 pl-0">
          <Dialog
            class="w-dialog h-full flex-grow shadow-sm border border-gray-400 rounded-2xl"
          />
        </div>

        <!-- Itinerary width controlled based on internal collapsed logic -->
        <div class="z-400 h-full flex-none p-6">
          <Itinerary v-show="haveCities" />
        </div>
      </div>
    </div>
    <Footer class="flex-none h-16" v-if="!isAmtHit" />
    <Onboarding />
    <Tooltips />
    <ItineraryListModal />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue';
import { useStore } from '@/store';

import Header from './Header.vue';
import Preferences from './preferences/Preferences.vue';
import Inspire from './Inspire.vue';
import Itinerary from './Itinerary.vue';
import Footer from './Footer.vue';

import Map from '@/components/common/map/Map.vue';
import Dialog from '@/components/common/dialog/Dialog.vue';

import Onboarding from '@/components/common/onboarding/Onboarding.vue';
import Tooltips from '@/components/common/tooltips/index.vue';
import ItineraryListModal from '@/components/desktop/ItineraryListModal.vue';

import { message } from 'ant-design-vue';

export default defineComponent({
  components: {
    Header,
    Preferences,
    Inspire,
    Map,
    Itinerary,
    Dialog,
    Footer,
    Onboarding,
    Tooltips,
    ItineraryListModal,
  },
  setup() {
    const store = useStore();

    const isAmtHit = computed(() => store.getters.isAmtHit);
    const haveCities = computed(() => store.getters.haveCities);
    const segments = computed(() => store.getters.segments);
    const showInspiration = computed(() => store.state.showInspiration);

    // Show warning
    watch(
      () => store.state.warnings,
      (warningsNew, warningsOld) => {
        warningsNew.slice(warningsOld.length).forEach(m => message.warn(m));
      },
      { deep: true }
    );

    // Show errors
    watch(
      () => store.state.errors,
      (errorsNew, errorsOld) => {
        errorsNew.slice(errorsOld.length).forEach(m => message.error(m));
      },
      { deep: true }
    );

    return {
      isAmtHit,
      showInspiration,
      haveCities,
      segments,
    };
  },
});
</script>
