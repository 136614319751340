<template>
  <a-modal
    class="modal-basic min-h-72"
    :visible="block"
    :maskClosable="false"
    :centered="true"
    :closable="false"
    :footer="null"
    :zIndex="20000"
  >
    <h1>Screen size not supported 😒</h1>
    <p>
      You are accessing from a screen smaller than the size supported in this
      version.
    </p>
    <p>
      <strong>
        For a complete experience, please access from a desktop browser.
      </strong>
    </p>
    <div class="flex flex-row">
      <div class="btn secondary" @click="goToZen3">
        Use table-based itinerary builder
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import isMobile from 'is-mobile';
import config from 'config';

export default defineComponent({
  setup() {
    const block = computed(() => isMobile({ tablet: true }));

    function goToZen3() {
      window.location.href = config.ZEN3_URL;
    }

    return {
      block,
      goToZen3,
    };
  },
});
</script>
