<template>
  <div class="UserMessage flex flex-col mt-4 mb-2 justify-end flex-shrink-0">
    <div class="flex justify-end">
      <div
        class="max-w-4/5 flex-grow-0 align-self-right px-4 py-3 bg-ow-p-dark-blue leading-5 text-white rounded-3xl rounded-br-md break-words"
        v-html="text"
      />
    </div>
    <div class="flex justify-end mt-0.5">
      <div
        class="mr-2 text-sm text-gray-400 italic"
        v-if="parsingSummary"
        v-html="parsingSummary"
      />
      <div
        v-show="showUndo"
        class="hover:cursor-pointer hover:text-blue-600 rounded-xl text-sm"
        @click="undoLatest"
      >
        Undo
        <font-awesome-icon :icon="['fas', 'undo-alt']" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
const md = new MarkdownIt().use(MarkDownItEmoji);

import { UserAction, UserMessage, UserTextChoice } from '@/api/service';
import { formulaBuilder, sendActionBuilder } from '@/api/kr';

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<UserMessage>,
      required: true,
    },
    isLastUserMessage: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const alreadyUndone = ref(false);

    const text = computed(() => {
      if (props.message.textInput != null) {
        if (
          props.message.textInput.processed != null &&
          props.message.textInput.processed.processedText != null
        ) {
          return md.renderInline(
            props.message.textInput.processed.processedText
          );
        } else {
          return props.message.textInput.text
            ? md.renderInline(decodeURIComponent(props.message.textInput.text))
            : undefined;
        }
      } else if (
        props.message.textChoices != null &&
        props.message.textChoices.textChoices != null
      ) {
        return props.message.textChoices.textChoices
          .filter((t): t is UserTextChoice => t != null)
          .map(t => t.displayText)
          .filter((t): t is string => t != null)
          .map(t => md.renderInline(t))
          .join(', ');
      } else if (
        props.message.actions != null &&
        props.message.actions.actions != null
      ) {
        return props.message.actions.actions
          .filter((a): a is UserAction => a != null)
          .map(a => a.text)
          .filter((t): t is string => t != null)
          .map(t => md.renderInline(t))
          .join(', ');
      } else {
        return undefined;
      }
    });

    const parsingSummary = computed(() =>
      props.message.textInput != null &&
      props.message.textInput.processed != null &&
      props.message.textInput.processed.parsingSummary != null
        ? md.render(props.message.textInput.processed.parsingSummary)
        : undefined
    );

    const intent = computed(() =>
      props.message.metadata != null
        ? props.message.metadata.userIntent
        : undefined
    );
    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const haveActiveRequestSystemMessage = computed(
      () => store.getters.haveActiveRequestSystemMessage
    );
    const showUndo = computed(
      () =>
        !requireDialogResponse.value &&
        haveActiveRequestSystemMessage.value &&
        props.isLastUserMessage &&
        props.message.metadata?.impliesMutation &&
        !alreadyUndone.value
    );

    function undoLatest() {
      store.dispatch(ActionTypes.SendActions, [
        sendActionBuilder('undo', formulaBuilder('rtwAction/undo', [])),
      ]);
      alreadyUndone.value = true;
    }

    return {
      text,
      parsingSummary,
      intent,
      showUndo,
      undoLatest,
      alreadyUndone,
    };
  },
});
</script>
