<template>
  <span
    class="StatusTooltip"
    v-tooltip="{
      content: renderedText,
      html: true,
      theme: theme,
    }"
  >
    <slot name="anchor" />
  </span>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
const md = new MarkdownIt().use(MarkDownItEmoji);

import { enumValues, Status } from '@/api/service';

export default defineComponent({
  props: {
    status: {
      type: String,
      enum: enumValues(Status),
      required: true,
    },
    statusMessage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const theme = computed(() => {
      if (props.status === Status.StatusError) {
        return 'ErrorTooltip';
      } else if (props.status === Status.StatusWarning) {
        return 'WarningTooltip';
      }
      return 'tooltip';
    });

    const renderedText = computed(() => {
      const m = props.statusMessage;
      return m != null ? md.render(decodeURIComponent(m)) : undefined;
    });

    return {
      theme,
      renderedText,
    };
  },
});
</script>
