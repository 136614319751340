<template>
  <div class="VirtualAgent text-ow-p-dark-blue">
    <div class="onboarding-header">
      <div class="title">
        <div>
          Next Generation Support Tool
        </div>
      </div>
      <div class="subtitle">
        Our collaborative travel agent can understand natural language and will
        guide you on your journey.
      </div>
    </div>
    <div
      class="grid grid-cols-1 pb-4 px-4 md:grid-cols-2 gap-x-2 md:my-4 md:mx-0 md:pt-4"
    >
      <div class="my-2 md:mx-4 md:my-0">
        <div
          v-for="x in content.length"
          :key="x"
          class="flex py-4 px-4 max-w-96"
        >
          <font-awesome-icon
            class="mt-1 mr-4 text-ow-s-emerald text-xl"
            :icon="icons[x - 1]"
          />
          <div class="text-left">{{ content[x - 1] }}</div>
        </div>
      </div>
      <div class="relative mb-8 hidden md:block">
        <div class="bg-ow-p-yellow w-96 h-64" />
        <div
          class="hidden md:block mx-auto border-2 border-ow-p-yellow p-4 bg-white absolute top-5 left-5 w-96"
        >
          <img
            :src="require(`@/assets/onboarding/chat.webp`)"
            alt="chat agent example"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

const icons = [
  ['fa', 'badge-check'],
  ['fa', 'circle-info'],
  ['fa', 'bullseye-arrow'],
];

const content = [
  'Will help you build a valid itinerary and stay compliant with the fare rules.',
  'Will inform you when there are no connecting flights between cities in your itinerary.',
  'Will adapt to your needs, suggest useful tips, and make it easy to accomplish your goals.',
];

export default defineComponent({
  setup() {
    const show = ref(true);

    return {
      show,
      icons,
      content,
    };
  },
});
</script>
