<template>
  <span class="px-1 py-0.5 text-xs border rounded" :class="classObj">
    {{ name }}
  </span>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const classObj = computed(() => {
      const obj: { [key: string]: boolean } = {};
      obj['text-' + props.color] = true;
      obj['border-' + props.color] = true;
      return obj;
    });

    return {
      classObj,
    };
  },
});
</script>
