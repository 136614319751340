<template>
  <FlightSegment
    v-if="isFlightSegment"
    :segment="segment"
    :previousSegment="previousSegment"
    :showDetails="showDetails"
    @toggleShowDetails="$emit('toggleShowDetails')"
  />
  <GroundSegment v-else :segment="segment" :previousSegment="previousSegment" />
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { Segment as SegmentType } from '@/api/service';
import FlightSegment from './FlightSegment.vue';
import GroundSegment from './GroundSegment.vue';

export default defineComponent({
  props: {
    segment: {
      type: Object as PropType<SegmentType>,
      required: true,
    },
    previousSegment: {
      type: Object as PropType<SegmentType>,
      required: false,
    },
    showDetails: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    GroundSegment,
    FlightSegment,
  },
  emits: ['toggleShowDetails'],
  setup(props) {
    // Default, when both not set, is a flight
    const isFlightSegment = computed(
      () =>
        props.segment.flightSegment != null ||
        props.segment.groundSegment === null
    );

    return {
      isFlightSegment,
    };
  },
});
</script>
