<template>
  <div
    class="flex flex-col pr-3"
    v-if="showStatusCard"
  >
    <div
      class="mb-6 p-2 rounded-xl shadow-lg bg-white flex flex-row items-center"
    >
      <div class="text-ow-warning">
        <span class="ml-2 mr-4">
          <font-awesome-icon
            :icon="['fa', 'triangle-exclamation']"
            color="ow-s-warning"
          />
        </span>
      </div>
      <div class="ml-1 text-center items-center" v-if="showCityOrderWarning">
        {{ 'City order is not set.' }}
        <span
          v-if="hasDialogResponse"
          class="banner-action"
          @click="orderCities"
        >
          Find valid city order
        </span>
      </div>
      <div class="ml-1 text-center items-center" v-if="showVisitDatesWarning">
        {{ 'New dates need validation.' }}
        <span
          v-if="hasDialogResponse"
          class="banner-action"
          @click="validateDates"
        >
          Validate dates
        </span>
      </div>
    </div>
  </div>
  <div
    class="flex flex-row justify-between items-center border-b border-b-neutral-300 pb-2 mb-2"
  >
    <div class="text-lg text-ow-p-dark-blue">Your itinerary</div>
  </div>
</template>

<style lang="scss" scoped>
.banner-action {
  @apply text-ow-p-dark-blue underline
  cursor-pointer;
}
</style>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { formulaBuilder, sendActionBuilder } from '@/api/kr';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  setup() {
    const store = useStore();

    const showStatusCard = computed(
      () => showCityOrderWarning.value || showVisitDatesWarning.value
    );

    const showCityOrderWarning = computed(
      () => store.getters.isPhaseCitiesBatching
    );

    const showSetDatesWarning = computed(
      () => store.getters.isPhaseCitiesOrderValid
    );

    const showVisitDatesWarning = computed(
      () => store.getters.isPhaseDatesBatching
    );

    const hasDialogResponse = computed(
      () =>
        store.getters.requireDialogResponse ||
        store.getters.requireMultiChoiceResponse ||
        store.getters.haveActiveRequestSystemMessage
    );

    function validateDates() {
      const action = sendActionBuilder(
        'Validate dates',
        formulaBuilder('rtwAction/batchingComplete', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    function orderCities() {
      const action = sendActionBuilder(
        'Order cities',
        formulaBuilder('rtwAction/orderCities', [])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    return {
      showStatusCard,
      orderCities,
      validateDates,
      showCityOrderWarning,
      showSetDatesWarning,
      showVisitDatesWarning,
      hasDialogResponse,
    };
  },
});
</script>
