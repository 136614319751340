<template>
  <div class="Desktop w-screen h-screen">
    <Home class="w-full h-full" />
    <DeviceBlock />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { MutationTypes } from '@/store/mutations';

import Home from './Home.vue';
import DeviceBlock from '@/components/desktop/DeviceBlock.vue';

export default defineComponent({
  components: {
    Home,
    DeviceBlock,
  },
  setup() {
    const store = useStore();

    // TODO: use router
    const queryParams = computed(() => {
      const queryStr = window.location.search.substring(1);
      const paramPairs = queryStr.split('&');
      const queryMap: { [key: string]: string } = {};
      paramPairs.forEach((pair: string) => {
        const pairArr: string[] = pair.split('=');
        queryMap[pairArr[0]] = pairArr[1];
      });

      return queryMap;
    });

    const taskId = computed(() => store.state.taskId);

    // to test: ?workerId=1&assignmentId=1&taskId=1&hitId=1&turkSubmitTo=bla
    const isExperiment = computed(
      () =>
        queryParams.value.workerId !== undefined &&
        queryParams.value.assignmentId !== undefined &&
        queryParams.value.taskId !== undefined &&
        queryParams.value.hitId !== undefined &&
        queryParams.value.turkSubmitTo !== undefined
    );

    const showExperimentSidebar = computed(
      () =>
        queryParams.value.taskId !== undefined &&
        !isNaN(Number(queryParams.value.taskId))
    );

    async function assignTask() {
      await store.dispatch(ActionTypes.AssignTask);
    }

    async function assignAmtParams() {
      if (isExperiment.value && Object.keys(queryParams.value).length > 0) {
        await store.dispatch(ActionTypes.SetAmtParams, {
          workerId: queryParams.value.workerId,
          assignmentId: queryParams.value.assignmentId,
          taskId: queryParams.value.taskId,
          hitId: queryParams.value.hitId,
          turkSubmitTo: queryParams.value.turkSubmitTo
            ? decodeURIComponent(queryParams.value.turkSubmitTo)
            : '',
        });
      }
    }

    onMounted(async () => {
      // Catch debug
      if (queryParams.value.debug != null) {
        store.commit(
          MutationTypes.SetDebug,
          JSON.parse((queryParams.value.debug as string).toLowerCase())
        );
      }

      await assignAmtParams();
      await store.dispatch(ActionTypes.Reset);

      if (isExperiment.value) {
        await assignTask();
      }
    });

    return {
      taskId,
      isExperiment,
      showExperimentSidebar,
    };
  },
});
</script>
