<template>
  <div
    class="DialogHelp flex flex-col flex-grow-0 z-50 bg-white shadow overflow-hidden"
  >
    <div
      class="flex flex-row h-16 px-6 py-3 -mb-3 items-center justify-between"
    >
      <div class="text-lg">
        What can I say?
      </div>
      <div
        class="flex flex-row items-center text-ow-p-dark-blue cursor-pointer"
        @click="hide"
      >
        <div class="font-normal mr-3">Hide help</div>
        <font-awesome-icon class="text-xl" :icon="['far', 'times']" />
      </div>
    </div>
    <div class="flex-grow-0 overflow-scroll">
      <div
        class="py-5 border-b border-b-neutral-400 last:border-none"
        v-for="(section, s) in options"
        :key="s"
      >
        <div class="px-6 mb-3 font-normal">
          {{ section.title }}
        </div>
        <ul v-if="section.examples && section.examples.length > 0">
          <li
            v-for="(example, e) in section.examples"
            :key="e"
            class="px-6 py-2 text-neutral-800"
          >
            "{{ example }}"
          </li>
        </ul>
        <ul v-for="(option, o) in section.options" :key="o">
          <li
            class="px-6 py-2 hover:cursor-pointer hover:bg-neutral-200"
            :class="{
              'bg-neutral-200': accordianItem === option.title,
            }"
            @click="toggleAccordianItem(option.title)"
          >
            <font-awesome-icon
              v-if="accordianItem !== option.title"
              class="arrow"
              :icon="['fas', 'angle-right']"
            />
            <font-awesome-icon
              v-else
              class="arrow"
              :icon="['fas', 'angle-down']"
            />
            <span class="ml-3">{{ option.title }}</span>
          </li>
          <li v-if="accordianItem === option.title">
            <ul>
              <li
                v-for="(example, e) in option.examples"
                :key="e"
                class="px-12 py-2 text-neutral-800"
              >
                "{{ example }}"
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';

import options from '../../../assets/dialog/help';

export default defineComponent({
  setup() {
    const store = useStore();

    const accordianItem = ref('');
    const defaultAccordianItem = ref('');

    const show = computed(() => store.state.dialog.showHelp);
    const messages = computed(() => store.state.dialog.messages);

    function toggleAccordianItem(item: string) {
      if (accordianItem.value === item) {
        accordianItem.value = defaultAccordianItem.value;
      } else {
        accordianItem.value = item;
      }
    }

    function hide() {
      store.commit(MutationTypes.SetDialogShowHelp, false);
      accordianItem.value = defaultAccordianItem.value;
    }

    onMounted(() => {
      defaultAccordianItem.value = options[0].options[0].title;
      accordianItem.value = defaultAccordianItem.value;
    });

    watch(messages, () => hide());

    return {
      accordianItem,
      toggleAccordianItem,
      options,
      show,
      hide,
    };
  },
});
</script>
