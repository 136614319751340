<template>
  <div
    class="ShowInspired bg-ow-p-yellow border rounded-full flex flex-row items-center px-6 py-2 cursor-pointer hover:text-gray-500"
    @click="toggleShowInspire"
    v-if="showInspireFlag"
  >
    <span class="mr-4 whitespace-nowrap">Be Inspired</span>
    <span class="text-xl">
      <font-awesome-icon :icon="['fal', 'angle-up']" v-if="showInspire" />
      <font-awesome-icon :icon="['fal', 'angle-down']" v-else />
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';
import { event } from 'vue-gtag';

export default defineComponent({
  setup() {
    const store = useStore();

    const showInspire = computed(() => store.state.showInspiration);

    const showInspireFlag = ref(true);
    const ldClient = computed(() => store.state.ldClient);
    watch(ldClient, newVal => {
      if (newVal) {
        const flagValue = newVal.variation('be-inspired', false);
        showInspireFlag.value = flagValue;
      }
    });

    function toggleShowInspire() {
      if (showInspire.value) {
        store.commit(MutationTypes.SetInspirationItineraryCities, []);
      }
      store.commit(MutationTypes.SetShowInspiration, !showInspire.value);
      if (showInspire.value) {
        event('be-inspired-show', { method: 'Google' });
      } else {
        event('be-inspired-hide', { method: 'Google' });
      }
    }

    return {
      showInspire,
      showInspireFlag,
      toggleShowInspire,
    };
  },
});
</script>
