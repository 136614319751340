<template>
  <div
    class="SystemMessage flex flex-col flex-shrink-0 max-w-9/10"
    :data-intent-type="message.metadata?.systemIntent?.type"
    :data-intent-sub-type="message.metadata?.systemIntent?.subType"
    :data-intent-field="message.metadata?.systemIntent?.field"
    :data-flags="message.metadata?.flags"
    :data-disable-text-entry="message.disableTextEntry"
  >
    <div class="flex flex-col relative">
      <div class="absolute -left-4 -bottom-1.5" v-show="hasAvatar">
        <Avatar />
      </div>
      <div class="ml-4 flex flex-col">
        <div class="flex flex-row">
          <div
            class="rounded-3xl px-5 py-3 leading-6 mt-1.5"
            :class="{
              'rounded-bl-md': hasAvatar && !showSubtext,
              'bg-cool-gray-100': intent?.type !== 'error',
              'bg-red-400': intent?.type === 'error',
            }"
            v-html="text"
          />
        </div>
        <transition name="messages">
          <div class="flex flex-row">
            <div
              class="flex flex-row bg-alert-yellow-200 rounded-3xl pl-4 pr-5 py-3 leading-6 mt-1.5"
              :class="{
                'rounded-bl-md': hasAvatar,
              }"
              v-show="showSubtext"
            >
              <font-awesome-icon
                class="text-xl mr-2.5 mt-1"
                :icon="['fal', 'lightbulb']"
              />
              <div v-html="subtext" />
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="text-sm text-gray-400 mt-1 ml-4"
      :class="{
        '-mb-7': isFollowedByUserMessage,
      }"
      v-if="hasTimestamp && !nextSystemMessageHasSameTimestamp"
    >
      {{ timestamp }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(p) {
  @apply mb-2;

  &:last-child {
    @apply mb-0;
  }
}

:deep(ul) {
  @apply mx-4 mb-2 list-disc;
}
</style>

<script lang="ts">
import { PropType, defineComponent, computed, ref, onMounted } from 'vue';

import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
const md = new MarkdownIt().use(MarkDownItEmoji);

import {
  SystemMessage,
  formatTimestamp,
  timestampToDateForClient,
} from '@/api/service';
import { useStore } from '@/store';
import Avatar from '@/components/common/dialog/Avatar.vue';

export default defineComponent({
  components: { Avatar },
  props: {
    message: {
      type: Object as PropType<SystemMessage>,
      required: true,
    },
    isLastRequest: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['attention'],
  setup(props, { emit }) {
    const store = useStore();

    const readyToShowSubtext = ref(false);

    const text = computed(() =>
      props.message.text != null ? md.render(props.message.text) : ''
    );
    const subtext = computed(() =>
      props.message.subtext != null ? md.render(props.message.subtext) : ''
    );
    const intent = computed(() => props.message.metadata?.systemIntent);
    const hasSubtext = computed(
      () => props.message.subtext != null && props.message.subtext.length > 0
    );
    const hasRepeatedSubtext = computed(() =>
      store.getters.hasRepeatedSubtext(props.message)
    );
    const showSubtext = computed(
      () => hasSubtext.value && readyToShowSubtext.value
    );
    const hasTimestamp = computed(() => props.message.timestamp != null);
    const timestamp = computed(() =>
      formatTimestamp(
        props.message.timestamp,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'h:mm aaa'
      )
    );
    const isLastSystemMessageInGroup = computed(() =>
      store.getters.isLastSystemMessageInGroup(props.message)
    );
    const isLastSystemMessage = computed(
      () => store.getters.lastSystemMessage === props.message
    );
    const isLastMessage = computed(
      () => store.getters.lastMessage === props.message
    );
    const hasAvatar = computed(() => isLastSystemMessageInGroup.value);
    const nextSystemMessageHasSameTimestamp = computed(() => {
      const nm = store.getters.nextSystemMessage(props.message);
      return nm
        ? nm.timestamp != null &&
            props.message.timestamp != null &&
            timestampToDateForClient(nm.timestamp)?.getMinutes() ===
              timestampToDateForClient(props.message.timestamp)?.getMinutes()
        : false;
    });
    const isFollowedByUserMessage = computed(() =>
      store.getters.isFollowedByUserMessage(props.message)
    );
    const inputBoxLastInteraction = computed(
      () => store.state.dialog.inputBox.lastInteraction
    );

    function considerShowingSubText() {
      // Bail out under a few conditions
      if (
        !isLastMessage.value ||
        !hasSubtext.value ||
        readyToShowSubtext.value
      ) {
        return;
      }

      // Grab time since interaction
      let msSinceUserInteraction = undefined;
      if (inputBoxLastInteraction.value) {
        msSinceUserInteraction =
          new Date().valueOf() - inputBoxLastInteraction.value.valueOf();
      }

      let msSinceMessage = undefined;
      const messageTimestamp = timestampToDateForClient(
        props.message.timestamp
      );
      if (messageTimestamp) {
        msSinceMessage = new Date().valueOf() - messageTimestamp.valueOf();
      }

      // Delay values
      let delayAfterMessage = 12000;
      let delayAfterUserInteraction = 7000;
      if (hasRepeatedSubtext.value) {
        delayAfterMessage = 20000;
        delayAfterUserInteraction = 20000;
      }

      // If last system message, look to see if the input box has
      // been interacted with, and wait a little while
      const dontShowSubtextYet =
        msSinceMessage === undefined ||
        msSinceMessage < delayAfterMessage ||
        msSinceUserInteraction === undefined ||
        msSinceUserInteraction < delayAfterUserInteraction;

      // Recheck in a second or show
      if (dontShowSubtextYet) {
        setTimeout(() => considerShowingSubText(), 1000);
      } else {
        readyToShowSubtext.value = true;
        emit('attention');
      }
    }

    onMounted(() => considerShowingSubText());

    return {
      hasAvatar,
      text,
      subtext,
      showSubtext,
      hasRepeatedSubtext,
      hasSubtext,
      nextSystemMessageHasSameTimestamp,
      isFollowedByUserMessage,
      isLastSystemMessage,
      hasTimestamp,
      timestamp,
      intent,
    };
  },
});
</script>
