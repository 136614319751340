<template>
  <div class="VisitDuration">
    <!--  All is well or in batching without request  -->
    <div>
      <div class="row mb-4 mt-8">
        <div class="flex flex-grow-0 items-center whitespace-nowrap">
          {{ getStayDurationMessage() }}
          <div class="flex items-center pill ml-1 mr-1 h-7 w-13">
            <select onfocus='this.size=10' onblur='this.size=1' onchange='this.size=1'
              class="selector h-7 w-15"
              @change="onDropdownChange($event)"
              v-model="uiDuration"
            >
              <option class="selector-option" v-for="n in 99" :value="n" :key="n">{{ n }}</option>
            </select>
          </div>
          days.
          <a-tooltip title="Remove request" v-if="haveDurationRequestUi">
            <font-awesome-icon
              class="cursor-pointer ml-3 items-center"
              :icon="['far', 'xmark']"
              @click="removeRequest"
            />
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.pill {
  @apply border border-ow-p-dark-blue rounded-full
  px-3 py-1
  cursor-pointer;
  background-color: #fff;
  font-variant: tabular-nums;
  font-size: 14px;
}
.selector {
  z-index:999;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-variant: tabular-nums;
  max-height: 200px;
  height: 200px;
}

.selector-option {
  background-color: white;
  width: 50px;
}
.selector:focus {
  padding-top: 25px;
  align-self: start;
  outline: #252f3f;
  box-shadow: gray;
  border: 1px;
  background-color: white;
  font-variant: tabular-nums;
  max-height: 200px;
  height: 200px;
}
</style>
<script lang="ts">
import Logger from '@/logger';
import { ActionTypes } from '@/store/actions';
import { computed, defineComponent, PropType } from 'vue';
import { City, Formula } from '@/api/service';
import { useStore } from '@/store';
import {
    formulaBuilder,
    getUserRequestsDepartureDate,
    getUserRequestsExactDaysInVisit,
    literalIntegerBuilder,
    literalStringBuilder,
    propertyValueBuilder,
    sendActionBuilder,
} from '@/api/kr';
import { differenceInCalendarDays } from 'date-fns';
import { removeExactDurationPreference } from '@/api/user-actions';
import { ChangeEvent } from 'ant-design-vue/lib/_util/EventInterface';
const logger = new Logger('rtw:VisitDuration');
export default defineComponent({
  components: {},
  props: {
    city: {
      type: Object as PropType<City>,
      required: true,
    },
    preferences: {
      type: Object as PropType<Formula[]>,
      required: true,
    },
    arrivalDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    departureDate: {
      type: Object as PropType<Date>,
      required: false,
    },
  },
  setup: function(props) {
    const store = useStore();
    let showNegativeLengthVisitError = false;
    const negativeLengthVisitMessage = 'The visit length must be positive.';
    const isPhaseDatesBatching = computed(
      () => store.getters.isPhaseDatesBatching
    );

    function stayDurationUpdate(departureDate: Date | undefined) {
      const a = props.arrivalDate;
      const d = departureDate;
      if (a !== undefined && d !== undefined) {
        return differenceInCalendarDays(d, a);
      } else {
        return 0;
      }
    }

    function getStayDurationMessage() {
        if (haveDurationRequest.value) {
            return "You requested to stay for "
        } else  {
            return "You are staying for "
        }
    }

    const durationExactRequest = computed(() =>
      getUserRequestsExactDaysInVisit(props.preferences)
    );

    const initialDuration = computed(() => {
      if (durationExactRequest.value) {
        return durationExactRequest.value.exactDays;
      } else {
        return stayDurationUpdate(
          getUserRequestsDepartureDate(props.preferences)?.date
            ? getUserRequestsDepartureDate(props.preferences)?.date
            : props.departureDate
        );
      }
    });

    let uiDuration = initialDuration.value;

    async function updateDuration(
      city: City | undefined,
      cityDuration: number
    ) {
      if (cityDuration > 0 && cityDuration !== uiDuration) {
        uiDuration = cityDuration;
        if (city && city.code) {
            const action = sendActionBuilder(
                `Stay in ${city.name} for ${cityDuration} days`,
                formulaBuilder('rtwAction/setDuration', [
                    propertyValueBuilder('city', literalStringBuilder(city.code)),
                    propertyValueBuilder('days', literalStringBuilder(String(cityDuration)))])
            );
          await store.dispatch(ActionTypes.SendActions, [action]);
        } else {
          logger.error(
            'city is not defined while creating cityDurationConstraint'
          );
        }
      } else if (cityDuration <= 0) {
        showNegativeLengthVisitError = true;
      }
    }

    function onDropdownChange(selection: ChangeEvent) {
      if (selection && selection.target) {
        updateDuration(props.city, Number(selection.target.value));
      }
    }

    const durationRequest = computed(() =>
      getUserRequestsExactDaysInVisit(props.preferences)
    );

    const haveDurationRequest = computed(
      () => durationRequest.value != undefined
    );

    let haveDurationRequestUi = haveDurationRequest.value;

    function removeRequest() {
      if (haveDurationRequest.value) {
        let action = removeExactDurationPreference(
          props.city,
          durationRequest.value?._formula
        );
        if (action !== undefined) {
          store.dispatch(ActionTypes.SendActions, [action]);
        }
      }
    }

    return {
      isPhaseDatesBatching,
      showNegativeLengthVisitError,
      negativeLengthVisitMessage,
      updateDuration,
      uiDuration,
      onDropdownChange,
      removeRequest,
      haveDurationRequest,
      haveDurationRequestUi,
      getStayDurationMessage,
    };
  },
});
</script>
