<template>
  <a-popover
    placement="bottomLeft"
    trigger="clicked"
    color="none"
    v-model:visible="showOptions"
  >
    <template #content>
      <div class="min-w-60">
        <div class="text-lg font-normal mb-2">{{ header }}</div>
        <slot name="options" />
      </div>
    </template>
    <div
      class="mr-2 last:mr-0 flex p-0.5 whitespace-nowrap hover:text-ow-s-sapphire hover:cursor-pointer border rounded-full"
      :class="{
        'border-neutral-500': !isActive,
        'border-ow-p-dark-blue': isActive,
      }"
    >
      <div class="mx-2">{{ valStr }}</div>
      <div class="my-auto mr-2 text-right">
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'angle-down']"
          v-if="!showOptions"
        />
        <font-awesome-icon class="arrow" :icon="['fas', 'angle-up']" v-else />
      </div>
    </div>
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  props: {
    header: String,
    valStr: String,
    isActive: Boolean,
    scrollXPos: Number,
  },

  setup(props) {
    const showOptions = ref(false);

    const computedStyle = computed(() => {
      return {
        'margin-left': `-${props.scrollXPos}px`,
      };
    });

    return {
      showOptions,
      computedStyle,
    };
  },
});
</script>
