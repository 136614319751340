<template>
  <div class="Duration">
    <span class="select-none">Travelling for </span>
    <a-popover
      id="duration"
      trigger="clicked"
      placement="leftTop"
      :visible="showModal"
      @visibleChange="handleClickChange"
      class="z-2000"
    >
      <template #content>
        <div class="p-4">
          <div class="font-light text-2xl">
            Trip Duration
          </div>
          <div class="mt-2">
            How long would you like to travel?
          </div>
        </div>
        <div class="flex mx-4">
          <div class="flex flex-grow-0 whitespace-nowrap">
            <div
              class="customButton font-semibold text-lg text-gray-700 pr-1 pl-4 cursor-pointer"
              :class="{
                enabled: tripDurationVal > 1,
                'cursor-not-allowed': tripDurationVal <= 1,
                'opacity-40': tripDurationVal <= 1,
              }"
              @click="tripDurationVal > 1 ? changeTripDuration(-1) : null"
            >
              &#8211;
            </div>
            <input
              class="w-10 text-center text-lg"
              type="number"
              v-model="tripDurationVal"
            />
            <div
              class="customButton enabled pl-1 pr-2 font-semibold text-lg text-gray-700 cursor-pointer"
              @click="changeTripDuration(1)"
            >
              +
            </div>
          </div>
          <div class="text-lg text-gray-600 ml-8">
            days
          </div>
        </div>
        <div v-if="showDurationError" class="text-sm text-red-600 ml-6">
          {{ durationErrorMessage }}
        </div>
        <div class="flex justify-center pt-2 mt-8 pb-4">
          <span
            class="mr-4 cursor-pointer hover:text-black text-gray-500 my-auto btn secondary"
            @click="showModal = false"
          >
            Cancel
          </span>
          <button
            class="btn"
            @click="updateDuration"
            :class="{
              'cursor-not-allowed': showDurationError,
              'opacity-40': showDurationError,
            }"
          >
            Confirm
          </button>
        </div>
      </template>
      <span
        :class="{
          'cursor-pointer': !requireDialogResponse,
          'cursor-not-allowed opacity-50': requireDialogResponse,
        }"
        @click="toggleShowModal"
      >
        <span
          class="text-lg text-ow-p-dark-blue hover:border-b border-ow-p-dark-blue"
        >
          {{ tripDurationText }}
        </span>
        <font-awesome-icon class="ml-2 text-xl" :icon="['fal', 'calendar']" />
      </span>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import {
  formulaBuilder,
  literalIntegerBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

import { differenceInCalendarDays } from 'date-fns';
import { localDateToDate, unMaybe } from '@/api/service';

const zeroDayErrorMessage = 'The number of days must be greater than 0.';

export default defineComponent({
  setup() {
    const store = useStore();

    const showEdit = ref(false);
    const notSetYet = ref(true);

    const tripDurationText = computed(() => {
      if (
        actualTripDuration.value &&
        actualTripDuration.value != tripDuration.value
      ) {
        return `${actualTripDuration.value} day${
          actualTripDuration.value === 1 ? '' : 's'
        } (req. ${tripDuration.value})`;
      } else {
        return `${tripDuration.value} day${
          tripDuration.value === 1 ? '' : 's'
        }`;
      }
    });
    const tripDuration = computed(() => store.state.itinerary?.tripDuration);
    const tripDurationVal = ref(0);
    const errorMessage = ref('');
    const showDurationError = ref(false);
    const durationErrorMessage = ref('');
    const actualTripDuration = computed(() => {
      const departureLocalDate = localDateToDate(
        unMaybe(store.state.itinerary?.departureLocalDate)
      );
      const arrivalLocalDate = localDateToDate(
        unMaybe(store.state.itinerary?.arrivalLocalDate)
      );
      if (departureLocalDate && arrivalLocalDate) {
        return differenceInCalendarDays(arrivalLocalDate, departureLocalDate);
      } else {
        return undefined;
      }
    });

    watch(tripDuration, newVal => {
      if (newVal) {
        tripDurationVal.value = newVal;
      }
    });
    watch(tripDurationVal, newVal => {
      if (newVal && newVal > 0) {
        tripDurationVal.value = newVal;
        showDurationError.value = false;
        durationErrorMessage.value = '';
      } else {
        showDurationError.value = true;
        durationErrorMessage.value = zeroDayErrorMessage;
      }
    });

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    async function updateDuration() {
      if (showDurationError.value) return;
      let sendDurationUpdate = false;
      let hasError = false;
      if (
        tripDurationVal.value > 0 &&
        tripDurationVal.value !== tripDuration.value
      ) {
        sendDurationUpdate = true;
      } else if (tripDurationVal.value <= 0) {
        hasError = true;
        showDurationError.value = true;
        durationErrorMessage.value = zeroDayErrorMessage;
      }
      if (!hasError) {
        if (sendDurationUpdate) {
          const action = sendActionBuilder(
            `Set trip duration for ${tripDurationVal.value} days`,
            formulaBuilder('rtwAction/setTripDuration', [
              propertyValueBuilder(
                'days',
                literalIntegerBuilder(tripDurationVal.value)
              ),
            ])
          );
          await store.dispatch(ActionTypes.SendActions, [action]);
        }
        showModal.value = false;
      }
    }

    function changeTripDuration(change: number): void {
      tripDurationVal.value = Number(tripDurationVal.value) + change;
      if (tripDurationVal.value > 0) {
        showDurationError.value = false;
        durationErrorMessage.value = '';
      }
      if (tripDurationVal.value === 0 && tripDurationVal.value === 0) {
        showDurationError.value = true;
        durationErrorMessage.value = zeroDayErrorMessage;
      }
    }

    const showModal = ref(false);
    function toggleShowModal() {
      if (!requireDialogResponse.value) {
        showModal.value = !showModal.value;
      }
    }
    function hideModal() {
      showModal.value = false;
    }
    function handleClickChange(visible: boolean) {
      if (!requireDialogResponse.value) {
        showModal.value = visible;
      }
    }

    onMounted(() => {
      if (tripDuration.value !== null && tripDuration.value !== undefined) {
        tripDurationVal.value = tripDuration.value;
      }
    });

    return {
      showEdit,
      notSetYet,
      tripDurationText,
      tripDuration,
      tripDurationVal,
      actualTripDuration,
      requireDialogResponse,
      errorMessage,
      showDurationError,
      durationErrorMessage,
      changeTripDuration,
      updateDuration,
      showModal,
      hideModal,
      toggleShowModal,
      handleClickChange,
    };
  },
});
</script>
