<template>
  <Preference triggerId="passengers" :title="title" v-model:showEdit="showEdit">
    <template v-slot:body>
      <div class="w-80 pt-6 px-6">
        <div class="row mb-4">
          <div class="flex flex-grow text-gray-600 mr-4">
            Adult (11+ years)
          </div>
          <div class="flex flex-grow-0 whitespace-nowrap">
            <div
              class="customButton font-semibold pr-1 pl-2 py-1"
              :class="{
                'cursor-pointer text-gray-700': numAdultsVal > 1,
                'cursor-not-allowed text-gray-400': numAdultsVal < 2,
              }"
              @click="numAdultsVal > 1 ? changeAdultCount(-1) : null"
            >
              &#8211;
            </div>
            <input
              class="w-10 text-center text-gray-600"
              type="number"
              min="1"
              max="99"
              v-model="numAdultsVal"
            />
            <div
              class="customButton font-semibold pl-1 pr-2 py-0.5"
              :class="{
                'cursor-pointer text-gray-700': numAdultsVal < 9,
                'cursor-not-allowed text-gray-400': numAdultsVal > 8,
              }"
              @click="numAdultsVal < 9 ? changeAdultCount(1) : null"
            >
              +
            </div>
          </div>
        </div>
        <div class="row">
          <div class="flex flex-grow text-gray-600">
            Child (2-10 years)
          </div>
          <div class="flex flex-grow-0 whitespace-nowrap">
            <div
              class="customButton font-semibold pr-1 pl-2 py-1 "
              :class="{
                'cursor-pointer text-gray-700': numChildrenVal > 0,
                'cursor-not-allowed text-gray-400': numChildrenVal < 1,
              }"
              @click="numChildrenVal > 0 ? changeChildCount(-1) : null"
            >
              &#8211;
            </div>
            <input
              class="w-10 text-center"
              type="number"
              min="0"
              max="99"
              v-model="numChildrenVal"
            />
            <div
              class="customButton enabled pl-1 pr-2 py-0.5 font-semibold "
              :class="{
                'cursor-pointer text-gray-700': numChildrenVal < 9,
                'cursor-not-allowed text-gray-400': numChildrenVal > 8,
              }"
              @click="numChildrenVal < 9 ? changeChildCount(1) : null"
            >
              +
            </div>
          </div>
        </div>
        <div class="row" v-if="showError || showPassengerCountError">
          <div v-if="showError" class="text-sm text-red-600">
            {{ errorMessage }}
          </div>
          <div v-if="showPassengerCountError" class="text-sm text-red-600">
            {{ passengerCountErrorMessage }}
          </div>
        </div>
        <div class="row">
          <div class="flex flex-row text-sm p-3 bg-alert-yellow-200 rounded-xl">
            <font-awesome-icon
              class="text-xl mr-2.5 mt-1"
              :icon="['fal', 'lightbulb']"
            />
            <div>
              Child fares apply to travellers who are at least 2 years old and
              no more than 11 years old <em>before</em> completion of the
              Round-the-world journey. Infants cannot be booked on-line.
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end p-6 pt-3">
        <span
          class="mr-4 cursor-pointer hover:text-black text-gray-500 my-auto "
          @click="showEdit = false"
        >
          Cancel
        </span>
        <button
          class="btn"
          :disabled="showPassengerCountError || showError"
          @click="updateTravelerCount"
        >
          Save
        </button>
      </div>
    </template>
  </Preference>
</template>

<style lang="scss" scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row {
  @apply flex flex-row mb-4;
}
</style>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

import { TravelInfo } from '@/api/service';

import Preference from '@/components/desktop/preferences/Preference.vue';
import {
  formulaBuilder,
  literalIntegerBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

const zeroCountErrorMessage = 'The number of adults must be greater than 0.';
const maxDigits = 1;
const maxPassengers = 9;
const passengerCountErrorMessage = 'There is a maximum of 9 travelers';

export default defineComponent({
  components: {
    Preference,
  },
  setup() {
    const store = useStore();

    const showEdit = ref(false);

    const travelInfo = computed<TravelInfo | undefined>(
      () => store.state.travelInfo
    );

    const numAdults = computed<number>(() =>
      travelInfo.value?.numAdults != null ? travelInfo.value?.numAdults : 0
    );
    const numAdultsVal = ref(numAdults.value);
    watch(numAdultsVal, newVal => {
      numAdultsVal.value = Number(
        validateNumbericInput(newVal.toString()).slice(0, maxDigits)
      );
      if (newVal < 1) {
        showError.value = true;
        errorMessage.value = zeroCountErrorMessage;
      } else {
        showError.value = false;
        errorMessage.value = '';
      }
    });

    const showError = ref(false);
    const errorMessage = ref('');
    function validateNumbericInput(input: string) {
      return input.replace(/\D/g, '');
    }
    watch(numAdults, newVal => (numAdultsVal.value = newVal));

    const numChildren = computed<number>(() =>
      travelInfo.value?.numChildren != null ? travelInfo.value?.numChildren : 0
    );
    const numChildrenVal = ref(numChildren.value);
    watch(numChildrenVal, newVal => {
      numChildrenVal.value = Number(
        validateNumbericInput(newVal.toString()).slice(0, maxDigits)
      );
    });
    watch(numChildren, newVal => (numChildrenVal.value = newVal));

    const title = computed(() => {
      let t = numAdults.value + ' Adult';
      if (numAdults.value > 1) {
        t += 's';
      }
      t += ', ';
      t += numChildren.value;
      if (numChildren.value === 1) {
        t += ' Child';
      } else {
        t += ' Children';
      }
      return t;
    });

    function updateTravelerCount(): void {
      if (numAdultsVal.value > 0) {
        const action = sendActionBuilder(
          `Set number of travelers to ${numAdultsVal.value} adult${
            numAdultsVal.value === 1 ? '' : 's'
          }, ${numChildrenVal.value} ${
            numChildrenVal.value === 1 ? 'child' : 'children'
          }`,
          formulaBuilder('rtwAction/setPassengerCount', [
            propertyValueBuilder(
              'numAdults',
              literalIntegerBuilder(numAdultsVal.value)
            ),
            propertyValueBuilder(
              'numChildren',
              literalIntegerBuilder(numChildrenVal.value)
            ),
          ])
        );
        store.dispatch(ActionTypes.SendActions, [action]);
        showEdit.value = false;
      } else {
        showError.value = true;
        errorMessage.value = zeroCountErrorMessage;
      }
    }
    function changeAdultCount(change: number): void {
      numAdultsVal.value = numAdultsVal.value + change;
      if (numAdultsVal.value > 0) {
        showError.value = false;
      }
      if (numAdultsVal.value === 0 && numChildrenVal.value === 0) {
        showError.value = true;
        errorMessage.value = zeroCountErrorMessage;
      }
    }

    function changeChildCount(change: number): void {
      numChildrenVal.value = numChildrenVal.value + change;
      if (numChildrenVal.value > 0) {
        showError.value = false;
      }
      if (numAdultsVal.value === 0 && numChildrenVal.value === 0) {
        showError.value = true;
        errorMessage.value = zeroCountErrorMessage;
      }
    }

    const currentPassengers = computed(
      () => numAdultsVal.value + numChildrenVal.value
    );
    const showPassengerCountError = computed(
      () => currentPassengers.value > maxPassengers
    );

    onMounted(() => {
      numAdultsVal.value = numAdults.value;
      numChildrenVal.value = numChildren.value;
    });

    return {
      title,
      showEdit,
      travelInfo,
      numAdultsVal,
      numAdults,
      numChildrenVal,
      numChildren,
      showError,
      errorMessage,
      updateTravelerCount,
      changeAdultCount,
      changeChildCount,
      maxPassengers,
      currentPassengers,
      passengerCountErrorMessage,
      showPassengerCountError,
    };
  },
});
</script>
