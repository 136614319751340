<template>
  <div
    class="InputBoxCalendar p-2 py-3 rounded-t-xl border flex flex-row justify-center"
  >
    <!-- div class="font-semibold px-4 pt-2">{{ headerText }}</div -->
    <div class="flex justify-center w-5/6">
      <DatePicker
        v-if="expectsDateRange"
        class="dialog-calendar"
        color="blue"
        v-model="dateRangeVal"
        mode="date"
        is-range
        :columns="1"
        :min-date="minDate"
        :max-date="maxDate"
      />
      <DatePicker
        v-else-if="expectsDate"
        class="dialog-calendar"
        color="blue"
        v-model="dateVal"
        mode="date"
        :columns="1"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import { format } from 'date-fns';
import { useStore } from '@/store';
import { dayFormattingShort, dateFormatting } from '@/api/service';
import { ActionTypes } from '@/store/actions';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

export default defineComponent({
  emits: ['hide'],
  setup(_, { emit }) {
    const store = useStore();

    const expectsDateRange = computed(
      () => store.getters.lastSystemMessageExpectsDateRange
    );
    const expectsDate = computed(
      () => store.getters.lastSystemMessageExpectsDate
    );

    const headerText = computed(() =>
      expectsDateRange.value ? 'Select date range' : 'Select date'
    );
    const dateVal = ref();
    const dateRangeVal = ref();

    function dateProp(date: string, propName: string) {
      return propertyValueBuilder(propName, literalStringBuilder(date));
    }

    function dateRangeProps(startDate: string, endDate: string) {
      return [
        dateProp(startDate, 'startLocalDate'),
        dateProp(endDate, 'endLocalDate'),
      ];
    }
    watch(dateVal, newVal => {
      const date = newVal;
      const dateText = format(date, dayFormattingShort);
      const actionDate = format(date, dateFormatting);

      const action = sendActionBuilder(
        `${dateText}`,
        formulaBuilder('rtwAction/processIntent', [
          propertyValueBuilder('intent', {
            formula: formulaBuilder('rtwIntent/setDate', [
              dateProp(actionDate, 'localDate'),
            ]),
          }),
        ])
      );

      store.dispatch(ActionTypes.SendActions, [action]);
      emit('hide');
    });

    watch(dateRangeVal, newVal => {
      const startDate = newVal.start;
      const startDateText = format(startDate, dayFormattingShort);
      const actionStartDate = format(startDate, dateFormatting);
      const endDate = newVal.end;
      const endDateText = format(endDate, dayFormattingShort);
      const actionEndDate = format(endDate, dateFormatting);

      const action = sendActionBuilder(
        `${startDateText} - ${endDateText}`,
        formulaBuilder('rtwAction/processIntent', [
          propertyValueBuilder('intent', {
            formula: formulaBuilder(
              'rtwIntent/setDateRange',
              dateRangeProps(actionStartDate, actionEndDate)
            ),
          }),
        ])
      );

      store.dispatch(ActionTypes.SendActions, [action]);
      emit('hide');
    });

    const minDate = computed(() => {
      // today
      return new Date();
    });

    const maxDate = computed(() => {
      // one year in the future
      return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    });

    return {
      expectsDateRange,
      expectsDate,
      headerText,
      dateVal,
      dateRangeVal,
      minDate,
      maxDate,
    };
  },
});
</script>
