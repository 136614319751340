<template>
  <!-- City Date Info -->
  <div class="flex-grow flex flex-row items-center">
    <!-- calendar icon -->
    <font-awesome-icon
      :class="{
        'text-black': !missingDates,
      }"
      :id="`calendar-${index}`"
      :icon="['fal', datesIcon]"
    />

    <!-- dates -->
    <div class="flex-grow ml-3 mr-4 items-center">
      <div v-if="missingDates">
        {{ missingDatesText }}
      </div>
      <div class="flex flex-row items-center justify-between" v-else>
        <!-- Arrival -->
        <div class="flex flex-col" v-if="systemArrivalDateStr">
          <div v-if="datesOutOfSync">
            <div v-if="userArrivalDateStr" class="leading-6">
              {{ userArrivalDateStr }}
            </div>
            <div v-else class="h-6" />
          </div>
          <div
            :class="{
              outdated: globalOutOfSync,
              overridden: arrivalDateRequestOutOfSync,
            }"
          >
            {{ systemArrivalDateStr }}
          </div>
        </div>
        <div class="flex flex-col" v-else-if="isGroundArrival">
          <font-awesome-icon :icon="['fas', 'car-side']" />
        </div>

        <!-- Dash -->
        <div
          class="flex flex-col mx-1"
          v-if="!missingDates && !isOriginVisit && !isFinalVisit"
        >
            <div v-if="datesOutOfSync" class="h-6" />
          <div :class="{ outdated: globalOutOfSync }" >
            -
          </div>
        </div>

        <!-- Departure -->
        <div class="flex flex-col" v-if="systemDepartureDateStr">
          <div v-if="datesOutOfSync">
            <div v-if="userDepartureDateStr" class="leading-6">
              {{ userDepartureDateStr }}
            </div>
            <div v-else class="h-6" />
          </div>
          <div
            :class="{
              outdated: globalOutOfSync,
              overridden: departureDateRequestOutOfSync,
            }"
          >
            {{ systemDepartureDateStr }}
          </div>
        </div>
        <div class="flex flex-col" v-else-if="isGroundDeparture">
          <font-awesome-icon :icon="['fas', 'car-side']" />
        </div>

        <!-- Duration -->
        <div class="flex flex-col flex-grow" v-if="userDurationStr || systemDurationStr">
          <div v-if="durationRequestOutOfSync || datesOutOfSync">
            <div class="text-right leading-6" v-if="userDurationStr">
              {{ userDurationStr }}
            </div>
            <div v-else class="h-6" />
          </div>
          <div
            class="text-right"
            :class="{
              outdated: durationRequestOutOfSync || datesOutOfSync || globalOutOfSync,
              overridden: durationRequestOutOfSync || datesOutOfSync,
            }"
          >
            {{ systemDurationStr }}
          </div>
        </div>
        <div class="flex flex-col flex-grow" v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.overridden {
  text-decoration: line-through;
}
.outdated {
  @apply text-neutral-500;
}
</style>

<script lang="ts">
import { defineComponent, PropType, computed, toRefs } from 'vue';
import { format, differenceInCalendarDays } from 'date-fns';

import { useStore } from '@/store';
import { Concept, Formula } from '@/api/service';
import {
  getUserRequestsArrivalDate,
  getUserRequestsDepartureDate,
  getUserRequestsExactDaysInVisit,
  getUserRequestsMinDaysInVisit,
} from '@/api/kr';
const dateTextFormatting = 'd MMM';

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    arrivalDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    isGroundArrival: {
      type: Boolean,
      required: false,
    },
    departureDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    isGroundDeparture: {
      type: Boolean,
      required: false,
    },
    duration: {
      type: Number,
      required: false,
    },
    preferences: {
      type: Object as PropType<Array<Formula>>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      arrivalDate,
      isGroundArrival,
      departureDate,
      isGroundDeparture,
      preferences,
    } = toRefs(props);

    const isOriginVisit = computed(() =>
      store.getters.isOriginVisit(props.visit)
    );
    const isFinalVisit = computed(() =>
      store.getters.isFinalVisit(props.visit)
    );
    const hadDates = computed(() => store.state.hadDates);
    const missingDates = computed(() => !store.getters.haveDates);
    const isPhaseDatesBatching = computed(
      () => store.getters.isPhaseDatesBatching
    );
    const globalOutOfSync = computed(
      () =>
        store.getters.requestForTimingOutOfSync && isPhaseDatesBatching.value
    );

    const datesIcon = computed(() =>
      hadDates.value && missingDates.value ? 'calendar-minus' : 'calendar'
    );
    const missingDatesText = computed(() => {
      if (hadDates.value) {
        return 'reworking dates...';
      } else {
        return 'yet to specify dates';
      }
    });

    const arrivalDateRequest = computed(() =>
      getUserRequestsArrivalDate(preferences.value)
    );
    const arrivalDateRequestOutOfSync = computed(
      () =>
        !isGroundArrival.value &&
        isPhaseDatesBatching.value &&
        arrivalDateRequest.value &&
        arrivalDateRequest.value.date !== arrivalDate.value
    );

    const departureDateRequest = computed(() =>
      getUserRequestsDepartureDate(preferences.value)
    );
    const departureDateRequestOutOfSync = computed(
      () =>
        !isGroundDeparture.value &&
        isPhaseDatesBatching.value &&
        departureDateRequest.value &&
        departureDateRequest.value.date !== departureDate.value
    );

    const duration = computed(() => {
      const a = arrivalDate.value;
      const d = departureDate.value;
      if (a && d) {
        return differenceInCalendarDays(d, a);
      } else {
        return undefined;
      }
    });
    const durationMinRequest = computed(() =>
      getUserRequestsMinDaysInVisit(preferences.value)
    );
    const durationExactRequest = computed(() =>
      getUserRequestsExactDaysInVisit(preferences.value)
    );

    const durationRequestOutOfSync = computed(
      () =>
        duration.value !== undefined &&
        isPhaseDatesBatching.value &&
        ((durationMinRequest.value &&
          durationMinRequest.value.minDays !== duration.value) ||
          (durationExactRequest.value &&
            durationExactRequest.value.exactDays !== duration.value))
    );

    function formatDateText(localDate: Date) {
      return format(localDate, dateTextFormatting);
    }

    //
    // System Dates

    const systemArrivalDateStr = computed(() => {
      return arrivalDate.value ? formatDateText(arrivalDate.value) : undefined;
    });

    const systemDepartureDateStr = computed(() => {
      return departureDate.value
        ? formatDateText(departureDate.value)
        : undefined;
    });

    const systemDurationStr = computed(() => {
      return duration.value !== undefined
        ? `${duration.value} days`
        : undefined;
    });

    //
    // User preferences

    const datesOutOfSync = computed(
      () =>
        durationRequestOutOfSync.value ||
        arrivalDateRequestOutOfSync.value ||
        departureDateRequestOutOfSync.value
    );

    const userArrivalDateStr = computed(() => {
      if (arrivalDateRequest.value && arrivalDateRequestOutOfSync.value) {
        return formatDateText(arrivalDateRequest.value.date);
      } else {
        return undefined;
      }
    });

    const userDepartureDateStr = computed(() => {
      if (departureDateRequest.value && departureDateRequestOutOfSync.value) {
        return formatDateText(departureDateRequest.value?.date);
      } else {
        return undefined;
      }
    });

    const userDurationStr = computed(() => {
      if (durationExactRequest.value && durationRequestOutOfSync.value) {
        return `${durationExactRequest.value.exactDays} days`;
      } else {
        return undefined;
      }
    });

    return {
      isOriginVisit,
      isFinalVisit,
      hadDates,
      missingDates,
      missingDatesText,
      datesIcon,
      systemArrivalDateStr,
      systemDepartureDateStr,
      systemDurationStr,
      datesOutOfSync,
      globalOutOfSync,
      userArrivalDateStr,
      arrivalDateRequestOutOfSync,
      userDepartureDateStr,
      departureDateRequestOutOfSync,
      userDurationStr,
      durationRequestOutOfSync,
    };
  },
});
</script>
