<template>
  <div
    class="TooltipWrapper fixed z-3000"
    :style="modalStyle"
    ref="modal"
    v-click-outside="hide"
  >
    <div class="modal-arrow top" v-if="arrowVerticalType === 'top'">
      <span class="arrow" :class="arrowHorizontalType" />
    </div>
    <div class="Tooltip bg-gray-800 rounded-lg shadow-lg">
      <div
        class="rounded-lg h-full overflow-hidden flex flex-col space-between bg-gray-800 text-gray-50 p-5"
      >
        <div class="font-bold text-lg">
          {{ title }}
        </div>
        <div class="">
          {{ content }}
        </div>
        <div class="rounded-xl pt-4 flex justify-end text-sm font-normal">
          <div
            class="flex-end uppercase font-bold lead hover:text-gray-400 cursor-pointer my-auto"
            @click="dismissAll"
          >
            Do not show tips anymore
          </div>
          <button class="btn dark ml-4 flex-end uppercase" @click="hide">
            Got it!
          </button>
        </div>
      </div>
    </div>
    <div class="modal-arrow bottom" v-if="arrowVerticalType === 'bottom'">
      <span class="arrow-bottom" :class="arrowHorizontalType" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.TooltipWrapper {
  width: fit-content;
  height: fit-content;
}
.Tooltip {
  width: 464px;
}
.modal-arrow {
  .arrow {
    content: '';
    display: block;
    transform: rotate(90deg);
    width: 0;
    height: 0;

    position: absolute;
    top: -12px;

    background: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #252f3f;

    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
    }
  }
  .arrow-bottom {
    content: '';
    display: block;
    transform: rotate(270deg);
    width: 0;
    height: 0;

    position: absolute;
    bottom: -12px;

    background: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #252f3f;

    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
    }
  }
}
</style>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  onUpdated,
  nextTick,
} from 'vue';

const WIDTH = 430;
const OFFSET_X = 30;
const OFFSET_Y = 12;
const OFFSET_MIN = 0;

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
    anchor: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const modal = ref(null);
    const triggerElement = ref<HTMLElement>();
    const triggerBounds = ref<DOMRect>();

    const arrowHorizontalType = ref('left');
    const arrowVerticalType = ref('left');
    const offset = reactive({
      top: ref(0),
      left: ref(0),
      bottom: ref(0),
      right: ref(0),
    });

    function hide() {
      emit('update:hide');
    }

    function dismissAll() {
      emit('update:dismissAll');
    }

    const modalStyle = computed<object>(() => {
      return {
        top: offset.top !== 0 && `${offset.top}px`,
        left: offset.left !== 0 && `${offset.left}px`,
        bottom: offset.bottom !== 0 && `${offset.bottom}px`,
        right: offset.right !== 0 && `${offset.right}px`,
      };
    });

    function update() {
      nextTick(() => {
        const m = (modal.value as unknown) as HTMLElement;

        if (props.anchor) {
          const trigger = document.querySelector(props.anchor) as HTMLElement;
          if (trigger) {
            triggerElement.value = trigger;
            triggerBounds.value = trigger.getBoundingClientRect();

            // arrowHorizontalType
            if (triggerBounds.value.x < (window.innerWidth * 2) / 3) {
              arrowHorizontalType.value = 'left';
              const offsetLeft = Math.max(
                triggerBounds.value.x - OFFSET_X,
                OFFSET_MIN
              );
              offset.left = offsetLeft;
            } else {
              arrowHorizontalType.value = 'right';
              offset.left =
                triggerBounds.value.x + triggerBounds.value.width / 2 - WIDTH;
            }

            // arrowVerticalType
            if (triggerBounds.value.y < (window.innerHeight * 2) / 3) {
              arrowVerticalType.value = 'top';
              offset.top =
                triggerBounds.value.y + triggerBounds.value.height + OFFSET_Y;
              if (offset.top + m.scrollHeight > window.innerHeight) {
                offset.top = Math.max(
                  OFFSET_Y,
                  window.innerHeight - (OFFSET_Y + m.scrollHeight)
                );
              }
            } else {
              arrowVerticalType.value = 'bottom';
              offset.bottom =
                window.innerHeight - triggerBounds.value.y + OFFSET_Y;
            }

            // Force inside window bounds
            // TODO
          } else {
            offset.top = 100;
            offset.left = 100;
            offset.bottom = 100;
            offset.right = 100;
          }
        } else {
          offset.top = 100;
          offset.left = 100;
          offset.bottom = 100;
          offset.right = 100;
        }
      });
    }

    onMounted(() => update());
    onUpdated(() => update());

    return {
      modal,
      offset,
      arrowHorizontalType,
      arrowVerticalType,
      hide,
      modalStyle,
      dismissAll,
    };
  },
});
</script>
