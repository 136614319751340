<template>
  <div
    class="Header relative text-ow-p-dark-blue flex flex-row justify-between items-center"
  >
    <div class="absolute top-2 left-4 bg-white rounded-full w-24 h-24 p-2">
      <img src="@/assets/oneworld/oneworld.webp" alt="logo" class="w-20 h-20" />
    </div>
    <div class="text-xl whitespace-nowrap overflow-hidden overflow-ellipsis">
      Round The World with <span class="font-medium">one</span>world Explorer
    </div>
    <User />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import User from './User.vue';

export default defineComponent({
  components: {
    User,
  },
});
</script>
