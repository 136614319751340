<template>
  <Preference triggerId="seat-class" :title="title" v-model:showEdit="showEdit">
    <template v-slot:body>
      <div
        v-for="(option, o) in SEAT_CLASS"
        :key="o"
        class="capitalize px-8 py-4 hover:bg-ec-notification-blue-100 cursor-pointer text-gray-600 overscroll-none overflow-hidden"
        :class="{
          'font-semibold': seatClass === option.value,
          'bg-ec-notification-blue-100': seatClass === option.value,
        }"
        :value="option.code"
        @click="selectSeatClass(option.value)"
      >
        {{ option.label }}
      </div>
    </template>
  </Preference>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

import { SeatClass, TravelInfo, UserAction } from '@/api/service';
import { seatClassType, SEAT_CLASS } from '@/store/getters/base';

import Preference from '@/components/desktop/preferences/Preference.vue';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
} from '@/api/kr';

export default defineComponent({
  components: {
    Preference,
  },
  setup() {
    const store = useStore();

    const showEdit = ref(false);
    const title = ref('');

    const travelInfo = computed<TravelInfo | undefined>(
      () => store.state.travelInfo
    );

    const seatClass = computed<SeatClass | undefined>(() =>
      travelInfo.value?.seatClass != null
        ? travelInfo.value?.seatClass
        : undefined
    );

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    function selectSeatClass(classStr: SeatClass): void {
      const action: UserAction = {
        text:
          'set seat class to ' + store.getters.getReadibleSeatClass(classStr),
        action: formulaBuilder('rtwAction/setSeatClass', [
          propertyValueBuilder('seatClass', literalStringBuilder(classStr)),
        ]),
      };
      store.dispatch(ActionTypes.SendActions, [action]);
      showEdit.value = false;
    }

    function findSeatClassByValue(
      sClass: string | undefined
    ): seatClassType | undefined {
      return store.getters.findSeatClassByValue(sClass);
    }

    onMounted(() => {
      if (seatClass.value != undefined) {
        title.value =
          store.getters.getReadibleSeatClass(seatClass.value) + ' Class';
      }
    });

    watch(seatClass, newVal => {
      if (newVal) {
        title.value = findSeatClassByValue(newVal)?.label || '';
      }
    });

    return {
      showEdit,
      title,
      seatClass,
      requireDialogResponse,
      findSeatClassByValue,
      selectSeatClass,
      SEAT_CLASS,
    };
  },
});
</script>
