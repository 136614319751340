<template>
  <div>
    <div
      class="overview-row grid grid-cols-5 md:grid-cols-10 gap-2 my-auto rounded-lg"
      :class="[
        selected ? 'border border-blue-600' : 'border-t border-gray-200',
      ]"
    >
      <div class="col-span-1 mx-auto my-auto">
        <input
          type="radio"
          class="cursor-pointer"
          v-model="selectedFlight"
          :value="flight"
          name="selectedFlight"
        />
      </div>
      <div class="col-span-3 md:col-span-8 md:grid md:grid-cols-8 md:gap-2">
        <div
          class="md:col-span-3 flex flex-row items-center justify-between md:flex-col"
        >
          <img :src="logoUrl" class="h-14" alt="logo" />
          <div class="md:mb-2 md:text-center">
            {{ flightStr }}
          </div>
        </div>
        <div class="grid grid-cols-4 gap-2 md:col-span-5">
          <div class="col-span-4 flex-grow grid grid-cols-5 gap-2">
            <div class="col-span-5 flex items-center">
              <div class="mr-2 flex flex-col">
                <div class="text-lg">
                  {{ departureAirport.code }}
                </div>
                <div>
                  {{ departureTimeStr }}
                </div>
              </div>
              <div class="flex-grow flex flex-col text-sm">
                <div class="flex-grow text-center" v-if="legs">
                  <div v-if="legs.length <= 1">
                    Nonstop
                  </div>
                  <div v-else>
                    {{ legs.length - 1 }}
                    {{ stopsStr }}
                    via
                    {{ layoverStr }}
                  </div>
                </div>
                <FlightLine />
                <div class="flex-grow text-center" v-if="flight">
                  <font-awesome-icon
                    :icon="['far', 'clock']"
                    class="text-ow-s-sapphire"
                  />
                  {{ duration }}
                </div>
              </div>
              <div class="ml-2 flex flex-col items-end">
                <div class="text-lg">
                  {{ arrivalAirport.code }}
                </div>
                <div>{{ arrivalTimeStr }}</div>
                <div class="text-ow-s-ruby text-center text-sm" v-if="diffDay">
                  {{ diffDay }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-1 text-md my-auto mr-4 text-right text-ow-s-sapphire hover:text-gray-500 hover:cursor-pointer"
        @click="toggleFlightDetails"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'angle-down']"
          v-if="!showFlightDetails"
        />
        <font-awesome-icon class="arrow" :icon="['fas', 'angle-up']" v-else />
      </div>
    </div>
    <FlightDetail
      v-if="showFlightDetails"
      :flight="flight"
      :previousFlight="previousFlight"
      class="px-10 bg-white"
    />
  </div>
</template>

<style lang="scss" scoped>
.overview-row {
  min-height: 80px;
}
</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, ref } from 'vue';
import { useStore } from '@/store';
import {
  Segment,
  Flight,
  minutesToDurationFormatter,
  unMaybe,
  unMaybeArray,
  timestampToDate,
  formatTimestamp,
  dayFormatting,
  timeFormatting,
  dateOffsetString,
  Leg,
} from '@/api/service';

import FlightLine from '../step/FlightLine.vue';
import FlightDetail from './FlightDetail.vue';

export default defineComponent({
  props: {
    flight: Object as PropType<Flight>,
    previousFlight: Object as PropType<Flight>,
    segment: Object as PropType<Segment>,
    selected: Boolean,
  },
  components: {
    FlightLine,
    FlightDetail,
  },
  setup(props, { emit }) {
    const store = useStore();
    const showFlightDetails = ref(false);
    const duration = computed(() =>
      minutesToDurationFormatter(unMaybe(props.flight?.elapsedTime))
    );

    const selectedFlight = ref(props.segment?.flightSegment?.selectedFlight);

    const legs = computed(() => unMaybeArray(props.flight?.legs));
    const logoUrl = computed<string>(() => {
      if (props.flight) {
        const code = props.flight?.id?.slice(0, 2);
        if (code) {
          const url = store.getters.logoUriForCarrierCode(code);
          if (url) return require(`@/${url}.png`);
          return undefined;
        }
        return undefined;
      } else {
        return undefined;
      }
    });

    const departureCity = computed(() => unMaybe(props.flight?.departureCity));
    const departureAirport = computed(() =>
      unMaybe(props.flight?.departureAirport)
    );
    const arrivalCity = computed(() => unMaybe(props.flight?.arrivalCity));
    const arrivalAirport = computed(() =>
      unMaybe(props.flight?.arrivalAirport)
    );
    const departureDay = computed(() =>
      formatTimestamp(
        props.flight?.departureTime,
        departureCity.value?.timeZone,
        dayFormatting
      )
    );
    const arrivalDay = computed(() =>
      formatTimestamp(
        props.flight?.arrivalTime,
        arrivalCity.value?.timeZone,
        dayFormatting
      )
    );

    const departureTime = computed(() =>
      formatTimestamp(
        props.flight?.departureTime,
        departureCity.value?.timeZone,
        timeFormatting
      )
    );
    const arrivalTime = computed(() =>
      formatTimestamp(
        props.flight?.arrivalTime,
        arrivalCity.value?.timeZone,
        timeFormatting
      )
    );

    const departureTimeStr = computed(
      () => departureTime.value || departureDay.value || '-'
    );
    const arrivalTimeStr = computed(
      () => arrivalTime.value || arrivalDay.value || '-'
    );

    const departureDate = computed(() => {
      return timestampToDate(
        props.flight?.departureTime,
        unMaybe(departureCity.value?.timeZone)
      );
    });
    const arrivalDate = computed(() => {
      return timestampToDate(
        props.flight?.arrivalTime,
        unMaybe(arrivalCity.value?.timeZone)
      );
    });

    const diffDay = computed(() =>
      dateOffsetString(departureDate.value, arrivalDate.value)
    );

    const flightStr = computed(() =>
      props.flight?.flightNumber?.split('_').join(', ')
    );
    const stopsStr = computed(() =>
      legs.value?.length === 2 ? 'stop' : 'stops'
    );
    const layoverStr = computed(() =>
      legs.value
        .slice(0, -1)
        .map((leg: Leg) => leg.arrivalAirport?.code)
        .join(', ')
    );

    function toggleFlightDetails() {
      showFlightDetails.value = !showFlightDetails.value;
    }

    watch(selectedFlight, newVal => {
      emit('update:selectedFlight', newVal);
    });

    return {
      showFlightDetails,
      toggleFlightDetails,
      duration,
      legs,
      logoUrl,
      flightStr,
      stopsStr,
      layoverStr,
      departureTimeStr,
      departureTime,
      departureDay,
      departureCity,
      departureAirport,
      arrivalTimeStr,
      arrivalTime,
      arrivalDay,
      arrivalCity,
      arrivalAirport,
      selectedFlight,
      diffDay,
    };
  },
});
</script>
