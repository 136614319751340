<template>
  <font-awesome-layers>
    <font-awesome-icon
      class="text-pin text-ow-pin-origin-final"
      :icon="['fas', 'location-pin']"
    />
    <font-awesome-icon
      class="text-white text-pin-flag"
      transform="up-3 right-5"
      :icon="['fas', 'pennant']"
    />
  </font-awesome-layers>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
