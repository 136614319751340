<template>
  <div class="DateEdit" v-if="date !== undefined">
    <!--  All is well or in batching without request  -->
    <div
      v-if="
        (!isPhaseDatesBatching && !haveRequestMissMatch) ||
          (isPhaseDatesBatching && !haveDateRequest)
      "
    >
      {{ dateSentence }}
      <a-popover
        v-if="isDeparture || editArrivalDateUI"
        :class="{
          overridden: durationRequestOutOfSync,
        }"
        placement="bottomLeft"
        trigger="click"
      >
        <span class="pill">
          {{ formatDate(date) }}
          <font-awesome-icon :icon="['far', 'angle-down']" />
        </span>
        <template #content>
          <DateRequest
            :date="date"
            :segment="segment"
            :is-departure="false"
            :min-date="minDate"
            :max-date="maxDate"
            @update="updateRequest"
          />
        </template>
      </a-popover>
      <a-tooltip title="Remove request">
        <font-awesome-icon
          class="cursor-pointer ml-3"
          v-if="dateRequest !== undefined"
          :icon="['fal', 'xmark']"
          @click="removeRequest"
        />
      </a-tooltip>
    </div>

    <!--  Not in batching, but also have miss-matched request  -->
    <div v-else-if="!isPhaseDatesBatching && haveRequestMissMatch" class="">
      <div>{{ dateSentence }}</div>
      <div class="mt-2">
        You requested
        <a-popover class="z-2000" placement="bottomLeft" trigger="click">
          <span class="pill">
            {{ formatDate(dateRequest.date) }}
            <font-awesome-icon :icon="['far', 'angle-down']" />
          </span>
          <template #content>
            <DateRequest
              :date="date"
              :segment="segment"
              :is-departure="isDeparture"
              :min-date="minDate"
              :max-date="maxDate"
              @update="updateRequest"
            />
          </template>
        </a-popover>
        <a-tooltip title="Remove request">
          <font-awesome-icon
            class="cursor-pointer ml-3"
            :icon="['far', 'xmark']"
            @click="removeRequest"
          />
        </a-tooltip>
      </div>
    </div>

    <!--  In batching and have a request to display  -->
    <div v-else-if="isPhaseDatesBatching && dateRequest !== undefined" class="">
      You have asked for {{ isDeparture ? 'a departure' : 'an arrival' }} date
      of
      <a-popover class="z-2000" placement="bottomLeft" trigger="click">
        <span class="pill">
          {{ formatDate(dateRequest.date) }}
          <font-awesome-icon :icon="['far', 'angle-down']" />
        </span>
        <template #content>
          <DateRequest
            :date="dateRequest.date"
            :segment="segment"
            :is-departure="false"
            :min-date="minDate"
            :max-date="maxDate"
            @update="updateRequest"
          />
        </template>
      </a-popover>
      <a-tooltip title="Remove request">
        <font-awesome-icon
          class="cursor-pointer ml-3"
          :icon="['far', 'xmark']"
          @click="removeRequest"
        />
      </a-tooltip>
    </div>

    <!--  Otherwise is non-health state  -->
    <div v-else class="">
      {{ isDeparture ? 'Departure' : 'Arrival' }} date yet to be established.
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pill {
  @apply border border-ow-p-dark-blue rounded-full
  px-3 py-1
  cursor-pointer;
}
</style>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:DateEdit');

import { computed, defineComponent, PropType, toRefs } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { City, Concept, Formula, Segment } from '@/api/service';

import {
  arriveInCityOnDate,
  departFromCityOnDate,
  removeArrivalDatePreference,
  removeDepartureDatePreference,
} from '@/api/user-actions';
import { dateStr, dateFormat } from '@/format';
import {
  getUserRequestsArrivalDate,
  getUserRequestsDepartureDate,
  getUserRequestsExactDaysInVisit,
} from '@/api/kr';

import DateRequest from './DateRequest.vue';
import { differenceInCalendarDays } from 'date-fns';

export default defineComponent({
  components: {
    DateRequest,
  },
  props: {
    city: {
      type: Object as PropType<City>,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    segment: {
      type: Object as PropType<Segment>,
    },
    date: {
      type: Object as PropType<Date>,
    },
    minDate: {
      type: Date,
      required: true,
    },
    maxDate: {
      type: Date,
      required: true,
    },
    dateSentence: {
      type: String,
    },
    preferences: {
      type: Object as PropType<Formula[]>,
      required: true,
    },
    arrivalDate: {
      type: Object as PropType<Date>,
      required: false,
    },
    departureDate: {
      type: Object as PropType<Date>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { date, preferences } = toRefs(props);

    const editArrivalDateUI = computed(() =>
      store.state.ldClient?.variation('edit-arrival-date-ui', true)
    );
    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const isPhaseDatesBatching = computed(
      () => store.getters.isPhaseDatesBatching
    );
    const isDeparture = computed(
      () => props.segment?.departure?.code === props.city.code
    );
    const dateRequest = computed(() =>
      isDeparture.value
        ? getUserRequestsDepartureDate(preferences.value)
        : getUserRequestsArrivalDate(preferences.value)
    );

    const haveDateRequest = computed(() => dateRequest.value != undefined);
    const haveRequestMissMatch = computed(
      () =>
        dateRequest.value &&
        dateRequest.value.date.getTime() !== date.value?.getTime()
    );

    function updateRequest(newDate: Date) {
      let action;
      if (isDeparture.value) {
        action = departFromCityOnDate(props.city, props.visit, newDate);
      } else {
        action = arriveInCityOnDate(props.city, props.visit, newDate);
      }
      if (action !== undefined) {
        store.dispatch(ActionTypes.SendActions, [action]);
      } else {
        logger.error(
          'Failed to build action to update date request. newDate: ' + newDate
        );
      }
    }

    const durationExactRequest = computed(() =>
      getUserRequestsExactDaysInVisit(props.preferences)
    );

    const duration = computed(() => {
      const a = props.arrivalDate;
      const d = props.departureDate;
      if (a && d) {
        return differenceInCalendarDays(d, a);
      } else {
        return undefined;
      }
    });

    const durationRequestOutOfSync = computed(
      () =>
        duration.value !== undefined &&
        isPhaseDatesBatching.value &&
        durationExactRequest.value &&
        durationExactRequest.value.exactDays !== duration.value
    );

    function removeRequest() {
      if (dateRequest.value !== undefined) {
        let action;
        if (isDeparture.value) {
          action = removeDepartureDatePreference(
            props.city,
            dateRequest.value?._formula
          );
        } else {
          action = removeArrivalDatePreference(
            props.city,
            dateRequest.value?._formula
          );
        }
        if (action != undefined) {
          store.dispatch(ActionTypes.SendActions, [action]);
        } else {
          logger.error(
            'Failed to build action to remove date request: ' +
              JSON.stringify(dateRequest.value)
          );
        }
      }
    }

    function formatDate(date: Date) {
      return dateStr(date, dateFormat.day);
    }

    return {
      editArrivalDateUI,
      requireDialogResponse,
      isPhaseDatesBatching,
      isDeparture,
      dateRequest,
      haveDateRequest,
      updateRequest,
      removeRequest,
      haveRequestMissMatch,
      formatDate,
      durationRequestOutOfSync,
    };
  },
});
</script>
