<template>
  <a-modal
    width="80%"
    class="modal-top-bar max-w-128"
    :bodyStyle="{
      minHeight: '33rem',
      maxHeight: '75vh',
      overflowY: 'scroll',
    }"
    :visible="show"
    :centered="true"
    :closable="false"
    :maskCloseable="false"
  >
    <Welcome v-if="current === 0" />
    <Requirements v-else-if="current === 1" />
    <VirtualAgent v-else />
    <template #footer>
      <div class="grid grid-cols-3">
        <div class="items-center px-4 py-2">
          <button
            v-if="current > 0"
            class="btn secondary"
            @click="previousView"
          >
            Back
          </button>
        </div>
        <div class="flex justify-center items-center">
          <font-awesome-icon
            v-for="x in current"
            :icon="['fas', 'circle']"
            class="mx-2 text-neutral-500"
            :key="x"
          />
          <font-awesome-icon
            :icon="['fas', 'circle']"
            class="mx-2 text-ow-p-dark-blue"
          />
          <font-awesome-icon
            v-for="x in totalPages - 1 - current"
            :icon="['fas', 'circle']"
            class="mx-2 text-neutral-500"
            :key="x"
          />
        </div>
        <div class="flex justify-end items-center px-4 py-2">
          <button class="btn" @click="nextView">{{ nextText }}</button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<style lang="scss">
.onboarding-header {
  @apply pt-8 px-8 text-center;

  .title {
    @apply text-3xl leading-10 mb-3;
  }

  .subtitle {
    @apply text-lg break-words;
  }
}
</style>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useCookie } from 'vue-cookie-next';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';

import Welcome from './Welcome.vue';
import Requirements from './Requirements.vue';
import VirtualAgent from './VirtualAgent.vue';

const totalPages = 3;

export default defineComponent({
  components: {
    Welcome,
    Requirements,
    VirtualAgent,
  },
  setup() {
    const { setCookie, isCookieAvailable } = useCookie();
    const store = useStore();

    const current = ref(0);
    const show = ref(false);

    const nextText = computed(() =>
      current.value < totalPages - 1 ? 'Next' : 'Plan my trip'
    );

    onMounted(() => {
      const didOnboarding = isCookieAvailable('onboardingComplete');
      if (didOnboarding) {
        store.commit(MutationTypes.SetShowingOnboarding, false);
      } else {
        show.value = true;
        store.commit(MutationTypes.SetShowingOnboarding, true);
      }
    });

    function nextView() {
      if (current.value < totalPages - 1) {
        current.value++;
      } else {
        show.value = false;
        setCookie('onboardingComplete', 'true');
        store.commit(MutationTypes.SetShowingOnboarding, false);

        // The below seems to show up before the modal hides, so just avoid doing it
        // nextTick(() => (current.value = 0));
      }
    }

    function previousView() {
      if (current.value > 0) {
        current.value--;
      }
    }

    return {
      totalPages,
      current,
      show,
      nextText,
      nextView,
      previousView,
    };
  },
});
</script>
