<template>
  <div>
    <div v-for="(leg, legIdx) in legs" :key="legIdx">
      <div
        v-if="legIdx > 0"
        class="flex flex-row items-center p-2 px-6 my-2 bg-ow-p-light-blue rounded-lg"
      >
        <span>Layover: {{ layoverDuration(legs[legIdx - 1], leg) }}</span>
        <font-awesome-icon class="mx-1" :icon="['far', 'at']" />
        <span>
          {{ departureAirport(leg).name }} ({{ departureAirport(leg).code }})
        </span>
        <Tag
          class="ml-3"
          v-if="layoverDayDiffStr(legs[legIdx - 1], leg) !== undefined"
          :name="layoverDayDiffStr(legs[legIdx - 1], leg)"
          color="ow-s-ruby"
        />
      </div>
      <font-awesome-icon
        class="relative bg-white top -left-2 top-5 text-gray-400"
        :icon="['far', 'circle']"
      />

      <!--  departure section  -->
      <div class="border-l-2 border-gray-400 px-6 grid grid-cols-12 col-span-4">
        <div class="col-span-1 flex flex-col items-center">
          <div class="font-bold whitespace-nowrap">
            {{ departureDayMonth(leg) || '-' }}
          </div>
          <div class="whitespace-nowrap">
            {{ departureTime(leg) || '-' }}
          </div>
        </div>
        <div class="col-span-1" />
        <div class="col-span-7">
          <div class="mb-4">
            <span class="mr-2 font-bold"
              >{{ legDepartureCity(leg).name }},</span
            >
            <span class=""
              >{{ departureAirport(leg).name }} ({{
                departureAirport(leg).code
              }})</span
            >
            <!-- tooltip when departure airport is different than previous arrival airport -->
            <a-tooltip placement="bottom" v-if="diffAirport(legIdx)">
              <template #title>
                <div class="text-sm">
                  Your flight departs from a different airport than the airport
                  you arrived in. You may find it more convenient to find
                  alternative flights that use the same airport. Please note
                  that you must transport your baggage between arrival and
                  departure airports. Click to see some examples to help you
                  through the dialog
                </div>
              </template>
              <span class="ml-2 p-1.5 bg-ow-s-gold rounded text-xs">
                <font-awesome-icon :icon="['fa-light', 'plane-departure']" />
                Airport Change
              </span>
            </a-tooltip>
          </div>
        </div>
        <div class="col-span-3 flex justify-end -mb-4">
          <img :src="legCarrierLogoUrl(leg)" class="h-14" alt="logo" />
        </div>
      </div>

      <!--  Mid content section  -->
      <div class="border-l-2 border-gray-400 px-6 grid grid-cols-12 col-span-4">
        <div class="col-span-1 flex flex-col justify-end items-center">
          <div class="mb-1" v-if="diffDay(leg)">
            <Tag :name="diffDay(leg)" color="ow-s-ruby" />
          </div>
        </div>
        <div class="col-span-1" />
        <div class="col-span-7">
          <div class="">
            <div>Flight Duration: {{ legDuration(leg) }}</div>
            <div class="font-normal">
              Class:
              <a-tooltip :title="legClassMismatchStr(leg)">
                <span
                  class="capitalize"
                  :class="{
                    'text-ow-s-ruby': isLegClassMismatch(leg),
                    'text-bold': isLegClassMismatch(leg),
                  }"
                >
                  {{ legClass(leg) }}
                  <font-awesome-icon
                    class="ml-1"
                    v-if="isLegClassMismatch(leg)"
                    :icon="['fas', 'triangle-exclamation']"
                  />
                </span>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>

      <!--  Arrival section  -->
      <div class="border-l-2 border-gray-400 px-6 grid grid-cols-12 ">
        <div class="col-span-1 flex flex-col justify-end items-center">
          <div class="font-bold whitespace-nowrap">
            {{ arrivalDayMonth(leg) || '-' }}
          </div>
          <div class="whitespace-nowrap">{{ arrivalTime(leg) || '-' }}</div>
        </div>
        <div class="col-span-1" />
        <div class="col-span-6 flex items-end">
          <div class="mt-3">
            <span class="mr-2 font-bold">{{ legArrivalCity(leg).name }},</span>
            <span class="font-normal">
              {{ arrivalAirport(leg).name }} ({{ arrivalAirport(leg).code }})
            </span>
          </div>
        </div>
        <div class="col-span-4 flex justify-end items-end">
          <span
            >Flight Number <strong>{{ leg.flightNumber }}</strong></span
          >
        </div>
      </div>
      <font-awesome-icon
        v-if="legIdx === legs.length - 1"
        class="relative -left-2 -top-4 text-gray-400"
        :icon="['fas', 'circle']"
      />
      <font-awesome-icon
        v-else
        class="relative bg-white top -left-2 -top-5 text-gray-400"
        :icon="['far', 'circle']"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { useStore } from '@/store';
import {
  Flight,
  minutesToDurationFormatter,
  unMaybe,
  unMaybeArray,
  Leg,
  formatTimestamp,
  dayFormatting,
  timeFormatting,
  dateOffsetString,
  dayFormattingShortest,
  arrivalDateFlight,
  departureDateFlight,
  arrivalDateLeg,
  departureDateLeg,
} from '@/api/service';
import { GoogleProtobufTimestamp } from '@ec/rtw-graphql';
import { capitalize } from 'lodash-es';
import Tag from '@/components/common/misc/Tag.vue';

export default defineComponent({
  props: {
    flight: Object as PropType<Flight>,
    previousFlight: Object as PropType<Flight>,
  },
  components: { Tag },
  setup(props) {
    const store = useStore();
    const duration = computed(() =>
      minutesToDurationFormatter(unMaybe(props.flight?.elapsedTime))
    );

    const legs = computed<Leg[]>(() => unMaybeArray(props.flight?.legs));

    function departureAirport(leg: Leg) {
      return unMaybe(leg.departureAirport);
    }
    function arrivalAirport(leg: Leg) {
      return unMaybe(leg.arrivalAirport);
    }
    function arrivalCity(leg: Leg) {
      return unMaybe(leg.arrivalCity);
    }
    function legDepartureCity(leg: Leg) {
      return unMaybe(leg.departureCity);
    }
    function legArrivalCity(leg: Leg) {
      return unMaybe(leg.arrivalCity);
    }

    function departureDay(leg: Leg) {
      return formatTimestamp(
        leg.departureTime,
        legDepartureCity(leg)?.timeZone,
        dayFormatting
      );
    }
    function arrivalDay(leg: Leg) {
      return formatTimestamp(
        leg.arrivalTime,
        arrivalCity(leg)?.timeZone,
        dayFormatting
      );
    }

    function departureTime(leg: Leg) {
      return formatTimestamp(
        leg.departureTime,
        legDepartureCity(leg)?.timeZone,
        timeFormatting
      );
    }

    function departureDayMonth(leg: Leg) {
      return formatTimestamp(
        leg.departureTime,
        legDepartureCity(leg)?.timeZone,
        dayFormattingShortest
      );
    }

    function arrivalTime(leg: Leg) {
      return formatTimestamp(
        leg.arrivalTime,
        arrivalCity(leg)?.timeZone,
        timeFormatting
      );
    }

    function arrivalDayMonth(leg: Leg) {
      return formatTimestamp(
        leg.arrivalTime,
        arrivalCity(leg)?.timeZone,
        dayFormattingShortest
      );
    }

    function diffDay(flight: Flight) {
      return dateOffsetString(
        departureDateFlight(flight),
        arrivalDateFlight(flight)
      );
    }

    function diffAirport(legIdx: number) {
      if (legs.value.length > 0 && props.previousFlight) {
        const prevAirport =
          legIdx === 0
            ? props.previousFlight?.arrivalAirport
            : arrivalAirport(legs.value[legIdx - 1]);
        const currentLeg = legs.value[legIdx];
        return departureAirport(currentLeg)?.code !== prevAirport?.code;
      }
      return false;
    }

    function legDuration(leg: Leg) {
      return minutesToDurationFormatter(unMaybe(leg.flightMinutes)?.toString());
    }

    function legClass(leg: Leg): string {
      if (leg.userClass?.ubCabinClass != null) {
        return capitalize(leg.userClass?.ubCabinClass.toLowerCase());
      } else if (leg.userClass?.userSeatClass != null) {
        return capitalize(leg.userClass?.userSeatClass?.toLowerCase());
      } else {
        return 'not set';
      }
    }

    function legCarrierLogoUrl(leg: Leg): string | undefined {
      if (props.flight) {
        const code = leg.carrier?.code;
        if (code) {
          const url = store.getters.logoUriForCarrierCode(code);
          if (url) return require(`@/${url}.png`);
          return undefined;
        }
        return undefined;
      } else {
        return undefined;
      }
    }

    function layoverDayDiffStr(
      legPrevious: Leg,
      legNext: Leg
    ): string | undefined {
      return dateOffsetString(
        arrivalDateLeg(legPrevious),
        departureDateLeg(legNext)
      );
    }

    function isLegClassMismatch(leg: Leg) {
      if (leg.userClass?.ubCabinClass != null) {
        return (
          leg.userClass?.userSeatClass?.toLowerCase() !=
          leg.userClass?.ubCabinClass?.toLowerCase()
        );
      } else {
        return false;
      }
    }

    function legClassMismatchStr(leg: Leg) {
      if (isLegClassMismatch(leg)) {
        return (
          'Unfortunately, ' +
          capitalize(leg.userClass?.userSeatClass?.toLowerCase()) +
          ' class is not offered, or is unavailable, for this flight.'
        );
      } else {
        return '';
      }
    }

    function layoverDuration(firstLeg: Leg, secondLeg: Leg) {
      const beginTime: GoogleProtobufTimestamp | undefined = unMaybe(
        firstLeg?.arrivalTime
      );
      const endTime: GoogleProtobufTimestamp | undefined = unMaybe(
        secondLeg?.departureTime
      );

      if (beginTime?.seconds && endTime?.seconds) {
        const durationInSeconds = Number(endTime.seconds - beginTime.seconds);
        const durationInMinutes: number = durationInSeconds / 60;
        return minutesToDurationFormatter(durationInMinutes.toString());
      }
    }

    return {
      duration,
      legs,
      legCarrierLogoUrl,
      legDuration,
      legClass,
      isLegClassMismatch,
      legClassMismatchStr,
      layoverDuration,
      layoverDayDiffStr,
      departureAirport,
      departureDay,
      departureTime,
      departureDayMonth,
      legDepartureCity,
      arrivalAirport,
      arrivalDay,
      arrivalTime,
      arrivalDayMonth,
      legArrivalCity,
      diffDay,
      diffAirport,
    };
  },
});
</script>
