<template>
  <SystemMessage
    v-if="isSystemMessage(message)"
    :message="message"
    :isLastRequest="isLastRequestSystemMessage"
    @send="send"
  />
  <UserMessage
    v-else
    :message="message"
    @send="send"
    :isLastUserMessage="isLastUserMessage"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import {
  SystemMessage as SystemMessageType,
  UserMessage as UserMessageType,
  isSystemMessage,
} from '@/api/service';

import SystemMessage from './SystemMessage.vue';
import UserMessage from './UserMessage.vue';

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<SystemMessageType | UserMessageType>,
      required: true,
    },
    isLastRequestSystemMessage: {
      type: Boolean,
      required: true,
    },
    isLastUserMessage: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SystemMessage,
    UserMessage,
  },
  setup() {
    function send() {
      // TODO: for some reason this layer is not required
      // emit("send", value);
    }

    return {
      send,
      isSystemMessage,
    };
  },
});
</script>
