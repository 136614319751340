<template>
  <a-modal
    id="ItineraryListModal"
    class="modal-basic modal-top-bar z-2000"
    wrapClassName="itineraryListWrap"
    centered
    :visible="showItineraryList"
    :destroyOnClose="true"
    :footer="null"
    @ok="hideModal"
    @cancel="hideModal"
  >
    <div class="my-8">
      <div class="mx-24 text-ow-p-dark-blue text-xl mb-4">
        My Itineraries
      </div>
      <div
        class="itineraryList max-h-96 overflow-y-scroll"
        v-if="itineraryList.length > 0"
      >
        <div v-for="itinerary in itineraryList" :key="itinerary.itineraryName">
          <ItineraryListItem :itinerary="itinerary" class="mx-12" />
        </div>
      </div>
      <div v-else-if="loading" class="mx-24 text-neutral-700 mb-24">
        <div class="mb-4">
          Loading ...
        </div>
      </div>
      <div v-else class="mx-24 text-neutral-700 mb-24">
        <div class="mb-4">
          You don't have any saved itineraries. When you save new itineraries,
          they will appear here.
        </div>
        <div>You can save new itineraries through your profile menu.</div>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import ItineraryListItem from './ItineraryListItem.vue';
import { MutationTypes } from '@/store/mutations';

export default defineComponent({
  components: {
    ItineraryListItem,
  },
  setup() {
    const store = useStore();

    const loading = ref(true);

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const showItineraryList = computed(() => store.state.showItineraryList);
    const itineraryList = computed(() => store.state.itineraryList);

    function toggleShowModal() {
      if (!requireDialogResponse.value) {
        store.commit(
          MutationTypes.SetShowItineraryList,
          !showItineraryList.value
        );
      }
    }
    function hideModal() {
      store.commit(MutationTypes.SetShowItineraryList, false);
    }
    function handleClickChange(visible: boolean) {
      if (!requireDialogResponse.value) {
        store.commit(MutationTypes.SetShowItineraryList, visible);
      }
    }

    async function load() {
      if (itineraryList.value.length == 0) {
        loading.value = true;
      }
      await store.dispatch(ActionTypes.GetItineraryList);
      loading.value = false;
    }

    watch(showItineraryList, current => {
      if (current) {
        load();
      }
    });

    return {
      loading,
      showItineraryList,
      hideModal,
      toggleShowModal,
      handleClickChange,
      requireDialogResponse,
      itineraryList,
    };
  },
});
</script>
