<template>
  <div v-if="currentTip && show">
    <Teleport to="body">
      <Tooltip
        :title="currentTip.title"
        :content="currentTip.content"
        :anchor="currentTip.anchor"
        @update:hide="nextToolTip"
        @update:dismissAll="dismissAllTips"
      />
    </Teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { useCookie } from 'vue-cookie-next';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { Tooltip as TooltipType } from '@/api/service';
import Tooltip from '../misc/Tooltip.vue';

export default defineComponent({
  components: {
    Tooltip,
  },
  setup() {
    const store = useStore();
    const { setCookie, isCookieAvailable } = useCookie();

    const completedTipIds = ref<string[]>([]);
    const validTips = ref<TooltipType[]>([]);
    const currentTip = ref<TooltipType>();
    const show = ref(true);

    const showingOboarding = computed(() => store.state.showingOnboarding);
    const allTooltips = computed(() => store.state.allTooltips);
    const matchingTooltips = computed(() => store.state.matchingTooltips);

    onMounted(() => {
      store.dispatch(ActionTypes.GetToolTips);
      const didTooltips = isCookieAvailable('tooltipsComplete');
      const hideTooltips = isCookieAvailable('hideTooltips');
      if (didTooltips || hideTooltips || showingOboarding.value) {
        show.value = false;
      }
    });

    function nextToolTip() {
      if (currentTip.value) {
        completedTipIds.value.push(currentTip.value.id);
        currentTip.value = undefined;
        validTips.value = validTips.value.slice(1);
        if (
          allTooltips.value &&
          completedTipIds.value.length === allTooltips.value.length - 1
        ) {
          setCookie('tooltipsComplete', 'true');
        }
      }
      if (validTips.value && validTips.value.length > 0) {
        currentTip.value = validTips.value[0];
      }
    }

    function dismissAllTips() {
      setCookie('hideTooltips', 'true');
      show.value = false;
    }

    watch(showingOboarding, newShowingValue => {
      if (newShowingValue === false && currentTip.value) {
        show.value = true;
      }
    });

    watch(matchingTooltips, newTooltips => {
      const unusedMatching: TooltipType[] | undefined = newTooltips?.filter(
        (t: TooltipType) => completedTipIds.value.indexOf(t.id) === -1
      );
      if (unusedMatching) {
        unusedMatching.forEach((t: TooltipType) => {
          if (!validTips.value.find((x: TooltipType) => x.id === t.id)) {
            validTips.value.push(t);
          }
        });
        if (!currentTip.value) {
          nextToolTip();
        }
      }
    });

    return {
      show,
      currentTip,
      nextToolTip,
      dismissAllTips,
    };
  },
});
</script>
