<template>
  <div
    class="InputBox flex flex-col min-h-12 flex-shrink-0"
    v-click-outside="hideExtras"
  >
    <!-- Removed for now: InputBoxAutocomplete /-->
    <div
      class="flex-grow flex flex-col border-neutral-400"
      :class="{
        'border-t': !showCalendarModal,
      }"
    >
      <!-- Removed due to not clean operation RTW-3879 <slide-up-down v-model="showMultiChoice">-->
      <InputBoxMultiChoice
        v-show="showMultiChoice"
        v-model:submittable="multiChoiceSubmittable"
        @submit="submit"
      />
      <!-- </slide-up-down>-->
      <InputBoxCalendar v-show="showCalendarModal" @hide="hideCalendar" />
      <div class="flex-grow flex content-center items-end px-4 py-4">
        <InputBoxText class="flex-grow" ref="inputBoxText" @submit="submit" />

        <!-- Calendar assist -->
        <font-awesome-icon
          :icon="['fal', 'calendar']"
          class="ml-3 mb-1 text-2xl text-ow-p-dark-blue opacity-50 hover:opacity-100 cursor-pointer"
          @click="toggleCalendarModal"
          v-if="showCalendarIcon"
        />

        <!-- Submit -->
        <font-awesome-icon
          class="ml-3 mb-1 text-2xl text-ow-p-dark-blue"
          :class="{
            'opacity-30': !submittable,
            'cursor-pointer': submittable,
            'cursor-not-allowed': !submittable,
          }"
          :disabled="!submittable"
          v-if="haveBot"
          :icon="['fal', 'paper-plane-top']"
          @click.stop="submit()"
        />
        <font-awesome-icon
          v-else
          class="ml-2 mb-1 text-2xl text-ow-p-dark-blue fa-spin"
          :icon="['fal', 'spinner-third']"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Logger from '@/logger';
import { computed, defineComponent, nextTick, ref, watch } from 'vue';
import { useStore } from '@/store';
import LogRocket from 'logrocket';
import { ActionTypes } from '@/store/actions';
import {
  DialogStatusType,
  Formula,
  isMultiChoice,
  UserAction,
  UserTextChoice,
} from '@/api/service';
import { MutationTypes } from '@/store/mutations';

// import InputBoxAutocomplete from './InputBoxAutocomplete.vue';
import InputBoxMultiChoice from './InputBoxMultiChoice.vue';
import InputBoxText from './InputBoxText.vue';
import InputBoxCalendar from './InputBoxCalendar.vue';

const logger = new Logger('rtw:InputBox');

export default defineComponent({
  components: {
    // InputBoxAutocomplete,
    InputBoxMultiChoice,
    InputBoxText,
    InputBoxCalendar,
  },
  setup() {
    const store = useStore();

    const inputBoxText = ref<typeof InputBoxText | null>(null);
    const lastRequestSystemMessage = computed(
      () => store.getters.lastRequestSystemMessage
    );
    const systemMessagesSinceLastUserMessage = computed(
      () => store.getters.systemMessagesSinceLastUserMessage
    );
    const haveBot = computed(() => store.state.dialog.bot !== undefined);
    const waitingOnDialog = computed(
      () => store.state.dialog.waitingOnDialogUpdateAfterSend
    );
    const haveActiveRequestSystemMessage = computed(
      () => store.getters.haveActiveRequestSystemMessage
    );
    const status = computed(() => store.state.dialog?.status);

    const idleStatus = computed(
      () => status.value?.type == DialogStatusType.StatusTypeIdle
    );

    //
    // Text
    const text = computed(() => store.state.dialog.inputBox.text);
    const haveText = computed(() => text.value.trim() !== '');
    const disableTextEntry = computed(() => store.getters.disabledTextEntry);

    //
    // Multi-choice
    const showMultiChoice = ref(false);
    const multiChoiceSubmittable = ref(false);
    const haveMultiChoice = computed(() =>
      isMultiChoice(lastRequestSystemMessage.value)
    );
    const shouldShowMultiChoice = computed(
      () =>
        haveMultiChoice.value &&
        systemMessagesSinceLastUserMessage.value.length > 0 &&
        !waitingOnDialog.value
    );
    const selectedChoices = computed(
      () => store.state.dialog.inputBox.selectedChoices
    );
    const selectedActionResponses = computed(() =>
      selectedChoices.value
        .filter(c => c.action != null)
        .map(c => c.action)
        .filter((a): a is UserAction => a != null)
    );
    const selectedTextChoiceResponses = computed(() =>
      selectedChoices.value
        .filter(c => c.textChoice != null)
        .map(c => c.textChoice)
        .filter((c): c is UserTextChoice => c != null)
    );
    watch(shouldShowMultiChoice, value =>
      nextTick(() => (showMultiChoice.value = value))
    );

    //
    // Submit
    const submittable = computed(() => {
      if (waitingOnDialog.value) {
        return false;
      } else if (!haveActiveRequestSystemMessage.value) {
        return false;
      } else if (!idleStatus.value) {
        return false;
      } else if (!disableTextEntry.value && haveText.value) {
        return true;
      } else if (isMultiChoice(lastRequestSystemMessage.value)) {
        return multiChoiceSubmittable.value;
      } else {
        return false;
      }
    });

    async function submitText() {
      if (!submittable.value) {
        logger.error('.submit: Trying to send but not submittable.');
        return;
      }
      await store.dispatch(ActionTypes.SendText, text.value);
    }

    async function sleep(millis: number) {
      return new Promise(resolve => setTimeout(resolve, millis));
    }

    async function submitChoices() {
      if (!submittable.value) {
        logger.error('.submit: Trying to send but not submittable.');
        return;
      }

      // Cannot submit a mix of types ... double check
      if (
        selectedActionResponses.value.length > 0 &&
        selectedTextChoiceResponses.value.length > 0
      ) {
        logger.error(
          'Have a mix of action and text responses to multi-choice. Not allowed.'
        );
        return;
      }

      // Action options
      if (selectedActionResponses.value.length > 0) {
        await store.dispatch(
          ActionTypes.SendActions,
          selectedActionResponses.value
        );
        logger.debug(
          `Sending actions ${JSON.stringify(selectedActionResponses.value)}`
        );
      }

      // Text choice options
      if (selectedTextChoiceResponses.value.length > 0) {
        await store.dispatch(
          ActionTypes.SendTextChoices,
          selectedTextChoiceResponses.value
        );
        logger.debug(
          `Sending text choices ${JSON.stringify(
            selectedTextChoiceResponses.value
          )}`
        );
      }

      // Deal with formula
      const onSubmit: Formula[] = selectedChoices.value
        .map(c => c?.onSubmit)
        .filter((c): c is Formula => !!c);

      if (onSubmit && onSubmit.length > 0) {
        const properties = onSubmit[0].propertyValues;
        const property = properties?.find(p => p?.property == 'url');
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('refId', store.state.sessionId || 'unknown');
        if (property) {
          const url =
            property?.value?.entity?.literal?.s +
            '&target=' +
            encodeURIComponent(
              location.protocol +
                '//' +
                location.host +
                location.pathname +
                '?' +
                searchParams.toString()
            );
          if (url) {
            LogRocket.track('revenue');
            await sleep(1000);
            const urlBack = new URL(window.location.href);
            urlBack.searchParams.set('refId', store.state.sessionId || '');
            window.history.pushState({}, '', urlBack.toString());
            window.location.href = url;
          }
        }
      }

      // Clear out choices
      store.commit(MutationTypes.ClearSelectedChoices);
    }

    async function submit() {
      if (!submittable.value) {
        logger.error('.submit: Trying to send but not submittable.');
        return;
      }

      // Cannot submit selected choices and text
      if (text.value.length > 0 && selectedChoices.value.length > 0) {
        logger.error('.submit: Trying to submit text and choices.');
        return;
      } else if (text.value.length > 0) {
        await submitText();
      } else if (selectedChoices.value.length > 0) {
        await submitChoices();
      } else {
        logger.error('.submit: Trying to submit without text or choices');
        return;
      }

      // After submit, force focus back to text box
      if (inputBoxText.value != null) {
        inputBoxText.value.setFocus();
      }
    }

    // Currently turned off
    // async function getCompletions() {
    //   store.dispatch(ActionTypes.GetCompletions, text.value);
    // }
    // const debouncedGetSubstitutions = debounce(getCompletions, 300);
    // watch(text, () => debouncedGetSubstitutions());

    const showCalendarIcon = computed(
      () =>
        store.getters.lastSystemMessageExpectsDate ||
        store.getters.lastSystemMessageExpectsDateRange
    );
    const showCalendarModal = ref(false);
    function toggleCalendarModal() {
      showCalendarModal.value = !showCalendarModal.value;
    }

    function hideCalendar() {
      showCalendarModal.value = false;
    }

    function hideExtras() {
      hideCalendar();
    }

    return {
      inputBoxText,
      lastRequestSystemMessage,
      systemMessagesSinceLastUserMessage,
      text,
      waitingOnDialog,
      haveBot,
      submittable,
      haveText,
      hideExtras,
      submit,
      showMultiChoice,
      haveMultiChoice,
      multiChoiceSubmittable,
      showCalendarIcon,
      toggleCalendarModal,
      showCalendarModal,
      hideCalendar,
    };
  },
});
</script>
