<template>
  <!-- Draggable interims -->
  <Draggable
    class="list-group"
    handle=".cursor-move"
    :component-data="{
      tag: 'ul',
      name: 'itinerary-list',
      type: 'transition',
    }"
    :list="list"
    item-key="key"
    :disabled="disabled"
    ghost-class="ghost"
    drag-class="sortable-drag"
    @start="startDrag"
    @end="endDrag"
  >
    <template #item="{ element }">
      <Interim
        :key="element.key"
        :index="element.interimIndex"
        :interim="element.interim"
        :segment="element.segment"
        :previousSegment="element.previousSegment"
        :draggable="draggable"
        :showSegments="showSegments"
      />
    </template>
  </Draggable>
</template>

<style lang="scss" scoped>
.ghost {
  @apply opacity-50;
}

.sortable-drag,
.sortable-chosen {
  .dragBlur {
    filter: blur(2px);
  }
}
</style>

<script lang="ts">
import Logger from '@/logger';
const logger = new Logger('rtw:Interims');

import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import Draggable from 'vuedraggable';

import {
  Interim as InterimType,
  Segment as SegmentType,
  UserAction,
} from '@/api/service';
import { ActionTypes } from '@/store/actions';

import Interim from './Interim.vue';
import {
  conceptToString,
  formulaBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

interface DraggableInterim {
  key: string;
  interimIndex: number;
  interim: InterimType;
  segment: SegmentType;
  previousSegment: SegmentType | undefined;
}

export default defineComponent({
  props: {
    showSegments: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Draggable,
    Interim,
  },
  setup() {
    const store = useStore();

    const isDragging = ref(false);
    // If we have finished an ordering and sent an update. reset when we get a new update
    const processing = ref(false);
    // Local list which is draggable
    const list = ref<DraggableInterim[]>([]);

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const isPhaseCitiesBatching = computed(
      () => store.getters.isPhaseCitiesBatching
    );
    const disabledSend = computed(() => store.getters.disabledSend);
    const haveOrigin = computed(() => store.getters.haveOrigin);
    const interims = computed<InterimType[]>(() => store.getters.interims);
    const segments = computed<SegmentType[]>(() => store.getters.segments);
    const dragNDropEnabled = computed(() =>
      store.state.ldClient?.variation('drag-n-drop', false)
    );
    const disabled = computed(
      () =>
        requireDialogResponse.value ||
        disabledSend.value ||
        !dragNDropEnabled.value ||
        processing.value ||
        isPhaseCitiesBatching.value
    );

    // NOTE: Able to be dragged at some point --- can be disabled
    const draggable = computed(
      () =>
        haveOrigin.value && interims.value.length > 1 && dragNDropEnabled.value
    );

    // Update the local list when system list changes
    function updateList() {
      // Technically processing, although should be fast
      processing.value = true;
      // logger.debug('.updateList: ');
      list.value = [];

      interims.value.forEach((interim: InterimType, index: number) => {
        const key = conceptToString(interim.visit);
        const segment = store.getters.segmentForInterim(interim);
        const previousSegment =
          index > 0
            ? store.getters.segmentForInterim(interims.value[index - 1])
            : store.getters.firstSegment;
        if (!key || !segment) {
          logger.error('.updateList: could not find corresponding segment.');
          logger.error(interim);
          logger.error(segments);
          return;
        }
        // Add entry
        list.value.push({
          key,
          // Bumps up the list if we have an origin
          interimIndex: index + (store.getters.originCity ? 1 : 0),
          interim,
          segment,
          previousSegment,
        });
      });
      // Reset processing
      processing.value = false;
    }

    function startDrag() {
      isDragging.value = true;
    }

    // Build after or before action
    function buildPutAction(
      moved: InterimType,
      reference: InterimType,
      actionText: string,
      actionPredicate: string
    ): UserAction | undefined {
      // Validate data
      if (
        reference.city?.code != null &&
        moved.city?.code != null &&
        reference.visit != null &&
        moved.visit != null
      ) {
        return sendActionBuilder(
          `Move ${moved.city.name} ${actionText} ${reference.city.name}`,
          formulaBuilder('rtwAction/' + actionPredicate, [
            propertyValueBuilder('reference', reference.visit),
            propertyValueBuilder('cities', moved.visit),
          ])
        );
      } else {
        return undefined;
      }
    }

    const endDrag = ({
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    }) => {
      processing.value = true;
      isDragging.value = false;
      const moved = interims.value[oldIndex];
      const bumped = interims.value[newIndex];

      let action: UserAction | undefined;
      if (newIndex > oldIndex) {
        action = buildPutAction(
          moved,
          bumped,
          'after',
          'putInItineraryAfterReference'
        );
      } else if (newIndex < oldIndex) {
        action = buildPutAction(
          moved,
          bumped,
          'before',
          'putInItineraryBeforeReference'
        );
      } else {
        // Did not move - not sure how often this happens
        // Processing set back to false because we are not sending an action
        processing.value = false;
        logger.debug('.endDrag: index did not change.');
        return;
      }

      if (action) {
        store.dispatch(ActionTypes.SendActions, [action]);
        logger.debug(action);
      } else {
        logger.error('.endDrag: could not build action.');
        logger.error(moved);
        logger.error(bumped);
      }
    };

    onMounted(() => updateList());
    watch(interims, () => updateList());

    return {
      list,
      disabled,
      draggable,
      startDrag,
      endDrag,
    };
  },
});
</script>
