<template>
  <div
    class="Filters flex flex-row overflow-y-visible overflow-x-hidden items-center"
  >
    <div class="mr-4 whitespace-nowrap">Filters</div>
    <Scroller class="flex-grow flex flex-row" :small="true" :step="200">
      <!-- Airlines -->
      <Filter
        header="Airlines"
        :valStr="checkedAirlinesStr"
        :isActive="filters.checkedAirlines.length > 0"
        :scrollXPos="scrollXPos"
      >
        <template #options>
          <ul v-for="airline in Object.keys(airlines).sort()" :key="airline">
            <li>
              <input
                :id="airline"
                v-model="filters.checkedAirlines"
                :value="airline"
                class="my-auto"
                type="checkbox"
              />&nbsp;
              <label :for="airline" class="my-auto">
                {{ airline }}
              </label>
            </li>
          </ul>
        </template>
      </Filter>

      <!-- Stops -->
      <Filter
        header="Stops"
        :valStr="stopsStr"
        :scrollXPos="scrollXPos"
        :isActive="filters.checkedStops.length > 0"
      >
        <template v-slot:options>
          <ul v-for="stop in Object.keys(stops).sort()" :key="stop">
            <li>
              <input
                type="checkbox"
                class="my-auto"
                :id="stop"
                :value="stop"
                v-model="filters.checkedStops"
              />&nbsp;
              <label :for="stop" class="my-auto">
                {{ stopsText(stop) }}
              </label>
            </li>
          </ul>
        </template>
      </Filter>

      <!-- Departure Time -->
      <Filter
        header="Departure Time"
        :valStr="departureTimeStr"
        :isActive="filters.checkedDepartureTimes.length > 0"
        :scrollXPos="scrollXPos"
      >
        <template v-slot:options>
          <ul
            v-for="departureTime in Object.keys(departureTimes).sort(
              sortFlightTimes
            )"
            :key="departureTime"
          >
            <li>
              <input
                type="checkbox"
                class="my-auto"
                :id="`departureTime-${departureTime}`"
                :value="departureTime"
                v-model="filters.checkedDepartureTimes"
              />&nbsp;
              <label :for="`departureTime-${departureTime}`" class="my-auto">
                {{ departureTime }}
              </label>
            </li>
          </ul>
        </template>
      </Filter>

      <!-- Arrival Time -->
      <Filter
        header="Arrival Time"
        :valStr="arrivalTimeStr"
        :scrollXPos="scrollXPos"
        :isActive="filters.checkedArrivalTimes.length > 0"
      >
        <template v-slot:options>
          <ul
            v-for="arrivalTime in Object.keys(arrivalTimes).sort(
              sortFlightTimes
            )"
            :key="arrivalTime"
          >
            <li>
              <input
                type="checkbox"
                :id="`arrivalTime-${arrivalTime}`"
                :value="arrivalTime"
                v-model="filters.checkedArrivalTimes"
              />&nbsp;
              <label :for="`arrivalTime-${arrivalTime}`">
                {{ arrivalTime }}
              </label>
            </li>
          </ul>
        </template>
      </Filter>

      <!-- Duration -->
      <Filter
        header="Duration"
        :valStr="maxDurationStr"
        :scrollXPos="scrollXPos"
        v-if="duration.min && duration.max"
        :isActive="maxDurationActive"
      >
        <template v-slot:options>
          <input
            class="duration-input my-autos"
            type="range"
            :min="duration.min"
            :max="duration.max"
            v-model="filters.maxDuration"
          />
          <div class="duration-text my-auto flex justify-between">
            <div>{{ formatDuration(duration.min) }}</div>
            <div>{{ formatDuration(duration.max) }}</div>
          </div>
        </template>
      </Filter>

      <!-- Airport -->
      <Filter
        header="Airport"
        :valStr="checkedAirportsStr"
        :scrollXPos="scrollXPos"
        :isActive="
          filters.checkedDepartureAirports.length > 0 ||
            filters.checkedArrivalAirports.length > 0
        "
      >
        <template v-slot:options>
          <div class="mt-1">From</div>
          <ul
            v-for="airport in Object.keys(departureAirports).sort()"
            :key="airport"
          >
            <li>
              <input
                type="checkbox"
                class="my-auto"
                :id="airport"
                :value="airport"
                v-model="filters.checkedDepartureAirports"
              />&nbsp;
              <label :for="airport" class="my-auto">
                {{ airport }}
              </label>
            </li>
          </ul>

          <div class="mt-1">To</div>
          <ul
            v-for="airport in Object.keys(arrivalAirports).sort()"
            :key="airport"
          >
            <li>
              <input
                type="checkbox"
                class="my-auto"
                :id="airport"
                :value="airport"
                v-model="filters.checkedArrivalAirports"
              />&nbsp;
              <label :for="airport" class="my-auto">
                {{ airport }}
              </label>
            </li>
          </ul>
        </template>
      </Filter>

      <!-- Layover -->
      <Filter
        header="Layover"
        :valStr="layoverStr"
        :scrollXPos="scrollXPos"
        :isActive="filters.checkedLayoverAirports.length > 0"
        v-if="layoverDuration.min && layoverDuration.max"
      >
        <template v-slot:options>
          <div class="mt-1">Airport</div>
          <ul
            v-for="airport in Object.keys(layoverAirports).sort()"
            :key="airport"
          >
            <li>
              <input
                type="checkbox"
                class="my-auto"
                :id="airport"
                :value="airport"
                v-model="filters.checkedLayoverAirports"
              />&nbsp;
              <label :for="airport" class="my-auto">
                {{ airport }}
              </label>
            </li>
          </ul>

          <div class="mt-1">Duration</div>
          <input
            class="duration-input my-autos"
            type="range"
            :min="layoverDuration.min"
            :max="layoverDuration.max"
            v-model="filters.maxDuration"
          />
          <div class="duration-text my-auto flex justify-between">
            <div>{{ formatDuration(layoverDuration.min) }}</div>
            <div>{{ formatDuration(layoverDuration.max) }}</div>
          </div>
        </template>
      </Filter>
    </Scroller>
    <div
      class="uppercase whitespace-nowrap ml-4 hover:cursor-pointer"
      @click="resetFilters"
    >
      Clear All
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue';
import {
  Airport,
  Carrier,
  dayFormattingShort,
  dayFormattingShortest,
  Flight,
  FlightTimes,
  Leg,
  Maybe,
  minutesToDurationFormatter,
  Segment,
  unMaybe,
} from '@/api/service';
import { format } from 'date-fns';
import { useStore } from '@/store';
import { groupBy, mapValues, max, min } from 'lodash';

import Filter from './Filter.vue';
import Scroller from '@/components/common/misc/Scroller.vue';

export default defineComponent({
  components: {
    Scroller,
    Filter,
  },
  props: {
    segment: Object as PropType<Segment>,
  },
  setup(props, { emit }) {
    const store = useStore();

    const scrollXPos = ref(0);

    function stopsText(s: number) {
      if (s === 0) {
        return 'direct';
      } else if (s === 1) {
        return '1 stop';
      }
      return `${s} stops`;
    }

    const flightOptions: Flight[] = (
      props.segment?.flightSegment?.flightOptions ?? []
    ).filter(flightOption => {
      if (!flightOption) return false;

      return (
        flightOption.flightNumber !==
        props.segment?.flightSegment?.selectedFlight?.flightNumber
      );
    }) as Flight[];

    const elapsedTimes = computed(() =>
      flightOptions
        .filter(fo => fo.elapsedTime != null)
        .map(flightOption => Number(flightOption.elapsedTime))
        .sort((a, b) => a - b)
    );

    const elapsedLayoverTimes = computed(() => {
      const legs = flightOptions
        .map(flightOption => flightOption?.legs?.slice(1))
        .flat();
      return legs
        .filter(leg => unMaybe(leg)?.layoverMinutes != null)
        .map(leg => unMaybe(leg)?.layoverMinutes);
    });

    const filters = reactive({
      checkedAirlines: [],
      checkedStops: [],
      checkedDepartureTimes: [],
      checkedArrivalTimes: [],
      maxDuration: max(elapsedTimes.value),
      maxLayoverDuration: max(elapsedLayoverTimes.value),
      checkedDepartureAirports: [],
      checkedArrivalAirports: [],
      checkedLayoverAirports: [],
    });

    function selectedStr(
      selectedArray: string[],
      noSelectedText: string,
      labelStr?: string
    ) {
      const label = labelStr || '';
      if (selectedArray.length === 0) {
        return noSelectedText;
      } else if (selectedArray.length === 1) {
        return `${selectedArray[0]} ${label}`;
      }
      return `${selectedArray[0]} ${label} +${selectedArray.length - 1}`;
    }

    const checkedAirlinesStr = computed(() =>
      selectedStr(filters.checkedAirlines, 'Any Airline')
    );

    const stopsStr = computed(() =>
      selectedStr(filters.checkedStops, 'Any Number of Stops', 'stops')
    );

    const departureTimeStr = computed(() =>
      selectedStr(filters.checkedDepartureTimes, 'Any Departure Time')
    );
    const arrivalTimeStr = computed(() =>
      selectedStr(filters.checkedArrivalTimes, 'Any Arrival Time')
    );

    function formatDuration(minutes: number | undefined) {
      if (minutes) {
        return minutesToDurationFormatter(minutes.toString());
      } else {
        return 'N/A';
      }
    }

    const maxDurationActive = computed(
      () => filters.maxDuration != max(elapsedTimes.value)
    );

    const maxDurationStr = computed(() =>
      filters.maxDuration ? formatDuration(filters.maxDuration) : ''
    );

    const checkedLayoverAirportsStr = computed(() => {
      const airports = filters.checkedLayoverAirports.map((a: string) =>
        a.slice(-4, -1)
      );
      return selectedStr(airports, 'Any Layover Airport');
    });

    const maxLayoverDurationStr = computed(() =>
      filters.maxLayoverDuration
        ? formatDuration(filters.maxLayoverDuration)
        : ''
    );
    const layoverStr = computed(
      () => `${checkedLayoverAirportsStr.value}, ${maxLayoverDurationStr.value}`
    );

    const checkedDepartureAirportsStr = computed(() => {
      const airports = filters.checkedDepartureAirports.map((a: string) =>
        a.slice(-4, -1)
      );
      return selectedStr(airports, 'Any Departure Airport');
    });

    const checkedArrivalAirportsStr = computed(() => {
      const airports = filters.checkedArrivalAirports.map((a: string) =>
        a.slice(-4, -1)
      );
      return selectedStr(airports, 'Any Arrival Airport');
    });

    const checkedAirportsStr = computed(
      () =>
        `${checkedDepartureAirportsStr.value} -  ${checkedArrivalAirportsStr.value}`
    );

    const carriers = computed<Carrier[]>(() => store.getters.carriers);

    function getAirline(flightNumber: Maybe<string> | undefined): string {
      if (flightNumber === null || flightNumber === undefined) {
        return 'Unknown';
      }
      const iataCode = flightNumber.slice(0, 2);
      const carrier = carriers.value.find((c: Carrier) => c.code === iataCode);
      return carrier?.name || 'Unknown';
    }

    function countPerItem<T>(
      list: T[],
      f: (item: T) => string
    ): { [key: string]: number } {
      return mapValues(groupBy(list, f), 'length');
    }

    const airlines = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        getAirline(flightOption.flightNumber ?? '')
      );
    });

    const departureAirports = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        flightOption.departureAirport
          ? store.getters.getAirportString(flightOption.departureAirport)
          : ''
      );
    });

    const arrivalAirports = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        flightOption.arrivalAirport
          ? store.getters.getAirportString(flightOption.arrivalAirport)
          : ''
      );
    });

    const layoverAirports = computed(() => {
      const airports: (Airport | undefined)[] = flightOptions
        .map((f: Flight) =>
          f?.legs
            ?.slice(1)
            .map((leg: Maybe<Leg>) => unMaybe(unMaybe(leg)?.departureAirport))
        )
        .flat();
      return countPerItem(airports, airport =>
        airport ? store.getters.getAirportString(airport) : ''
      );
    });

    const stops = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        String((flightOption.legs ?? []).length - 1)
      );
    });

    const departureTimes = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        store.getters.getFlightTime(
          flightOption.departureTime?.seconds,
          flightOption.departureCity?.timeZone
        )
      );
    });

    const arrivalTimes = computed(() => {
      return countPerItem(flightOptions, flightOption =>
        store.getters.getFlightTime(
          flightOption.arrivalTime?.seconds,
          flightOption.arrivalCity?.timeZone
        )
      );
    });

    const departureDateStr = computed(() => {
      const localDate = props.segment
        ? store.getters.departureDateForSegment(props.segment)
        : undefined;
      return props.segment && localDate !== undefined
        ? format(localDate, dayFormattingShort)
        : '-';
    });

    const arrivalDateStr = computed(() => {
      const localDate = props.segment
        ? store.getters.arrivalDateForSegment(props.segment)
        : undefined;
      return props.segment && localDate !== undefined
        ? format(localDate, dayFormattingShort)
        : '-';
    });

    const showArrivalStr = computed(
      () => arrivalDateStr.value !== departureDateStr.value
    );

    function sortFlightTimes(a: FlightTimes, b: FlightTimes): number {
      const order = {
        [FlightTimes.EarlyMorning]: 1,
        [FlightTimes.Morning]: 2,
        [FlightTimes.Afternoon]: 3,
        [FlightTimes.Evening]: 4,
      };

      if (order[a] < order[b]) {
        return -1;
      }
      if (order[a] > order[b]) {
        return 1;
      }
      return 0;
    }

    function formatDate(localDate: Date) {
      return format(localDate, dayFormattingShortest);
    }

    function resetFilters() {
      filters.checkedAirlines = [];
      filters.checkedStops = [];
      filters.checkedDepartureTimes = [];
      filters.checkedArrivalTimes = [];
      filters.maxDuration = max(elapsedTimes.value);
      filters.maxLayoverDuration = max(elapsedLayoverTimes.value);
      filters.checkedDepartureAirports = [];
      filters.checkedArrivalAirports = [];
      filters.checkedLayoverAirports = [];
    }

    watch(filters, newFilters => {
      emit('update:filters', newFilters);
    });

    return {
      filters,
      airlines,
      getAirline,
      stops,
      departureTimes,
      arrivalTimes,
      flightOptions,
      duration: {
        min: min(elapsedTimes.value),
        max: max(elapsedTimes.value),
      },
      layoverDuration: {
        min: min(elapsedLayoverTimes.value),
        max: max(elapsedLayoverTimes.value),
      },
      departureAirports,
      arrivalAirports,
      layoverAirports,
      departureDateStr,
      arrivalDateStr,
      showArrivalStr,
      checkedAirlinesStr,
      stopsStr,
      stopsText,
      departureTimeStr,
      arrivalTimeStr,
      maxDurationActive,
      maxDurationStr,
      maxLayoverDurationStr,
      checkedAirportsStr,
      layoverStr,
      formatDate,
      formatDuration,
      resetFilters,
      scrollXPos,
      sortFlightTimes,
    };
  },
});
</script>
