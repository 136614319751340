<template>
  <div class="Inspire w-full bg-white">
    <div class="flex mt-6 mb-4 mx-6">
      <div
        class="cursor-pointer flex hover:text-gray-700"
        :class="{ 'text-gray-400': !(category === currentItineraryCategory) }"
        v-for="(category, c) in itineraryCategories"
        :key="c"
        @click="setCurrentItineraryCategory(category)"
      >
        <div class="mr-6 cursor-pointer hover:text-gray-500">
          <div
            class="text-md hover:border-ow-s-gold"
            :class="{
              'border-b border-ow-s-gold':
                category === currentItineraryCategory,
            }"
          >
            {{ category.catName }}
          </div>
        </div>
      </div>
    </div>
    <Scroller class="w-full mb-6" v-if="currentItineraryCategory">
      <div
        v-for="(itinerary, i) in categoryItineraries"
        :key="itinerary.itinName"
        class="flex-shrink-0 flex flex-row text-cool-gray-600 h-40 mr-4 bg-white border border-t-4 border-ow-p-yellow cursor-pointer hover:shadow-2xl"
        :class="{
          'ml-6': i === 0,
          'shadow-2xl': isCurrentItinerary(itinerary),
        }"
        @click="setCurrentItinerary(itinerary)"
      >
        <div class="flex-none overflow-hidden">
          <transition name="fade-in">
            <img
              class="h-40 w-40 object-cover"
              :src="itinerary.itineraryThumbnail"
              v-show="showThumbnail"
              @load="showThumbnail = true"
              alt="thumbnail"
            />
          </transition>
        </div>
        <div
          class="flex flex-col w-64 py-3 px-4"
          :class="{
            'w-96': isCurrentItinerary(itinerary),
          }"
        >
          <div class="mb-2 text-lg">
            {{ itinerary.itinName }}
          </div>
          <div class="mb-2 text-sm">
            {{ getItineraryCityNames(itinerary) }}
          </div>
          <div
            class="justify-self-end flex flex-row"
            v-if="isCurrentItinerary(itinerary)"
          >
            <div
              class="btn small inspire mr-4"
              :class="{ disabled: requireDialogResponse }"
              @click.stop="selectInspirationCities"
            >
              Select cities to add
            </div>
            <div
              class="btn small inspire"
              :class="{ disabled: requireDialogResponse }"
              @click.stop="addInspirationCities"
            >
              Add all cities
            </div>
          </div>
        </div>
      </div>
    </Scroller>
    <OwBar />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { Category, CategoryItinerary, useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { MutationTypes } from '@/store/mutations';

import { City, Maybe } from '@/api/service';

import Scroller from '@/components/common/misc/Scroller.vue';
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy';
import OwBar from '@/components/common/misc/OwBar.vue';
import {
  formulaBuilder,
  literalStringSetBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

const PROBLEM_IMAGE = 'AdobeStock_57683096-Spices_1.jpg';

export default defineComponent({
  components: {
    OwBar,
    Scroller,
  },
  setup() {
    const store = useStore();

    const showThumbnail = ref(false);
    const originCity = computed(() => store.getters.originCity);
    const itineraryCategories = ref<Category[]>([]);
    const currentItineraryCategory = ref<Category | undefined>();
    const categoryItineraries = ref<CategoryItinerary[]>([]);
    const currentItinerary = ref<CategoryItinerary | undefined>();

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );
    const allCities = computed(() => {
      return store.state.geographyData && store.state.geographyData.cities
        ? store.state.geographyData?.cities.filter((c): c is City => c != null)
        : [];
    });
    const currentItineraryCities = computed(() => {
      if (!currentItinerary.value) return [];
      const cities = currentItinerary.value.itinerary
        .split(',')
        .map((stop: string) =>
          allCities.value.find((city: City) => city.code === stop)
        )
        .filter((c): c is City => c != null);
      return uniqueBy(cities, c => c.code);
    });
    const currentItineraryCityNames = computed(() => {
      const names: string[] = currentItineraryCities.value
        .map((city: City) => city.name)
        .filter((name): name is string => name != null);
      return [...new Set(names)].join(', ');
    });
    const inspirationItineraryCities = computed(
      () => store.state.inspirationItineraryCities
    );
    const currentCityCodes = computed<string[]>(() =>
      store.getters.cities
        .map(city => city.code)
        .filter((c): c is string => c != null)
    );
    const newInspirationCities = computed(() =>
      inspirationItineraryCities.value?.filter(
        (city: City) =>
          city.code != null &&
          !currentCityCodes.value.includes(city.code) &&
          city.code !== originCity.value?.code
      )
    );

    async function getCategories() {
      itineraryCategories.value = await store.dispatch(
        ActionTypes.GetItineraryCategories
      );
    }

    async function getCategoryItineraries(category: string) {
      categoryItineraries.value = await store.dispatch(
        ActionTypes.GetItineraries,
        category
      );
    }

    function getCategoryImage(category: Category) {
      return category.categoryThumbnail.includes(PROBLEM_IMAGE) &&
        categoryItineraries.value &&
        categoryItineraries.value.length > 0
        ? categoryItineraries.value[0].itineraryThumbnail
        : category.categoryThumbnail;
    }

    function setCurrentItineraryCategory(category: Category) {
      currentItineraryCategory.value = category;
      getCategoryItineraries(currentItineraryCategory.value.catName);
    }

    function getItineraryCityNames(itinerary: CategoryItinerary) {
      const names = itinerary.itinerary
        .split(',')
        .map((stop: string) =>
          allCities.value.find((city: Maybe<City>) => city?.code === stop)
        )
        .filter((c): c is City => c != null)
        .map((city: City) => city.name);
      return [...new Set(names)].join(', ');
    }

    function setCurrentItinerary(itinerary: CategoryItinerary) {
      currentItinerary.value = itinerary;
      store.commit(
        MutationTypes.SetInspirationItineraryCities,
        currentItineraryCities.value
      );
    }

    function clearCurrentItinerary() {
      currentItinerary.value = undefined;
      store.commit(MutationTypes.SetInspirationItineraryCities, []);
    }

    function isCurrentItinerary(itinerary: CategoryItinerary) {
      return currentItinerary.value === itinerary;
    }

    function inspirationCitiesNamesAndCodes() {
      const cityCodes: string[] = [];
      const cityNames: string[] = [];
      newInspirationCities.value?.forEach((city: City) => {
        if (city.code != null && city.name != null) {
          const cityNameString = city.hasAmbiguousName
            ? `${city.name} (${city.code})`
            : city.name;
          cityCodes.push(city.code);
          cityNames.push(cityNameString);
        }
      });
      return {
        names: [...new Set(cityNames)],
        codes: [...new Set(cityCodes)],
      };
    }

    function addInspirationCities() {
      if (requireDialogResponse.value) {
        return;
      }
      const { names, codes } = inspirationCitiesNamesAndCodes();

      if (names.length > 0) {
        let nameStr =
          names.length > 1
            ? names.slice(0, -1).join(', ') + ', and ' + names.slice(-1)
            : names[0];
        const action = sendActionBuilder(
          'add ' + nameStr,
          formulaBuilder('rtwAction/addToItinerary', [
            propertyValueBuilder('cities', literalStringSetBuilder(codes)),
          ])
        );
        store.dispatch(ActionTypes.SendActions, [action]);
        store.commit(MutationTypes.SetShowInspiration, false);
        clearCurrentItinerary();
      }
    }

    function selectInspirationCities() {
      if (requireDialogResponse.value) {
        return;
      }
      const { names, codes } = inspirationCitiesNamesAndCodes();
      if (names.length > 0) {
        let nameStr =
          names.length > 1
            ? names.slice(0, -1).join(', ') + ', and ' + names.slice(-1)
            : names[0];
        const action = sendActionBuilder(
          "I'd like to consider " + nameStr,
          formulaBuilder('rtwAction/addInterestedCities', [
            propertyValueBuilder('cities', literalStringSetBuilder(codes)),
          ])
        );
        store.dispatch(ActionTypes.SendActions, [action]);
        store.commit(MutationTypes.SetShowInspiration, false);
        clearCurrentItinerary();
      }
    }

    onMounted(() => {
      getCategories();
      getCategoryItineraries('adventure');
    });

    watch(itineraryCategories, newCategories => {
      if (
        !currentItineraryCategory.value &&
        newCategories != null &&
        newCategories.length > 0
      ) {
        setCurrentItineraryCategory(newCategories[0]);
      }
    });

    return {
      showThumbnail,
      requireDialogResponse,
      itineraryCategories,
      currentItineraryCategory,
      getCategoryImage,
      setCurrentItineraryCategory,
      categoryItineraries,
      getItineraryCityNames,
      currentItinerary,
      currentItineraryCityNames,
      currentItineraryCities,
      setCurrentItinerary,
      isCurrentItinerary,
      addInspirationCities,
      selectInspirationCities,
    };
  },
});
</script>
