<template>
  <div
    class="flex flex-row flex-wrap justify-end mt-2 p-2 pb-0 overflow-hidden"
  >
    <button
      class="btn-choice mr-1 mb-1"
      v-for="choice in choices"
      :key="choice.index"
      @click.stop="toggle(choice)"
      :class="{ selected: isSelectedChoice(choice) }"
      v-html="choiceText(choice)"
    />
  </div>
</template>

<style lang="scss" scoped>
.btn-choice {
  @apply bg-white
    border border-ow-p-dark-blue
    text-ow-p-dark-blue text-base uppercase all-small-caps
    py-1 px-3
    rounded-full;

  &:hover {
    @apply shadow-md;
  }

  &.selected {
    @apply bg-ow-p-dark-blue text-white;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}
</style>

<script lang="ts">
import { defineComponent, computed, watch, nextTick, onMounted } from 'vue';
import { useStore } from '@/store';
import { isMultiChoice, Choice, unMaybe, isSingleChoice } from '@/api/service';
import Logger from '@/logger';
import { MutationTypes } from '@/store/mutations';

import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
const md = new MarkdownIt().use(MarkDownItEmoji);

const logger = new Logger('rtw:InputBoxMultiChoice');

export default defineComponent({
  emits: ['submit', 'update:submittable'],
  setup(_, { emit }) {
    const store = useStore();

    const lastRequestSystemMessage = computed(
      () => store.getters.lastRequestSystemMessage
    );
    const disableTextEntry = computed(() => store.getters.disabledTextEntry);
    const messages = computed(() => store.state.dialog.messages);
    const show = computed(() => isMultiChoice(lastRequestSystemMessage.value));
    const choices = computed(() => store.getters.multiChoiceChoices);
    const selectedChoices = computed(
      () => store.state.dialog.inputBox.selectedChoices
    );
    const requireSubmit = computed(
      () =>
        lastRequestSystemMessage.value != null &&
        !isSingleChoice(lastRequestSystemMessage.value)
    );

    const isInMultiChoiceRange = computed(
      () =>
        lastRequestSystemMessage.value != null &&
        lastRequestSystemMessage.value.multiChoice &&
        lastRequestSystemMessage.value.multiChoice?.minRequired != null &&
        selectedChoices.value.length >=
          lastRequestSystemMessage.value.multiChoice?.minRequired &&
        lastRequestSystemMessage.value.multiChoice?.maxAllowed != null &&
        selectedChoices.value.length <=
          lastRequestSystemMessage.value.multiChoice?.maxAllowed
    );

    const isMutuallyExclusiveSelected = computed(
      () =>
        lastRequestSystemMessage.value != null &&
        lastRequestSystemMessage.value.multiChoice &&
        selectedChoices.value.length === 1 &&
        selectedChoices.value[0].isMutuallyExclusive
    );

    const submittable = computed(
      () => isInMultiChoiceRange.value || isMutuallyExclusiveSelected.value
    );

    function choiceText(choice: Choice) {
      if (choice.textChoice != null && choice.textChoice.displayText != null) {
        return md.renderInline(choice.textChoice.displayText);
      } else if (choice.action != null && choice.action.text != null) {
        return md.renderInline(choice.action.text);
      } else {
        logger.error('Error displaying text for choice: ' + choice);
        return '-';
      }
    }

    function isSelectedChoice(choice: Choice) {
      const index = unMaybe(choice.index);
      if (index === undefined) {
        return;
      }
      return selectedChoices.value?.includes(choice);
    }

    function reset() {
      nextTick(() => {
        emit('update:submittable', submittable.value);
      });
    }

    function toggle(choice: Choice) {
      const index = unMaybe(choice.index);
      if (index === undefined) {
        return;
      }

      // Update that there was an input interaction
      store.commit(MutationTypes.SetDialogInputBoxLastInteraction, new Date());

      // Always reset text when a choice is toggled
      store.commit(MutationTypes.SetDialogInputBoxText, '');

      // If M.E. clear out other choices first
      if (choice.isMutuallyExclusive) {
        const isSelected = isSelectedChoice(choice);
        store.commit(MutationTypes.ClearSelectedChoices);
        // Put is back to toggle
        // TODO: bit clumsy, need utility to toggle or but, or something else
        if (isSelected) {
          store.commit(MutationTypes.ToggleChoice, choice);
        }
      } else if (
        // If any other mutually exclusive selected, then clear.
        // The rtwAction/restart is necessary to allow restart in the middle of another multi-choice action
        // (like during the addition of an alternative travel segment, with one of the cities already selected)
        (isMutuallyExclusiveSelected.value &&
          selectedChoices.value[0] !== choice) ||
        choice.action?.action?.predicate === 'rtwAction/restart'
      ) {
        store.commit(MutationTypes.ClearSelectedChoices);
      }

      // Toggle the choice
      store.commit(MutationTypes.ToggleChoice, choice);

      // Wait for updates and submit
      nextTick(() => {
        // Update submittable
        emit('update:submittable', submittable.value);

        // Force trigger submit if it makes sense
        if (!requireSubmit.value && submittable.value) {
          emit('submit');
          reset();
        }
      });
    }

    watch(messages, () => reset());

    // Reset input text box if we show the multi-choice + text is disabled
    watch(show, newShow => {
      nextTick(() => {
        if (newShow && disableTextEntry.value) {
          store.commit(MutationTypes.SetDialogInputBoxText, '');
        }
      });
    });

    onMounted(() => reset());

    return {
      show,
      choices,
      selectedChoices,
      requireSubmit,
      isSelectedChoice,
      isInMultiChoiceRange,
      isMutuallyExclusiveSelected,
      choiceText,
      toggle,
    };
  },
});
</script>
