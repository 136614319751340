<template>
  <div
    class="ItineraryListItem pl-8"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="wrapper flex items-center relative">
      <div v-if="showDisabledIcon" class="absolute -left-8">
        <a-tooltip placement="bottomRight">
          <template #title>
            <div>
              This action is disabled. You need to respond to the chat first
            </div>
          </template>
          <font-awesome-icon
            class="ml-1 text-lg text-gray-500 relative"
            :icon="['fal', 'trash-can']"
          />
        </a-tooltip>
      </div>
      <div v-if="showDeleteIcon && !showConfirmDelete" class="absolute -left-8">
        <font-awesome-icon
          class="ml-1 text-lg text-gray-500 cursor-pointer hover:bg-gray-500 hover:bg-opacity-10 hover:font-bold mr-2 relative"
          @click="showDelete"
          :icon="['fal', 'trash-can']"
        />
      </div>
      <div class="flex flex-grow">
        <div
          class="bg-red-500 border-red-800 px-3.5 py-7 flex items-center cursor-pointer mb-2 hover:underline hover:text-gray-700"
          v-if="showConfirmDelete"
          @click="deleteItinerary"
        >
          delete
        </div>
        <div
          class="flex flex-grow justify-between border border-gray-200 shadow-md mb-2 hover:border-ow-p-dark-blue"
        >
          <div class="px-4 py-2 my-1 flex flex-grow flex-col justify-between">
            <div class="text-ow-p-dark-blue flex-grow">
              <div>{{ itinerary.itineraryName }}</div>
              <div class="text-sm">{{ itinerary.itineraryDescription }}</div>
            </div>
            <div class="text-sm mt-3">
              Last updated:
              {{ getUpdateDate(itinerary.itineraryLastModifiedDate) }}
            </div>
          </div>
          <div
            class="mr-4 mt-1 flex text-ow-p-dark-blue hover:font-bold cursor-pointer"
            v-if="showOpen"
          >
            <span class="mr-2" @click="openItinerary">open</span
            ><span class="mt-0.25"
              ><font-awesome-icon class="arrow" :icon="['fas', 'angle-right']"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { format } from 'date-fns';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { timeFormatting } from '@/api/service';
import { MutationTypes } from '@/store/mutations';
import {
  formulaBuilder,
  literalStringBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

export default defineComponent({
  props: {
    itinerary: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    const showDeleteIcon = ref(false);
    const showConfirmDelete = ref(false);
    const showDisabledIcon = ref(false);

    function showDelete() {
      showConfirmDelete.value = true;
      showDeleteIcon.value = false;
      showDisabledIcon.value = false;
    }

    function updatedTime(date: Date) {
      return format(date, timeFormatting);
    }

    const showOpen = ref(false);
    function handleMouseEnter(event: Event) {
      event.stopPropagation();
      showOpen.value = true;
      if (store.getters.requireDialogResponse) {
        showDisabledIcon.value = true;
        showDeleteIcon.value = false;
      } else {
        showDisabledIcon.value = false;
        showDeleteIcon.value = true;
      }
    }

    function handleMouseLeave(event: Event) {
      event.stopPropagation();
      showDeleteIcon.value = false;
      showConfirmDelete.value = false;
      showDisabledIcon.value = false;
      showOpen.value = false;
    }

    function deleteItinerary() {
      // Remove from list
      store.commit(MutationTypes.RemoveItinerary, props.itinerary);

      // Send command
      const action = sendActionBuilder(
        `Delete itinerary "${props.itinerary.itineraryName}"`,
        formulaBuilder('rtwAction/deleteItinerary', [
          propertyValueBuilder(
            'name',
            literalStringBuilder(props.itinerary.itineraryName)
          ),
        ])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
    }

    function openItinerary() {
      const msg = `open my itinerary "${props.itinerary.itineraryName}"`;
      const action = sendActionBuilder(
        msg,
        formulaBuilder('rtwAction/loadItinerary', [
          propertyValueBuilder(
            'name',
            literalStringBuilder(props.itinerary.itineraryName)
          ),
        ])
      );
      store.dispatch(ActionTypes.SendActions, [action]);
      store.commit(MutationTypes.SetShowItineraryList, false);
    }

    function getUpdateDate(updated: string) {
      // expected string format: 2\/28\/2022 - 3:03 PM
      return updated.split(' - ').shift();
    }

    return {
      requireDialogResponse,
      showDeleteIcon,
      showConfirmDelete,
      showDisabledIcon,
      showDelete,
      updatedTime,
      showOpen,
      handleMouseEnter,
      handleMouseLeave,
      deleteItinerary,
      openItinerary,
      getUpdateDate,
    };
  },
});
</script>
