export default [
  {
    title: 'Manipulate Cities',
    examples: [],
    options: [
      {
        title: 'Starting city',
        examples: [
          'Start in Tokyo',
          'My starting city is London',
          'Change my starting city to Paris',
          'Replace my home city with Miami',
        ],
      },
      {
        title: 'Add or remove cities',
        examples: [
          'Add New York',
          'Add LA before San Francisco',
          'Add Rome after Copenhagen',
          'Add Doha between London and Sydney',
          'I want to go to Berlin',
          'Drop Sao Paulo',
          'Put Milan before Stockholm',
          'Put Beijing after Hong Kong',
          'Put Dublin between Seoul and Rome',
        ],
      },
      {
        title: 'City order',
        examples: [
          'Move Athens before Paris',
          'Move Munich after Helsinki',
          'Move Madrid between Seoul and Rome',
          'Swap Beijing and Tokyo',
          'Replace Doha with Mumbai',
          'Reverse the direction',
        ],
      },
    ],
  },
  {
    title: 'Manipulate Dates',
    examples: [],
    options: [
      {
        title: 'Trip start or duration',
        examples: [
          'Start trip in September',
          'Change start date to October 20th',
          'Change trip duration to 5 months',
          'Increase trip duration by 1 month',
          'Decrease trip duration by 2 weeks',
        ],
      },
      {
        title: 'Apply dates for specific cities',
        examples: [
          'Depart Rome on August 2nd',
          'Arrive in Osaka on November 5th',
          'I need to be in Chicago on June 2nd',
          'Increase my stay in Singapore by 1 week',
          'Decrease my stay in Lisbon by 3 days',
          'Be in Seattle for 21 days',
        ],
      },
    ],
  },
  {
    title: 'Other',
    examples: ['Help', 'Restart'],
    options: [],
  },
];
