<template>
  <div class="TaskInfo flex flex-row px-4">
    <div class="submit mr-2">
      <button @click="toggleShowSubmitModal" id="submit-btn" class="btn">
        Submit HIT
      </button>
      <Teleport to="body">
        <Modal
          v-model:show="showSubmitModal"
          headerText="Submit Task"
          arrow="none"
          triggerId="submit-btn"
          :showClose="false"
        >
          <template v-slot:body>
            <div class="px-4 py-8">
              Are you sure you're ready to submit?
            </div>
          </template>
          <template v-slot:footer>
            <div class="btns">
              <div class="cancel-btn">
                <button class="btn secondary" @click="toggleShowSubmitModal">
                  Cancel
                </button>
              </div>
              <div class="submit-btn">
                <form
                  name="mturk_form"
                  method="post"
                  id="mturk_form"
                  :action="submitUrl"
                  @submit.prevent="submitTask"
                >
                  <input
                    type="hidden"
                    :value="assignmentId"
                    name="assignmentId"
                    id="assignmentId"
                  />
                  <input type="hidden" value="true" name="hasSubmitted" />
                  <button
                    class="btn"
                    type="button"
                    id="submitButton"
                    value="Submit"
                    @click="submitTask"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </template>
        </Modal>
      </Teleport>
    </div>
    <div class="text-5xl mr-2">☝</div>
    <div class="text-lg w-72 font-bold">
      Please see the <span class="whitespace-nowrap">"HIT Details"</span> link
      above to view instructions.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';

import { ActionTypes } from '@/store/actions';

import Modal from '@/components/common/misc/Modal.vue';

export default defineComponent({
  props: {
    taskId: {
      type: String,
      required: false,
    },
  },
  components: {
    Modal,
  },
  setup(props) {
    const store = useStore();
    const itinerarySmallUrl = computed<string>(() => {
      if (props.taskId && Number.parseInt(props.taskId) <= 5) {
        return require('../../assets/tasks/task' +
          Number.parseInt(props.taskId) +
          '.png');
      } else {
        return undefined;
      }
    });
    const itineraryUrl = computed<string>(() => {
      if (
        props.taskId &&
        Number.parseInt(props.taskId) <= 5 &&
        Number.parseInt(props.taskId) > 0
      ) {
        return require('../../assets/tasks/task' +
          Number.parseInt(props.taskId) +
          '-noFlight.png');
      } else {
        return undefined;
      }
    });

    const showSubmitModal = ref<boolean>(false);
    function toggleShowSubmitModal() {
      showSubmitModal.value = !showSubmitModal.value;
    }

    const showItineraryModal = ref<boolean>(false);
    function toggleShowItineraryModal() {
      showItineraryModal.value = !showItineraryModal.value;
    }

    const assignmentId = computed<string | undefined>(
      () => store.state?.assignmentId
    );
    const submitUrl = computed<string>(
      () => `${store.state.turkSubmitTo}/mturk/externalSubmit`
    );

    async function submitTask() {
      await store.dispatch(ActionTypes.SubmitTurkTask);
      const form: HTMLFormElement | null = document.getElementById(
        'mturk_form'
      ) as HTMLFormElement;
      if (form) {
        form.submit();
      }
      toggleShowSubmitModal();
    }

    const accordianItem = ref('description');
    function toggleAccordianItem(item: string) {
      if (accordianItem.value === item) {
        accordianItem.value = '';
      } else {
        accordianItem.value = item;
      }
    }

    return {
      assignmentId,
      itinerarySmallUrl,
      itineraryUrl,
      showSubmitModal,
      toggleShowSubmitModal,
      showItineraryModal,
      toggleShowItineraryModal,
      submitUrl,
      submitTask,
      toggleAccordianItem,
      accordianItem,
    };
  },
});
</script>
