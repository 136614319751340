<template>
  <div class="Preference" :data-tid="`preferences__button-${triggerId}`">
    <a-popover
      trigger="click"
      :visible="localShowEdit"
      overlayClassName="z-2000 popover-no-padding"
      placement="bottomLeft"
      @visibleChange="handleVisibleChange"
    >
      <div
        class=""
        :class="{
          'cursor-pointer': !requireDialogResponse,
          'cursor-not-allowed': requireDialogResponse,
          'opacity-40': requireDialogResponse,
        }"
        :id="triggerId"
        @click="localShowEdit = !requireDialogResponse"
      >
        <div>
          <div
            class="flex flex-row items-center bg-white px-7 py-3 border border-gray-300 text-ow-p-dark-blue rounded-full whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <span>{{ title }}</span>
            <font-awesome-icon
              class="ml-2 hover:text-gray-500"
              :icon="['far', 'angle-down']"
            />
          </div>
        </div>
      </div>
      <template #content>
        <div :data-tid="`preferences__popup-${triggerId}`">
          <slot name="body" />
          <slot name="footer" />
        </div>
      </template>
    </a-popover>
  </div>
</template>

<style lang="scss" scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.enabled {
  color: #4b5563;
  &:hover {
    cursor: pointer;
    color: black;
    border-color: #9fa6b2;
    box-shadow: 0 0 2px #9fa6b2;
    -moz-box-shadow: 0 0 2px #9fa6b2;
    -webkit-box-shadow: 0 0 2px #9fa6b2;
    -o-box-shadow: 0 0 2px #9fa6b2;
  }
}
.customButton {
  display: inline;
  width: 16px;
  height: 16px;
  background-color: white;
  box-shadow: 0 0 2px #d2d6dc;
  -moz-box-shadow: 0 0 2px #d2d6dc;
  -webkit-box-shadow: 0 0 2px #d2d6dc;
  -o-box-shadow: 0 0 2px #d2d6dc;
  border-radius: 12px;
}
</style>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  props: {
    triggerId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showEdit: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:showEdit'],
  setup(props, { emit }) {
    const store = useStore();

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    const localShowEdit = computed({
      get: () => {
        return props.showEdit;
      },
      set: (v: boolean) => {
        if (!requireDialogResponse.value) {
          emit('update:showEdit', v);
        }
      },
    });

    function handleVisibleChange(visible: boolean) {
      localShowEdit.value = visible;
    }

    return {
      localShowEdit,
      requireDialogResponse,
      handleVisibleChange,
    };
  },
});
</script>
