<template>
  <div class="Step mb-2">
    <div class="flex flex-row overflow-hidden">
      <City
        class="w-city flex-none"
        :index="index"
        :city="city"
        :sequence="segment?.sequence"
        :status="cityStatus"
        :statusMessage="cityStatusMessage"
        :draggable="draggable"
        :visit="visit"
        :preferences="cityPreferences"
      >
        <template #calendar>
          <slot name="calendar" />
        </template>
      </City>
      <Segment
        v-show="showSegments"
        class="ml-4 min-w-segment max-w-segment flex-grow dragBlur"
        :segment="segment"
        :previousSegment="previousSegment"
        :showDetails="showDetails"
        @toggleShowDetails="toggleDetails"
      />
    </div>
    <div
      v-if="showDetails"
      class="flex-grow bg-white px-9 py-2 mt-2 border border-gray-200 rounded-lg"
    >
      <!--  Only flight details at this point -->
      <FlightDetail
        v-if="segment?.flightSegment"
        :flight="segment?.flightSegment?.selectedFlight"
        :previousFlight="previousSegment?.flightSegment?.selectedFlight"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType, ref, toRefs, watch } from 'vue';
import {
  City as CityType,
  Concept,
  Formula,
  Segment as SegmentType,
  Status,
} from '@/api/service';

import City from './City.vue';
import Segment from './Segment.vue';
import FlightDetail from '../flight-options/FlightDetail.vue';

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
    city: {
      type: Object as PropType<CityType>,
      required: true,
    },
    visit: {
      type: Object as PropType<Concept>,
      required: true,
    },
    cityPreferences: {
      type: Object as PropType<Formula[]>,
      required: true,
    },
    cityStatus: {
      type: String as PropType<Status>,
      required: false,
    },
    cityStatusMessage: {
      type: String,
      required: false,
    },
    segment: {
      type: Object as PropType<SegmentType>,
      required: false,
    },
    previousSegment: {
      type: Object as PropType<SegmentType>,
      required: false,
    },
    draggable: {
      type: Boolean,
      required: true,
    },
    showSegments: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    City,
    Segment,
    FlightDetail,
  },
  setup(props) {
    const { segment } = toRefs(props);
    const showDetails = ref(false);

    function toggleDetails() {
      showDetails.value = !showDetails.value;
    }

    function hideDetails() {
      showDetails.value = false;
    }

    function update() {
      nextTick(() => {
        if (
          segment.value === undefined ||
          segment.value?.flightSegment?.selectedFlight != null
        ) {
          hideDetails();
        }
      });
    }

    watch(segment, update);

    return {
      showDetails,
      toggleDetails,
      hideDetails,
    };
  },
});
</script>
