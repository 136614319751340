<template>
  <div
    class="FlightSegment ticket ticket-notch-left ticket-notch-right flex flex-row items-center"
  >
    <!-- Left ticket slot -->
    <div
      class="h-full w-10 flex-none flex border-r-2 border-dotted items-center justify-center"
      @mouseenter="showGroundSegmentOption = true"
      @mouseleave="showGroundSegmentOption = false"
    >
      <a-tooltip
        v-if="departureCity?.name && arrivalCity?.name"
        class="flex flex-col"
        :class="{
          'cursor-pointer': allowGroundSegment,
        }"
        @click="requestGroundSegment"
      >
        <template #title>
          <p v-if="allowGroundSegment">
            Click to set {{ departureCity.name }} to {{ arrivalCity.name }} as
            alternative travel segment
          </p>
          <p v-else>
            Alternate travel not allowed on this segment
          </p>
          <p v-if="statusMessage" v-html="statusMessage" />
        </template>
        <font-awesome-icon :class="planeIconClass" :icon="['fas', 'plane']" />
        <font-awesome-icon
          v-if="allowGroundSegment && showGroundSegmentOption"
          :class="iconColor"
          :icon="['fal', 'arrow-down']"
        />
        <font-awesome-icon
          v-if="allowGroundSegment && showGroundSegmentOption"
          :class="iconColor"
          :icon="['fas', 'car-side']"
        />
      </a-tooltip>
    </div>

    <!-- Middle ticket slot -->
    <div class="flex-grow h-full px-4 py-2 flex flex-row">
      <div class="flex-grow flex items-center">
        <div class="pr-2 flex flex-col">
          <div class="mb-0.5" v-if="departureAirport">
            {{ departureAirport.code }}
          </div>
          <div class="mb-0.5" v-else-if="departureCity">
            {{ departureCity.name }}
          </div>
          <div class="">
            {{ departureTimeStr }}
          </div>
        </div>
          <PossibleStopOverPopover ref="stopOverToolTip" :segment="segment" :anchor="segmentDivId" :visible="hoveringStopOverIcon"/>
          <div class="flex-grow flex flex-col">
          <div
            class="flex-grow -mb-1 text-center"
            v-if="selectedFlightLegs.length > 0"
          >
            <div v-if="selectedFlightLegs.length <= 1">
              Nonstop
            </div>
            <div v-else>
              {{ selectedFlightLegs.length - 1 }}
              {{ stopsStr }}
              via
              {{ layoverStr }}
            </div>
          </div>
          <div
                  class="flex-grow -mb-1 text-center">
              <div v-bind:id=segmentDivId @mouseover="showPossibleStopOver"
                   @mouseout="hidePossibleStopOver"
                   v-if="showPossibleStopOverLabel"
                   style="cursor: pointer; margin-left: 20px; margin-right: 20px">
                  {{possibleStopOverText}}
              </div>
          </div>
          <FlightLine />
          <div class="flex-grow text-center min-w-48" v-if="selectedFlight">
            <font-awesome-icon
              :icon="['far', 'clock']"
              class="text-ow-s-sapphire"
            />
            {{ duration }}
          </div>
          <div
            class="flex-grow -mb-1 text-center"
            v-if="selectedFlightLegs.some(isLegClassMismatch)"
          >
            <a-tooltip
              :title="
                'Unfortunately your chosen class is not offered or is unavailable for this leg of the trip.'
              "
            >
              <span class="capitalize text-ow-warning text-bold">
                {{ getClassMismatchText(selectedFlightLegs) }}
                <font-awesome-icon
                  class="ml-1"
                  :icon="['fas', 'triangle-exclamation']"
                />
              </span>
            </a-tooltip>
          </div>
        </div>
        <div class="px-2 flex flex-col text-right">
          <div class="mb-0.5 text-base" v-if="arrivalAirport">
            {{ arrivalAirport.code }}
          </div>
          <div class="mb-0.5" v-else-if="arrivalCity">
            {{ arrivalCity.name }}
          </div>
          <div class="">
            {{ arrivalTimeStr }}
            <div class="text-ow-s-ruby text-right text-sm" v-if="diffDay">
              {{ diffDay }}
            </div>
          </div>
        </div>
      </div>

      <!--  Logo and options -->
      <div class="flex flex-col justify-between pl-4" v-if="selectedFlight">
        <img :src="logoUrl" class="h-1/2" alt="airline logo" />
        <!-- onboarding tooltip trigger -->
        <div
          class="place-self-center btn secondary small MoreFlights"
          :class="{
            'opacity-40': !allowSelectFlight,
          }"
          @click="toggleShowFlightOptions"
        >
          More flights
        </div>
        <FlightOptions
          v-model:show="showFlightOptions"
          :segment="segment"
          :previousFlight="lastLeg"
        />
      </div>
    </div>

    <!-- Right ticket slot -->
    <div
      class="h-full w-10 flex-none border-l-2 border-dotted flex justify-center items-center"
      @click="handleToggleShowDetails"
    >
      <font-awesome-icon
        class="hover:text-gray-400 cursor-pointer"
        :icon="['fas', 'angle-down']"
        v-if="selectedFlight && !showDetails"
      />
      <font-awesome-icon
        class="hover:text-gray-400 cursor-pointer"
        :icon="['fas', 'angle-up']"
        v-if="selectedFlight && showDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRefs,
  nextTick,
} from 'vue';
import {
  Segment,
  unMaybe,
  formatTimestamp,
  timestampToDate,
  minutesToDurationFormatter,
  unMaybeArray,
  dayFormatting,
  timeFormatting,
  dateOffsetString,
  Leg,
  Status,
} from '@/api/service';
import { useStore } from '@/store';
import PossibleStopOverPopover from './PossibleStopOverPopover.vue';
import FlightOptions from '../flight-options/FlightOptions.vue';
import FlightLine from './FlightLine.vue';
import { event } from 'vue-gtag';
import { ActionTypes } from '@/store/actions';
import {
  formulaBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';
import MarkdownIt from 'markdown-it';
import MarkDownItEmoji from 'markdown-it-emoji';
const md = new MarkdownIt().use(MarkDownItEmoji);

export default defineComponent({
  props: {
    segment: {
      type: Object as PropType<Segment>,
      required: true,
    },
    previousSegment: {
      type: Object as PropType<Segment>,
      required: false,
    },
    showDetails: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FlightLine,
    FlightOptions,
    PossibleStopOverPopover,
  },
  emits: ['toggleShowDetails'],
  setup(props, { emit }) {
    const store = useStore();

    const { showDetails } = toRefs(props);

    const requireDialogResponse = computed(
      () => store.getters.requireDialogResponse
    );

    const waitingOnDialog = computed(
      () => store.state.dialog.waitingOnDialogUpdateAfterSend
    );

    const haveActiveRequestSystemMessage = computed(
      () => store.getters.haveActiveRequestSystemMessage
    );

    const allowSelectFlight = computed(
      () => {
        if (requireDialogResponse.value) {
          return false;
        } else if (waitingOnDialog.value) {
          return false;
        } else if (!haveActiveRequestSystemMessage.value) {
          return false;
        }
        return true;
      }
    );

    const status = computed(() => props.segment.status);
    const statusMessage = computed(() => {
      const sm = unMaybe(props.segment.statusMessage);
      return sm !== undefined ? md.render(sm) : undefined;
    });
    const showWarningStatus = computed(
      () => props.segment.status === Status.StatusWarning
    );
    const showErrorStatus = computed(
      () => props.segment.status === Status.StatusError
    );
    const showStatus = computed(
      () => showWarningStatus.value || showErrorStatus.value
    );

    const selectedFlight = computed(() =>
      unMaybe(props.segment.flightSegment?.selectedFlight)
    );
    const selectedFlightLegs = computed(() =>
      unMaybeArray(selectedFlight.value?.legs)
    );
    const selectedFlightNumber = computed(() => {
      const fnumber = unMaybe(selectedFlight.value?.flightNumber);
      return fnumber ? fnumber.split('_').join(', ') : '';
    });

    const departureAirport = computed(() =>
      unMaybe(selectedFlight.value?.departureAirport)
    );
    const arrivalAirport = computed(() =>
      unMaybe(selectedFlight.value?.arrivalAirport)
    );
    const departureCity = computed(() => unMaybe(props.segment.departure));
    const arrivalCity = computed(() => unMaybe(props.segment.arrival));

    const departureDay = computed(() =>
      formatTimestamp(
        props.segment.departureTime,
        departureCity.value?.timeZone,
        dayFormatting
      )
    );
    const arrivalDay = computed(() =>
      formatTimestamp(
        props.segment.arrivalTime,
        arrivalCity.value?.timeZone,
        dayFormatting
      )
    );

    const departureTime = computed(() =>
      formatTimestamp(
        props.segment.departureTime,
        departureCity.value?.timeZone,
        timeFormatting
      )
    );
    const arrivalTime = computed(() =>
      formatTimestamp(
        props.segment.arrivalTime,
        arrivalCity.value?.timeZone,
        timeFormatting
      )
    );

    const departureDate = computed(() =>
      timestampToDate(
        selectedFlight.value?.departureTime,
        unMaybe(selectedFlight.value?.departureCity?.timeZone)
      )
    );
    const arrivalDate = computed(() =>
      timestampToDate(
        selectedFlight.value?.arrivalTime,
        unMaybe(selectedFlight.value?.arrivalCity?.timeZone)
      )
    );

    const diffDay = computed(() =>
      dateOffsetString(departureDate.value, arrivalDate.value)
    );

    const duration = computed(() =>
      minutesToDurationFormatter(unMaybe(selectedFlight.value?.elapsedTime))
    );

    const flightOptions = computed(() =>
      unMaybe(props.segment.flightSegment?.flightOptions)
    );
    const haveflightOptions = computed(() =>
      flightOptions.value ? flightOptions.value?.length > 0 : false
    );

    const showFlightOptions = ref(false);
    function toggleShowFlightOptions() {
      if (!allowSelectFlight.value) return;
      showFlightOptions.value = !showFlightOptions.value;
      if (showFlightOptions.value) {
        event('flight-options-show', { method: 'Google' });
      } else {
        event('flight-options-hide', { method: 'Google' });
      }
    }

    function handleToggleShowDetails() {
      emit('toggleShowDetails');
      nextTick(() => {
        if (showDetails.value) {
          event('flight-details-show', { method: 'Google' });
        } else {
          event('flight-details-hide', { method: 'Google' });
        }
      });
    }

    const logoUrl = computed<string>(() => {
      if (selectedFlight.value) {
        const code = selectedFlight.value?.id?.slice(0, 2);
        if (code) {
          const url = store.getters.logoUriForCarrierCode(code);
          if (url) return require(`@/${url}.png`);
          return undefined;
        }
        return undefined;
      } else {
        return undefined;
      }
    });
    const departureTimeStr = computed(
      () => departureTime.value || departureDay.value
    );
    const arrivalTimeStr = computed(
      () => arrivalTime.value || arrivalDay.value
    );
    const flightStr = computed(() =>
      props.segment?.flightSegment?.selectedFlight?.flightNumber
        ?.split('_')
        .join(', ')
    );
    const stopsStr = computed(() =>
      selectedFlightLegs.value.length === 2 ? 'stop' : 'stops'
    );
    const layoverStr = computed(() =>
      selectedFlightLegs.value
        ?.slice(0, -1)
        .map((leg: Leg) => leg.arrivalAirport?.code)
        .join(', ')
    );

    const itineraryOrdered = computed(() => store.getters.itineraryOrdered);
    const iconColor = computed<string>(() => {
      // default to unordered color
      let colorVal = 'neutral-500';
      if (showWarningStatus.value) {
        colorVal = 'ow-warning';
      } else if (showErrorStatus.value) {
        colorVal = 'ow-error';
      } else if (!haveflightOptions.value) {
        colorVal = 'neutral-500';
      } else if (itineraryOrdered.value) {
        colorVal = 'ow-s-sapphire';
      }
      return `text-${colorVal}`;
    });

    const planeIconClass = computed(() => {
      const c: { [key: string]: boolean } = {};
      c[iconColor.value] = true;
      if (allowGroundSegment.value) {
        c['FlightSegmentPlane'] = true;
      }
      return c;
    });

    const lastLeg = computed(() =>
      props.previousSegment?.flightSegment?.selectedFlight?.legs
        ?.slice(-1)
        .pop()
    );

    //
    // Ground segment option
    const showGroundSegmentOption = ref(false);

    const allowGroundSegment = computed(
      () =>
        props.segment.departureVisit != null &&
        !store.getters.isOriginVisit(props.segment.departureVisit)
    );

    async function requestGroundSegment() {
      if (!allowGroundSegment.value) return;
      const departure = props.segment.departure?.name;
      const departureVisit = props.segment.departureVisit;
      const arrival = props.segment.arrival?.name;
      const arrivalVisit = props.segment.arrivalVisit;
      if (departure && departureVisit && arrival && arrivalVisit) {
        const action = sendActionBuilder(
          'Add alternative travel between ' + departure + ' and ' + arrival,
          formulaBuilder('rtwAction/setGroundSegment', [
            propertyValueBuilder('origin', departureVisit),
            propertyValueBuilder('destination', arrivalVisit),
          ])
        );
        if (action != undefined) {
          await store.dispatch(ActionTypes.SendActions, [action]);
        }
      }
    }
    function isLegClassMismatch(leg: Leg) {
      const user_class = store.state.travelInfo?.seatClass;

      if (
        leg.userClass?.ubCabinClass != null &&
        user_class != null
      ) {
        return (
          // user_class is a string like SEAT_CLASS_FIRST,
          // ubCabinClass is first, business....
          // Using include due to the difference.
          !user_class.toLowerCase().includes(leg.userClass?.ubCabinClass.toLowerCase())
        );
      } else {
        return false;
      }
    }

    function getClassMismatchText(selectedFlightLegs: Leg[]): string {
      let legsWithMismatch = selectedFlightLegs.filter(isLegClassMismatch);
      let legClasses = legsWithMismatch.map(legWithMismatch =>
        legWithMismatch.userClass?.ubCabinClass?.toLowerCase()
      );
      let uniqueClasses = legClasses.filter(
        (value, index, array) => array.indexOf(value) === index
      );
      if (uniqueClasses.length === 1) {
        return uniqueClasses[0] + ' Class';
      } else {
        return 'Class Mismatch';
      }
    }

    const segmentDivId = computed(() => "segmentDiv"+props.segment.departure?.code+props.segment.arrival?.code);

    const hoveringStopOverIcon = ref(false);

    const hasPossibleOneStopOverCities = computed(() => (props.segment.possibleOneStopOverCities != undefined && props.segment.possibleOneStopOverCities?.length > 0));
    const hasPossibleTwoStopOverCities = computed(() => (props.segment.possibleTwoStopOverCities != undefined && props.segment.possibleTwoStopOverCities?.length > 0));
    const showPossibleStopOverLabel =  computed(() => hasPossibleOneStopOverCities.value || hasPossibleTwoStopOverCities.value);
    const possibleStopOverText = computed(() => hasPossibleOneStopOverCities.value ? "min. 1-stop" : "2-stops");
    function showPossibleStopOver() {
        hoveringStopOverIcon.value = true;
        store.state.currentStopOverSegment = props.segment;
    }

    function hidePossibleStopOver() {
        hoveringStopOverIcon.value = false
        store.state.currentStopOverSegment = undefined;
    }

      return {
      status,
      statusMessage,
      showStatus,
      showWarningStatus,
      showErrorStatus,
      origin,
      selectedFlight,
      selectedFlightLegs,
      selectedFlightNumber,
      departureAirport,
      departureCity,
      departureDay,
      departureTime,
      arrivalAirport,
      arrivalCity,
      arrivalDay,
      arrivalTime,
      duration,
      flightOptions,
      showFlightOptions,
      toggleShowFlightOptions,
      handleToggleShowDetails,
      logoUrl,
      flightStr,
      stopsStr,
      layoverStr,
      departureTimeStr,
      arrivalTimeStr,
      iconColor,
      planeIconClass,
      allowSelectFlight,
      diffDay,
      departureDate,
      arrivalDate,
      lastLeg,
      showGroundSegmentOption,
      requestGroundSegment,
      isLegClassMismatch,
      getClassMismatchText,
      allowGroundSegment,
      hoveringStopOverIcon,
      segmentDivId,
      showPossibleStopOver,
      showPossibleStopOverLabel,    
      hidePossibleStopOver,
      possibleStopOverText
      };
  },
});
</script>
